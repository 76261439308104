import {TRAINTARIFFPERIODSITEMS_CREATE, TRAINTARIFFPERIODSITEMS_DELETE, TRAINTARIFFPERIODSITEMS_LIST, TRAINTARIFFPERIODSITEMS_UPDATE} from "../../actions/rail/traintariffperiodsitems";
import TrainTariffPeriodsItemsDataSvc from '../../../services/rail/TrainTariffPeriodsItemsDataSvc'

const state = {
  traintariffperiodsitems: []
};

const getters = {
  getTrainTariffPeriodsItems: state => state.traintariffperiodsitems,
};

const actions = {
  [TRAINTARIFFPERIODSITEMS_LIST]: ({ commit }) => {
    return new Promise((resolve) => {
      TrainTariffPeriodsItemsDataSvc.getAll()
        .then(response => {
          commit(TRAINTARIFFPERIODSITEMS_LIST, response.data);
          resolve(response);
        })
      })
    },
  [TRAINTARIFFPERIODSITEMS_CREATE]: ({ dispatch }, traintariffperiodsitems )=> {
    return new Promise((resolve) => {
      TrainTariffPeriodsItemsDataSvc.create(traintariffperiodsitems)
        .then(response => {
          dispatch(TRAINTARIFFPERIODSITEMS_LIST);
          resolve(response);
        })
      })
    },
  [TRAINTARIFFPERIODSITEMS_DELETE]: ({ dispatch }, traintariffperiodsitems )=> {
    return new Promise((resolve) => {
      TrainTariffPeriodsItemsDataSvc.delete(traintariffperiodsitems)
        .then(response => {
          dispatch(TRAINTARIFFPERIODSITEMS_LIST);
          resolve(response);
        })
    })
  },
  [TRAINTARIFFPERIODSITEMS_UPDATE]: ({ dispatch }, traintariffperiodsitems )=> {
    return new Promise((resolve) => {
      TrainTariffPeriodsItemsDataSvc.update(traintariffperiodsitems.id,traintariffperiodsitems)
        .then(response => {
          dispatch(TRAINTARIFFPERIODSITEMS_LIST);
          resolve(response);
        })
    })
  },
};

const mutations = {
  [TRAINTARIFFPERIODSITEMS_LIST]: (state, resp) => {
    state.traintariffperiodsitems = resp;
  },
};

export default {
  state,
  getters,
  actions,
  mutations
};
