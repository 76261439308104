import {TRAINTARIFFPERIODS_CREATE, TRAINTARIFFPERIODS_DELETE, TRAINTARIFFPERIODS_LIST, TRAINTARIFFPERIODS_UPDATE} from "../../actions/rail/traintariffperiods";
import TrainTariffPeriodsDataSvc from '../../../services/rail/TrainTariffPeriodsDataSvc'

const state = {
  traintariffperiods: []
};

const getters = {
  getTrainTariffPeriods: state => state.traintariffperiods,
};

const actions = {
  [TRAINTARIFFPERIODS_LIST]: ({ commit }) => {
    return new Promise((resolve) => {
      TrainTariffPeriodsDataSvc.getAll()
        .then(response => {
          commit(TRAINTARIFFPERIODS_LIST, response.data);
          resolve(response);
        })
      })
    },
  [TRAINTARIFFPERIODS_CREATE]: ({ dispatch }, traintariffperiods )=> {
    return new Promise((resolve) => {
      TrainTariffPeriodsDataSvc.create(traintariffperiods)
        .then(response => {
          dispatch(TRAINTARIFFPERIODS_LIST);
          resolve(response);
        })
      })
    },
  [TRAINTARIFFPERIODS_DELETE]: ({ dispatch }, traintariffperiods )=> {
    return new Promise((resolve) => {
      TrainTariffPeriodsDataSvc.delete(traintariffperiods)
        .then(response => {
          dispatch(TRAINTARIFFPERIODS_LIST);
          resolve(response);
        })
    })
  },
  [TRAINTARIFFPERIODS_UPDATE]: ({ dispatch }, traintariffperiods )=> {
    return new Promise((resolve) => {
      TrainTariffPeriodsDataSvc.update(traintariffperiods.id,traintariffperiods)
        .then(response => {
          dispatch(TRAINTARIFFPERIODS_LIST);
          resolve(response);
        })
    })
  },
};

const mutations = {
  [TRAINTARIFFPERIODS_LIST]: (state, resp) => {
    state.traintariffperiods = resp;
  },
};

export default {
  state,
  getters,
  actions,
  mutations
};
