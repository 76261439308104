import {DSTATUSDMG_CREATE, DSTATUSDMG_DELETE, DSTATUSDMG_LIST, DSTATUSDMG_UPDATE} from "../../actions/dict/dictcontstatusdmg";
import DictContStatusDmgDataSvc from '../../../services/dict/DictContStatusDmgDataSvc'

const state = {
  dstatusdmg: []
};

const getters = {
  getDContStatusDmg: state => state.dstatusdmg,
};

const actions = {
  [DSTATUSDMG_LIST]: ({ commit }) => {
    return new Promise((resolve) => {
      DictContStatusDmgDataSvc.getAll()
        .then(response => {
          commit(DSTATUSDMG_LIST, response.data);
          resolve(response);
        })
      })
    },
  [DSTATUSDMG_CREATE]: ({ dispatch }, contstatusdmg ) => {
    return new Promise((resolve) => {
      DictContStatusDmgDataSvc.create(contstatusdmg)
        .then(response => {
          dispatch(DSTATUSDMG_LIST);
          resolve(response);
        })
      })
    },
  [DSTATUSDMG_DELETE]: ({ dispatch }, contstatusdmg ) => {
    return new Promise((resolve) => {
      DictContStatusDmgDataSvc.delete(contstatusdmg)
        .then(response => {
          dispatch(DSTATUSDMG_LIST);
          resolve(response);
        })
    })
  },
  [DSTATUSDMG_UPDATE]: ({ dispatch }, contstatusdmg ) => {
    return new Promise((resolve) => {
      DictContStatusDmgDataSvc.update(contstatusdmg.id,contstatusdmg)
        .then(response => {
          dispatch(DSTATUSDMG_LIST);
          resolve(response);
        })
    })
  },
};

const mutations = {
  [DSTATUSDMG_LIST]: (state, resp) => {
    state.dstatusdmg = resp;
  },
};

export default {
  state,
  getters,
  actions,
  mutations
};
