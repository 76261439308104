import {DINTDAMAGES_CREATE, DINTDAMAGES_DELETE, DINTDAMAGES_LIST, DINTDAMAGES_UPDATE} from "../../actions/dict/dictinterchangedamages";
import DictInterchangeDamagesDataSvc from '../../../services/dict/DictInterchangeDamagesDataSvc'

const state = {
  dintdam: []
};

const getters = {
  getDIntDam: state => state.dintdam,
};

const actions = {
  [DINTDAMAGES_LIST]: ({ commit }) => {
    return new Promise((resolve) => {
      DictInterchangeDamagesDataSvc.getAll()
        .then(response => {
          commit(DINTDAMAGES_LIST, response.data);
          resolve(response);
        })
      })
    },
  [DINTDAMAGES_CREATE]: ({ dispatch }, contstatus ) => {
    return new Promise((resolve) => {
      DictInterchangeDamagesDataSvc.create(contstatus)
        .then(response => {
          dispatch(DINTDAMAGES_LIST);
          resolve(response);
        })
      })
    },
  [DINTDAMAGES_DELETE]: ({ dispatch }, contstatus ) => {
    return new Promise((resolve) => {
      DictInterchangeDamagesDataSvc.delete(contstatus)
        .then(response => {
          dispatch(DINTDAMAGES_LIST);
          resolve(response);
        })
    })
  },
  [DINTDAMAGES_UPDATE]: ({ dispatch }, contstatus ) => {
    return new Promise((resolve) => {
      DictInterchangeDamagesDataSvc.update(contstatus.id,contstatus)
        .then(response => {
          dispatch(DINTDAMAGES_LIST);
          resolve(response);
        })
    })
  },
};

const mutations = {
  [DINTDAMAGES_LIST]: (state, resp) => {
    state.dintdam = resp;
  },
};

export default {
  state,
  getters,
  actions,
  mutations
};
