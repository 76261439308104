import Vue from 'vue';
import Vuex from 'vuex';
import user from './modules/user/user';
import users from './modules/user/users';
import usersrole from './modules/user/usersrole';
import auth from "./modules/auth";
import invaccounts from "./modules/invoice/invaccounts";
import currency from "./modules/currency"
import contclass from "./modules/cont/contclass"
import dictcontstatusdmg from "./modules/dict/dictcontstatusdmg"
import dictcontstatuswash from "./modules/dict/dictcontstatuswash"
import dictcontstatustype from "./modules/dict/dictcontstatustype"
import dictcontstatusclean from "./modules/dict/dictcontstatusclean"
import dictcontstatuspti from "./modules/dict/dictcontstatuspti"
import dictcontstatusunit from "./modules/dict/dictcontstatusunit"
import dictcontstatusneutral from "./modules/dict/dictcontstatusneutral"
import contstatus from "./modules/cont/contstatus"
import contstatuscurrent from "./modules/cont/contstatuscurrent"
import tariffcontservices from "./modules/tariff/tariffcontservices"
import contservices from "./modules/cont/contservices"
import otherservices from "./modules/tariff/otherservices"
import contassignment from "./modules/cont/contassignment"
import dictcontcolors from "./modules/dict/dictcontcolors"
import dictinterchangedamages from "./modules/dict/dictinterchangedamages"
import continterchange from "./modules/cont/continterchange"
import contcondition from "./modules/cont/contcondition"
import dictcontsize from "./modules/dict/dictcontsize"
import dictcontblockreason from "./modules/dict/dictcontblockreason"
import contblock from "./modules/cont/contblock"
import contnotification from "./modules/cont/contnotification"
import dictcontservicepayer from "./modules/dict/dictcontservicepayer"
import dictcontserviceperson from "./modules/dict/dictcontserviceperson"
import dictcontservics from "./modules/dict/dictcontservices"
import dictconttransport from "./modules/dict/dictconttransport"
import dictconttype from "./modules/dict/dictconttype"
import dictcontnotificationtype from "./modules/dict/dictcontnotificationtype"
import dictcontnotificationstatus from "./modules/dict/dictcontnotificationstatus"
import dictcontpti from "./modules/dict/dictcontpti"
import containers from "./modules/containers"
import places from "./modules/places"
import locations from "./modules/location"
import contractors from "./modules/cont/contractors"
import payment from "./modules/payment"
import language from "./modules/language"
import country from "./modules/country"
import repcatalog from "./modules/repair/repcatalog"
import reptariff from "./modules/repair/reptariff"
import reptarifflocations from "./modules/repair/reptarifflocations"
import repestimatestype from "./modules/repair/repestimatestype"
import dictcontassignment from "./modules/dict/dictcontassignment"
import repestimates from "./modules/repair/repestimates"
import repestimatespositions from "./modules/repair/repestimatespositions"
import rateshandling from './modules/rates/rateshandling'
import ratesstorage from "./modules/rates/ratesstorage"
import ratesclean from "./modules/rates/ratesclean"
import ratescleanpositions from "./modules/rates/ratescleanpositions"
import rateswash from "./modules/rates/rateswash"
import rateswashpositions from "./modules/rates/rateswashpositions"
import rateswrongput from "./modules/rates/rateswrongput"
import rateswrongputpositions from "./modules/rates/rateswrongputpositions"
import ratesnumericchoice from "./modules/rates/ratesnumericchoice"
import ratesnumericchoicepositions from "./modules/rates/ratesnumericchoicepositions"
import ratessealremove from "./modules/rates/ratessealremove"
import ratessealremovepositions from "./modules/rates/ratessealremovepositions"
import units from "./modules/units"
import repcodes from "./modules/repair/repcodes"
import repcodestype from "./modules/repair/repcodestype"
import repestno from "./modules/repair/repestno"
import contwestim from "./modules/cont/contwestim"
import rateshandlingpositions from "./modules/rates/rateshandlingpositions"
import ratesstoragepositions from "./modules/rates/ratesstoragepositions"
import ratesstoragetype from "./modules/rates/ratesstoragetype"
import nbprates from "./modules/nbprates"
import nbptables from "./modules/nbptables"
import invoice from "./modules/invoice/invoice"
import invoiceposition from "./modules/invoice/invoiceposition"
import invoiceno from "./modules/invoice/invoiceno"
import invcorrno from "./modules/invoice/invcorrno"
import invservices from "./modules/invoice/invservices"
import invproducts from "./modules/invoice/invproducts"
import invproductgroups from "./modules/invoice/invproductgroups"
import invvatrates from "./modules/invoice/invvatrates"
import invplaces from "./modules/invoice/invplaces"
import invgtu from './modules/invoice/invgtu';
import invcomments from './modules/invoice/invcomments';
import invcorrections from './modules/invoice/invcorrections';
import invcorrpositions from './modules/invoice/invcorrpositions';
import invcorrreason from './modules/invoice/invcorrreason';
import reports from './modules/reports';
import train from "./modules/rail/train"
import traincomponents from "./modules/rail/traincomponents"
import traincomponentsconts from "./modules/rail/traincomponentsconts"
import trainconttype from "./modules/rail/trainconttype"
import trainservices from "./modules/rail/trainservices"
import traintariff from "./modules/rail/traintariff"
import traintariffperiods from "./modules/rail/traintariffperiods"
import traintariffperiodsitems from "./modules/rail/traintariffperiodsitems"
import tariff from "./modules/tariff/tariff"
import tariffservices from "./modules/tariff/tariffservices"
import tariffservicesgroup from "./modules/tariff/tariffservicesgroup"
import tariffpositions from "./modules/tariff/tariffpositions"
import tariffitems from "./modules/tariff/tariffitems"
import tariffpositionsitems from "./modules/tariff/tariffpositionsitems"


Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    user,
    users,
    usersrole,
    auth,
    invaccounts,
    currency,
    contclass,
    dictcontcolors,
    contcondition,
    dictcontsize,
    dictcontblockreason,
    contblock,
    dictcontservics,
    dictcontservicepayer,
    dictcontserviceperson,
    dictconttransport,
    dictconttype,
    dictcontpti,
    dictcontnotificationstatus,
    dictcontnotificationtype,
    containers,
    contstatus,
    contstatuscurrent,
    dictcontstatusdmg,
    dictcontstatuswash,
    dictcontstatustype,
    dictcontstatusclean,
    dictcontstatuspti,
    dictcontstatusunit,
    dictcontstatusneutral,
    contnotification,
    tariffcontservices,
    contservices,
    otherservices,
    contassignment,
    dictinterchangedamages,
    continterchange,
    places,
    locations,
    contractors,
    payment,
    language,
    country,
    repcatalog,
    reptariff,
    reptarifflocations,
    dictcontassignment,
    repestimates,
    repestimatespositions,
    repestimatestype,
    ratesstorage,
    ratesstoragepositions,
    rateshandling,
    rateshandlingpositions,
    ratesstoragetype,
    ratesclean,
    ratescleanpositions,
    rateswash,
    rateswashpositions,
    rateswrongput,
    rateswrongputpositions,
    ratessealremove,
    ratessealremovepositions,
    ratesnumericchoice,
    ratesnumericchoicepositions,
    units,
    repcodes,
    repcodestype,
    repestno,
    contwestim,
    nbprates,
    nbptables,
    invoice,
    invoiceposition,
    invoiceno,
    invcorrno,
    invservices,
    invproducts,
    invproductgroups,
    invvatrates,
    invplaces,
    invgtu,
    invcomments,
    invcorrections,
    invcorrpositions,
    invcorrreason,
    reports,
    trainconttype,
    train,
    traincomponents,
    traincomponentsconts,
    trainservices,
    traintariff,
    traintariffperiods,
    traintariffperiodsitems,
    tariff,
    tariffservices,
    tariffservicesgroup,
    tariffpositions,
    tariffitems,
    tariffpositionsitems,
  },
  state: {},
  mutations: {},
  actions: {},
  getters: {},
});
