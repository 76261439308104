import '@mdi/font/css/materialdesignicons.css'
import Vue from 'vue';
import Vuetify from 'vuetify/lib';

Vue.use(Vuetify);

import pl from 'vuetify/lib/locale/pl'

export default new Vuetify({
    icons: {
        iconfont: 'mdi',
    },
    lang: {
        locales: { pl },
        current: 'pl',
      },
});
