import {ACCOUNT_CREATE, ACCOUNT_DELETE, ACCOUNT_LIST, ACCOUNT_UPDATE} from "../../actions/invoice/invaccounts";
import InvAccountsDataSvc from '../../../services/invoice/InvAccountsDataSvc'

const state = {
  accounts: []
};

const getters = {
  getAccounts: state => state.accounts,
};

const actions = {
  [ACCOUNT_LIST]: ({ commit }) => {
    return new Promise((resolve) => {
      InvAccountsDataSvc.getAll()
        .then(response => {
          commit(ACCOUNT_LIST, response.data);
          resolve(response);
        })
      })
    },
  [ACCOUNT_CREATE]: ({ dispatch }, account ) => {
    return new Promise((resolve) => {
      InvAccountsDataSvc.create(account)
        .then(response => {
          dispatch(ACCOUNT_LIST);
          resolve(response);
        })
      })
    },
  [ACCOUNT_DELETE]: ({ dispatch }, account ) => {
    return new Promise((resolve) => {
      InvAccountsDataSvc.delete(account)
        .then(response => {
          dispatch(ACCOUNT_LIST);
          resolve(response);
        })
    })
  },
  [ACCOUNT_UPDATE]: ({ dispatch }, account ) => {
    return new Promise((resolve) => {
      InvAccountsDataSvc.update(account.id,account)
        .then(response => {
          dispatch(ACCOUNT_LIST);
          resolve(response);
        })
    })
  },
};

const mutations = {
  [ACCOUNT_LIST]: (state, resp) => {
    state.accounts = resp;
  },
};

export default {
  state,
  getters,
  actions,
  mutations
};
