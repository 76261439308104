import {DSIZE_CREATE, DSIZE_DELETE, DSIZE_LIST, DSIZE_UPDATE} from "../../actions/dict/dictcontsize";
import DictContSizeDataSvc from '../../../services/dict/DictContSizeDataSvc'

const state = {
  size: []
};

const getters = {
  getSizes: state => state.size,
};

const actions = {
  [DSIZE_LIST]: ({ commit }) => {
    return new Promise((resolve) => {
      DictContSizeDataSvc.getAll()
        .then(response => {
          commit(DSIZE_LIST, response.data);
          resolve(response);
        })
      })
    },
  [DSIZE_CREATE]: ({ dispatch }, contsize ) => {
    return new Promise((resolve) => {
      DictContSizeDataSvc.create(contsize)
        .then(response => {
          dispatch(DSIZE_LIST);
          resolve(response);
        })
      })
    },
  [DSIZE_DELETE]: ({ dispatch }, contsize ) => {
    return new Promise((resolve) => {
      DictContSizeDataSvc.delete(contsize)
        .then(response => {
          dispatch(DSIZE_LIST);
          resolve(response);
        })
    })
  },
  [DSIZE_UPDATE]: ({ dispatch }, contsize ) => {
    return new Promise((resolve) => {
      DictContSizeDataSvc.update(contsize.id,contsize)
        .then(response => {
          dispatch(DSIZE_LIST);
          resolve(response);
        })
    })
  },
};

const mutations = {
  [DSIZE_LIST]: (state, resp) => {
    state.size = resp;
  },
};

export default {
  state,
  getters,
  actions,
  mutations
};
