import {STATUSCURRENT_CREATE, STATUSCURRENT_DELETE, STATUSCURRENT_LIST, STATUSCURRENT_UPDATE} from "../../actions/cont/contstatuscurrent";
import ContStatusCurrentDataSvc from '../../../services/cont/ContStatusCurrentDataSvc'

const state = {
  contstatuscurrent: [],
};

const getters = {
  getContStatusCurrent: state => state.contstatuscurrent,
};

const actions = {
  [STATUSCURRENT_LIST]: ({ commit }) => {
    return new Promise((resolve) => {
      ContStatusCurrentDataSvc.getAll()
        .then(response => {
          commit(STATUSCURRENT_LIST, response.data);
          resolve(response);
        })
      })
    },
    [STATUSCURRENT_CREATE]: ({ dispatch }, contstatuscurrent ) => {
    return new Promise((resolve) => {
      ContStatusCurrentDataSvc.create(contstatuscurrent)
        .then(response => {
          dispatch(STATUSCURRENT_LIST);
          resolve(response);
        })
      })
    },
  [STATUSCURRENT_DELETE]: ({ dispatch }, contstatuscurrent ) => {
    return new Promise((resolve) => {
      ContStatusCurrentDataSvc.delete(contstatuscurrent)
        .then(response => {
          dispatch(STATUSCURRENT_LIST);
          resolve(response);
        })
    })
  },
  [STATUSCURRENT_UPDATE]: ({ dispatch }, contstatuscurrent ) => {
    return new Promise((resolve) => {
      ContStatusCurrentDataSvc.update(contstatuscurrent.id,contstatuscurrent)
        .then(response => {
          dispatch(STATUSCURRENT_LIST);
          resolve(response);
        })
    })
  },
};

const mutations = {
  [STATUSCURRENT_LIST]: (state, resp) => {
    state.contstatuscurrent = resp;
  },
};

export default {
  state,
  getters,
  actions,
  mutations
};
