import {http} from "../../api/http-api";

class TrainDataSvc {
    getAll() {
        return http.get("/train")
    }
    create(data) {
        return http.post(`/train`, data)
    }
    update(id, data) {
        return http.patch(`/train/${id}`, data)
    }
    delete(id) {
        return http.delete(`/train/${id}`)
    }
}

export default new TrainDataSvc();