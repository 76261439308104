import {TRAINCOMPONENTSCONTS_CREATE, TRAINCOMPONENTSCONTS_DELETE, TRAINCOMPONENTSCONTS_LIST, TRAINCOMPONENTSCONTS_LISTEXP, TRAINCOMPONENTSCONTS_UPDATE} from "../../actions/rail/traincomponentsconts";
import TrainComponentsContsDataSvc from '../../../services/rail/TrainComponentsContsDataSvc'

const state = {
  traincomponentsconts: []
};

const getters = {
  getTrainComponentsConts: state => state.traincomponentsconts,
};

const actions = {
  [TRAINCOMPONENTSCONTS_LIST]: ({ commit }) => {
    return new Promise((resolve) => {
      TrainComponentsContsDataSvc.getAll()
        .then(response => {
          commit(TRAINCOMPONENTSCONTS_LIST, response.data);
          resolve(response);
        })
      })
    },
    [TRAINCOMPONENTSCONTS_LISTEXP]: ({ commit }) => {
      return new Promise((resolve) => {
        TrainComponentsContsDataSvc.getAllExp()
          .then(response => {
            commit(TRAINCOMPONENTSCONTS_LISTEXP, response.data);
            resolve(response);
          })
        })
      },
    [TRAINCOMPONENTSCONTS_CREATE]: ({ dispatch }, traincomponentsconts )=> {
    return new Promise((resolve) => {
      TrainComponentsContsDataSvc.create(traincomponentsconts)
        .then(response => {
          dispatch(TRAINCOMPONENTSCONTS_LISTEXP);
          resolve(response);
        })
      })
    },
  [TRAINCOMPONENTSCONTS_DELETE]: ({ dispatch }, traincomponentsconts )=> {
    return new Promise((resolve) => {
      TrainComponentsContsDataSvc.delete(traincomponentsconts)
        .then(response => {
          dispatch(TRAINCOMPONENTSCONTS_LISTEXP);
          resolve(response);
        })
    })
  },
  [TRAINCOMPONENTSCONTS_UPDATE]: ({ dispatch }, traincomponentsconts )=> {
    return new Promise((resolve) => {
      TrainComponentsContsDataSvc.update(traincomponentsconts.id,traincomponentsconts)
        .then(response => {
          dispatch(TRAINCOMPONENTSCONTS_LISTEXP);
          resolve(response);
        })
    })
  },
};

const mutations = {
  [TRAINCOMPONENTSCONTS_LIST]: (state, resp) => {
    state.traincomponentsconts = resp;
  },
  [TRAINCOMPONENTSCONTS_LISTEXP]: (state, resp) => {
    state.traincomponentsconts = resp;
  },
};

export default {
  state,
  getters,
  actions,
  mutations
};
