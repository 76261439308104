import {SERVICES_CREATE, SERVICES_DELETE, SERVICES_LIST, SERVICES_UPDATE} from "../../actions/dict/dictcontservices";
import DictContServicesDataSvc from '../../../services/dict/DictContServicesDataSvc'

const state = {
  service: []
};

const getters = {
  getServicesOwn: state => state.service.filter((service) => service.own === 1),
  getServicesOther: state => state.service.filter((service) => service.other === 1),
  getServices: state => state.service,
};

const actions = {
  [SERVICES_LIST]: ({ commit }) => {
    return new Promise((resolve) => {
      DictContServicesDataSvc.getAll()
        .then(response => {
          commit(SERVICES_LIST, response.data);
          resolve(response);
        })
      })
    },
  [SERVICES_CREATE]: ({ dispatch }, service ) => {
    return new Promise((resolve) => {
      DictContServicesDataSvc.create(service)
        .then(response => {
          dispatch(SERVICES_LIST);
          resolve(response);
        })
      })
    },
  [SERVICES_DELETE]: ({ dispatch }, service ) => {
    return new Promise((resolve) => {
      DictContServicesDataSvc.delete(service)
        .then(response => {
          dispatch(SERVICES_LIST);
          resolve(response);
        })
    })
  },
  [SERVICES_UPDATE]: ({ dispatch }, service ) => {
    return new Promise((resolve) => {
      DictContServicesDataSvc.update(service.id,service)
        .then(response => {
          dispatch(SERVICES_LIST);
          resolve(response);
        })
    })
  },
};

const mutations = {
  [SERVICES_LIST]: (state, resp) => {
    state.service = resp;
  },
};

export default {
  state,
  getters,
  actions,
  mutations
};
