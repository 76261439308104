import {http} from "../../api/http-api";

class DictContTypeDataSvc {
    getAll() {
        return http.get("/dictconttype")
    }
    create(data) {
        return http.post(`/dictconttype`, data)
    }
    update(id, data) {
        return http.patch(`/dictconttype/${id}`, data)
    }
    delete(id) {
        return http.delete(`/dictconttype/${id}`)
    }
}

export default new DictContTypeDataSvc();