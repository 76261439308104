import {http} from "../api/http-api";

class LocationDataSvc {
    getAll() {
        return http.get("/location")
    }
    create(data) {
        return http.post(`/location`, data)
    }
    update(id, data) {
        return http.patch(`/location/${id}`, data)
    }
    delete(id) {
        return http.delete(`/location/${id}`)
    }
}

export default new LocationDataSvc();