import {http} from "../../api/http-api";

class RatesNumericChoiceDataSvc {
    getAll() {
        return http.get("/ratesnumericchoice")
    }
    create(data) {
        return http.post(`/ratesnumericchoice`, data)
    }
    update(id, data) {
        return http.patch(`/ratesnumericchoice/${id}`, data)
    }
    delete(id) {
        return http.delete(`/ratesnumericchoice/${id}`)
    }
}

export default new RatesNumericChoiceDataSvc();