import {REPTARIFF_CREATE, REPTARIFF_DELETE, REPTARIFF_LIST, REPTARIFF_UPDATE} from "../../actions/repair/reptariff";
import RepTariffDataSvc from '../../../services/repair/RepTariffDataSvc'

const state = {
  reptariff: []
};

const getters = {
  getRepTariff: state => state.reptariff,
};

const actions = {
  [REPTARIFF_LIST]: ({ commit }) => {
    return new Promise((resolve) => {
      RepTariffDataSvc.getAll()
        .then(response => {
          commit(REPTARIFF_LIST, response.data);
          resolve(response);
        })
      })
    },
  [REPTARIFF_CREATE]: ({ dispatch }, reptariff ) => {
    return new Promise((resolve) => {
      RepTariffDataSvc.create(reptariff)
        .then(response => {
          dispatch(REPTARIFF_LIST);
          resolve(response);
        })
      })
    },
  [REPTARIFF_DELETE]: ({ dispatch }, reptariff ) => {
    return new Promise((resolve) => {
      RepTariffDataSvc.delete(reptariff)
        .then(response => {
          dispatch(REPTARIFF_LIST);
          resolve(response);
        })
    })
  },
  [REPTARIFF_UPDATE]: ({ dispatch }, reptariff ) => {
    return new Promise((resolve) => {
      RepTariffDataSvc.update(reptariff.id,reptariff)
        .then(response => {
          dispatch(REPTARIFF_LIST);
          resolve(response);
        })
    })
  },
};

const mutations = {
  [REPTARIFF_LIST]: (state, resp) => {
    state.reptariff = resp;
  },
};

export default {
  state,
  getters,
  actions,
  mutations
};
