import {http} from "../../api/http-api";

class ContStatusDataSvc {
    getAllexp() {
        return http.get("/contstatus?expand=status,service_made")
    }
    getAll() {
        return http.get("/contstatus")
    }
    create(data) {
        return http.post(`/contstatus`, data)
    }
    update(id, data) {
        return http.patch(`/contstatus/${id}`, data)
    }
    delete(id) {
        return http.delete(`/contstatus/${id}`)
    }
}

export default new ContStatusDataSvc();