import {PRODUCT_CREATE, PRODUCT_DELETE, PRODUCT_LIST, PRODUCT_UPDATE} from "../../actions/invoice/invproducts";
import InvProductsDataSvc from '../../../services/invoice/InvProductsDataSvc'

const state = {
  products: []
};

const getters = {
  getProducts: state => state.products,
};

const actions = {
  [PRODUCT_LIST]: ({ commit }) => {
    return new Promise((resolve) => {
      InvProductsDataSvc.getAll()
        .then(response => {
          commit(PRODUCT_LIST, response.data);
          resolve(response);
        })
      })
    },
  [PRODUCT_CREATE]: ({ dispatch }, product ) => {
    return new Promise((resolve) => {
      InvProductsDataSvc.create(product)
        .then(response => {
          dispatch(PRODUCT_LIST);
          resolve(response);
        })
      })
    },
  [PRODUCT_DELETE]: ({ dispatch }, product ) => {
    return new Promise((resolve) => {
      InvProductsDataSvc.delete(product)
        .then(response => {
          dispatch(PRODUCT_LIST);
          resolve(response);
        })
    })
  },
  [PRODUCT_UPDATE]: ({ dispatch }, product ) => {
    return new Promise((resolve) => {
      InvProductsDataSvc.update(product.id,product)
        .then(response => {
          dispatch(PRODUCT_LIST);
          resolve(response);
        })
    })
  },
};

const mutations = {
  [PRODUCT_LIST]: (state, resp) => {
    state.products = resp;
  },
};

export default {
  state,
  getters,
  actions,
  mutations
};
