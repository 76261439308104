import {CONTAINER_CREATE, CONTAINER_DELETE, CONTAINER_LISTALL, CONTAINER_UPDATEONPLACE, CONTAINER_UPDATEALL, CONTAINER_LISTOUT, CONTAINER_LISTDMG, CONTAINER_LISTONPLACE} from "../actions/containers";
import ContainersDataSvc from '../../services/ContainersDataSvc'

const state = {
  containers: [],
  containersout: [],
  containersdmg: [],
  containersonplace: [],
};

const getters = {
  getContainers: state => state.containers,
  getContainersDmg: state => state.containersdmg,
  getContainersOut: state => state.containersout,
  getContainersOnPlace: state => state.containersonplace,
  getContainersDmgBox: state => state.containersdmg.filter((container) => container.dmg_status_curr > 1 || container.wash_status_curr > 1 || container.clean_status_curr > 1),
  getContainersDmgReef: state => state.containersdmg.filter((container) => container.pti_status_curr > 1 || container.unit_status_curr > 1),
  getContainersServices: state => state.containersdmg.filter((container) => container.clean_status_curr > 1 || container.wash_status_curr > 1),
};

const actions = {
  [CONTAINER_LISTALL]: ({ commit }) => {
    return new Promise((resolve) => {
      ContainersDataSvc.getAll()
        .then(response => {
          commit(CONTAINER_LISTALL, response.data);
          resolve(response);
        })
      })
    },
  [CONTAINER_LISTONPLACE]: ({ commit }) => {
    return new Promise((resolve) => {
      ContainersDataSvc.getOnPlace()
        .then(response => {
          commit(CONTAINER_LISTONPLACE, response.data);
          resolve(response);
        })
      })
    },
  [CONTAINER_LISTOUT]: ({ commit }) => {
    return new Promise((resolve) => {
      ContainersDataSvc.getOut()
        .then(response => {
          commit(CONTAINER_LISTOUT, response.data);
          resolve(response);
        })
      })
    },
  [CONTAINER_LISTDMG]: ({ commit }) => {
    return new Promise((resolve) => {
      ContainersDataSvc.getDmg()
        .then(response => {
          commit(CONTAINER_LISTDMG, response.data);
          resolve(response);
        })
      })
    },
  [CONTAINER_CREATE]: ({ dispatch }, containers ) => {
    return new Promise((resolve) => {
      ContainersDataSvc.create(containers)
        .then(response => {
          dispatch(CONTAINER_LISTALL);
          resolve(response);
        })
      })
    },
  [CONTAINER_DELETE]: ({ dispatch }, containers ) => {
    return new Promise((resolve) => {
      ContainersDataSvc.delete(containers)
        .then(response => {
          dispatch(CONTAINER_LISTALL);
          resolve(response);
        })
      })
    },
  [CONTAINER_UPDATEONPLACE]: ({ dispatch }, containers ) => {
    return new Promise((resolve) => {
      ContainersDataSvc.update(containers.id,containers)
        .then(response => {
          dispatch(CONTAINER_LISTONPLACE);
          resolve(response);
        })
      })
    },
    [CONTAINER_UPDATEALL]: ({ dispatch }, containers ) => {
      return new Promise((resolve) => {
        ContainersDataSvc.update(containers.id,containers)
          .then(response => {
            dispatch(CONTAINER_LISTALL);
            resolve(response);
          })
        })
      },
  };

const mutations = {
  [CONTAINER_LISTALL]: (state, resp) => {
    state.containers = resp;
  },
  [CONTAINER_LISTONPLACE]: (state, resp) => {
    state.containersonplace = resp;
  },
  [CONTAINER_LISTOUT]: (state, resp) => {
    state.containersout = resp;
  },
  [CONTAINER_LISTDMG]: (state, resp) => {
    state.containersdmg = resp;
  },
};

export default {
  state,
  getters,
  actions,
  mutations
};
