import {http} from "../../api/http-api";

class RatesSealRemovePositionsDataSvc {
    getAll() {
        return http.get("/ratessealremovepositions")
    }
    create(data) {
        return http.post(`/ratessealremovepositions`, data)
    }
    update(id, data) {
        return http.patch(`/ratessealremovepositions/${id}`, data)
    }
    delete(id) {
        return http.delete(`/ratessealremovepositions/${id}`)
    }
}

export default new RatesSealRemovePositionsDataSvc();