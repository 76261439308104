import { NBPRATES_LIST } from "../actions/invoice/nbprates";
import NbpRatesDataSvc from '../../services/invoice/NbpRatesDataSvc'

const state = {
  nbprate: []
};

const getters = {
  getNbpRates: state => state.nbprate,
};

const actions = {
  [NBPRATES_LIST]: ({ commit }) => {
    return new Promise((resolve) => {
      NbpRatesDataSvc.getAll()
        .then(response => {
          commit(NBPRATES_LIST, response.data);
          resolve(response);
        })
      })
    },
};

const mutations = {
  [NBPRATES_LIST]: (state, resp) => {
    state.nbprate = resp;
  },
};

export default {
  state,
  getters,
  actions,
  mutations
};
