import {http} from "../../api/http-api";

class TrainServicesDataSvc {
    getAll() {
        return http.get("/trainservices")
    }
    create(data) {
        return http.post(`/trainservices`, data)
    }
    update(id, data) {
        return http.patch(`/trainservices/${id}`, data)
    }
    delete(id) {
        return http.delete(`/trainservices/${id}`)
    }
}

export default new TrainServicesDataSvc();