import {REPESTIMATESPOSITIONS_CREATE, REPESTIMATESPOSITIONS_DELETE, REPESTIMATESPOSITIONS_LIST, REPESTIMATESPOSITIONS_UPDATE} from "../../actions/repair/repestimatespositions";
import RepEstimatesPositionsDataSvc from '../../../services/repair/RepEstimatesPositionsDataSvc'

const state = {
  estimatespositions: []
};

const getters = {
  getEstimatesPositions: state => state.estimatespositions,
};

const actions = {
  [REPESTIMATESPOSITIONS_LIST]: ({ commit }) => {
    return new Promise((resolve) => {
      RepEstimatesPositionsDataSvc.getAll()
        .then(response => {
          commit(REPESTIMATESPOSITIONS_LIST, response.data);
          resolve(response);
        })
      })
    },
  [REPESTIMATESPOSITIONS_CREATE]: ({ dispatch }, estimatepos ) => {
    return new Promise((resolve) => {
      RepEstimatesPositionsDataSvc.create(estimatepos)
        .then(response => {
          dispatch(REPESTIMATESPOSITIONS_LIST);
          resolve(response);
        })
      })
    },
  [REPESTIMATESPOSITIONS_DELETE]: ({ dispatch }, estimatepos ) => {
    return new Promise((resolve) => {
      RepEstimatesPositionsDataSvc.delete(estimatepos)
        .then(response => {
          dispatch(REPESTIMATESPOSITIONS_LIST);
          resolve(response);
        })
    })
  },
  [REPESTIMATESPOSITIONS_UPDATE]: ({ dispatch }, estimatepos ) => {
    return new Promise((resolve) => {
      RepEstimatesPositionsDataSvc.update(estimatepos.id,estimatepos)
        .then(response => {
          dispatch(REPESTIMATESPOSITIONS_LIST);
          resolve(response);
        })
    })
  },
};

const mutations = {
  [REPESTIMATESPOSITIONS_LIST]: (state, resp) => {
    state.estimatespositions = resp;
  },
};

export default {
  state,
  getters,
  actions,
  mutations
};
