import {http} from "../../api/http-api";

class TrainComponentsContsDataSvc {
    getAll() {
        return http.get("/traincomponentsconts")
    }
    getAllExp() {
      return http.get("/traincomponentsconts?expand=container")
  }
  create(data) {
        return http.post(`/traincomponentsconts`, data)
    }
    update(id, data) {
        return http.patch(`/traincomponentsconts/${id}`, data)
    }
    delete(id) {
        return http.delete(`/traincomponentsconts/${id}`)
    }
}

export default new TrainComponentsContsDataSvc();