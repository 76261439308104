import {DTYPE_CREATE, DTYPE_DELETE, DTYPE_LIST, DTYPE_UPDATE} from "../../actions/dict/dictconttype";
import DictContTypeDataSvc from '../../../services/dict/DictContTypeDataSvc'

const state = {
  type: []
};

const getters = {
  getType: state => state.type,
};

const actions = {
  [DTYPE_LIST]: ({ commit }) => {
    return new Promise((resolve) => {
      DictContTypeDataSvc.getAll()
        .then(response => {
          commit(DTYPE_LIST, response.data);
          resolve(response);
        })
      })
    },
  [DTYPE_CREATE]: ({ dispatch }, conttype ) => {
    return new Promise((resolve) => {
      DictContTypeDataSvc.create(conttype)
        .then(response => {
          dispatch(DTYPE_LIST);
          resolve(response);
        })
      })
    },
  [DTYPE_DELETE]: ({ dispatch }, conttype ) => {
    return new Promise((resolve) => {
      DictContTypeDataSvc.delete(conttype)
        .then(response => {
          dispatch(DTYPE_LIST);
          resolve(response);
        })
    })
  },
  [DTYPE_UPDATE]: ({ dispatch }, conttype ) => {
    return new Promise((resolve) => {
      DictContTypeDataSvc.update(conttype.id,conttype)
        .then(response => {
          dispatch(DTYPE_LIST);
          resolve(response);
        })
    })
  },
};

const mutations = {
  [DTYPE_LIST]: (state, resp) => {
    state.type = resp;
  },
};

export default {
  state,
  getters,
  actions,
  mutations
};
