import {http} from "../../api/http-api";

class DictContStatusUnitDataSvc {
    getAll() {
        return http.get("/dictcontstatusunit")
    }
    create(data) {
        return http.post(`/dictcontstatusunit`, data)
    }
    update(id, data) {
        return http.patch(`/dictcontstatusunit/${id}`, data)
    }
    delete(id) {
        return http.delete(`/dictcontstatusunit/${id}`)
    }
}

export default new DictContStatusUnitDataSvc();