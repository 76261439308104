import {http} from "../../api/http-api";

class PaymentDataSvc {
    getAll() {
        return http.get("/payment")
    }
    create(data) {
        return http.post(`/payment`, data)
    }
    update(id, data) {
        return http.patch(`/payment/${id}`, data)
    }
    delete(id) {
        return http.delete(`/payment/${id}`)
    }
}

export default new PaymentDataSvc();