import {CONDITION_CREATE, CONDITION_DELETE, CONDITION_LIST, CONDITION_UPDATE} from "../../actions/cont/contcondition";
import ContConditionDataSvc from '../../../services/cont/ContConditionDataSvc'

const state = {
  condition: []
};

const getters = {
  getCondition: state => state.condition,
};

const actions = {
  [CONDITION_LIST]: ({ commit }) => {
    return new Promise((resolve) => {
      ContConditionDataSvc.getAll()
        .then(response => {
          commit(CONDITION_LIST, response.data);
          resolve(response);
        })
      })
    },
  [CONDITION_CREATE]: ({ dispatch }, contcondition ) => {
    return new Promise((resolve) => {
      ContConditionDataSvc.create(contcondition)
        .then(response => {
          dispatch(CONDITION_LIST);
          resolve(response);
        })
      })
    },
  [CONDITION_DELETE]: ({ dispatch }, contcondition ) => {
    return new Promise((resolve) => {
      ContConditionDataSvc.delete(contcondition)
        .then(response => {
          dispatch(CONDITION_LIST);
          resolve(response);
        })
    })
  },
  [CONDITION_UPDATE]: ({ dispatch }, contcondition ) => {
    return new Promise((resolve) => {
      ContConditionDataSvc.update(contcondition.id,contcondition)
        .then(response => {
          dispatch(CONDITION_LIST);
          resolve(response);
        })
    })
  },
};

const mutations = {
  [CONDITION_LIST]: (state, resp) => {
    state.condition = resp;
  },
};

export default {
  state,
  getters,
  actions,
  mutations
};
