import {http} from "../../api/http-api";

class TariffItemsDataSvc {
    getAll() {
        return http.get("/tariffitems")
    }
    create(data) {
        return http.post(`/tariffitems`, data)
    }
    update(id, data) {
        return http.patch(`/tariffitems/${id}`, data)
    }
    delete(id) {
        return http.delete(`/tariffitems/${id}`)
    }
}

export default new TariffItemsDataSvc();