import axios from "axios";

const http = axios.create({
  baseURL: "http://iad2.api.local/v1",
  headers: {
    "Content-type": "application/json"
  }
});
const httpgetfile = axios.create({
  baseURL: "http://iad2.api.local",
  headers: {
    "Content-type": "application/octet-stream"
  }
});
export {
  http,
  httpgetfile
}