import {http} from "../api/http-api";

class UnitsDataSvc {
    getAll() {
        return http.get(`/units`)
    }
    delete(id) {
        return http.delete(`/units/${id}`)
    }
    create(data) {
        return http.post(`/units`, data)
    }
    update(id, data) {
        return http.patch(`/units/${id}`, data)
    }
}

export default new UnitsDataSvc();