import {CONTSERVICES_CREATE, CONTSERVICES_DELETE, CONTSERVICES_LIST, CONTSERVICES_UPDATE} from "../../actions/cont/contservices";
import ContServicesDataSvc from '../../../services/cont/ContServicesDataSvc'

const state = {
  contservices: [],
};

const getters = {
  getContServices: state => state.contservices,
};

const actions = {
  [CONTSERVICES_LIST]: ({ commit }) => {
    return new Promise((resolve) => {
      ContServicesDataSvc.getAll()
        .then(response => {
          commit(CONTSERVICES_LIST, response.data);
          resolve(response);
        })
      })
    },
    [CONTSERVICES_CREATE]: ({ dispatch }, contservice ) => {
    return new Promise((resolve) => {
      ContServicesDataSvc.create(contservice)
        .then(response => {
          dispatch(CONTSERVICES_LIST);
          resolve(response);
        })
      })
    },
  [CONTSERVICES_DELETE]: ({ dispatch }, contservice ) => {
    return new Promise((resolve) => {
      ContServicesDataSvc.delete(contservice)
        .then(response => {
          dispatch(CONTSERVICES_LIST);
          resolve(response);
        })
    })
  },
  [CONTSERVICES_UPDATE]: ({ dispatch }, contservice ) => {
    return new Promise((resolve) => {
      ContServicesDataSvc.update(contservice.id,contservice)
        .then(response => {
          dispatch(CONTSERVICES_LIST);
          resolve(response);
        })
    })
  },
};

const mutations = {
  [CONTSERVICES_LIST]: (state, resp) => {
    state.contservices = resp;
  },
};

export default {
  state,
  getters,
  actions,
  mutations
};
