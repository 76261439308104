import {TARIFFPOSITIONSITEMS_CREATE, TARIFFPOSITIONSITEMS_DELETE, TARIFFPOSITIONSITEMS_LIST, TARIFFPOSITIONSITEMS_UPDATE} from "../../actions/tariff/tariffpositionsitems";
import TariffPositionsItemsDataSvc from '../../../services/tariff/TariffPositionsItemsDataSvc'

const state = {
  tariffpositionsitems: []
};

const getters = {
  getTariffPositionsItems: state => state.tariffpositionsitems,
};

const actions = {
  [TARIFFPOSITIONSITEMS_LIST]: ({ commit }) => {
    return new Promise((resolve) => {
      TariffPositionsItemsDataSvc.getAll()
        .then(response => {
          commit(TARIFFPOSITIONSITEMS_LIST, response.data);
          resolve(response);
        })
      })
    },
  [TARIFFPOSITIONSITEMS_CREATE]: ({ dispatch }, rates )=> {
    return new Promise((resolve) => {
      TariffPositionsItemsDataSvc.create(rates)
        .then(response => {
          dispatch(TARIFFPOSITIONSITEMS_LIST);
          resolve(response);
        })
      })
    },
  [TARIFFPOSITIONSITEMS_DELETE]: ({ dispatch }, rates )=> {
    return new Promise((resolve) => {
      TariffPositionsItemsDataSvc.delete(rates)
        .then(response => {
          dispatch(TARIFFPOSITIONSITEMS_LIST);
          resolve(response);
        })
    })
  },
  [TARIFFPOSITIONSITEMS_UPDATE]: ({ dispatch }, rates )=> {
    return new Promise((resolve) => {
      TariffPositionsItemsDataSvc.update(rates.id,rates)
        .then(response => {
          dispatch(TARIFFPOSITIONSITEMS_LIST);
          resolve(response);
        })
    })
  },
};

const mutations = {
  [TARIFFPOSITIONSITEMS_LIST]: (state, resp) => {
    state.tariffpositionsitems = resp;
  },
};

export default {
  state,
  getters,
  actions,
  mutations
};
