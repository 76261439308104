import {DNOTIFICATIONSTATUS_CREATE, DNOTIFICATIONSTATUS_DELETE, DNOTIFICATIONSTATUS_LIST, DNOTIFICATIONSTATUS_UPDATE} from "../../actions/dict/dictcontnotificationstatus";
import DictContNotificationStatusDataSvc from '../../../services/dict/DictContNotificationStatusDataSvc'

const state = {
  dnotifstatus: []
};

const getters = {
  getDContNotifStatus: state => state.dnotifstatus,
};

const actions = {
  [DNOTIFICATIONSTATUS_LIST]: ({ commit }) => {
    return new Promise((resolve) => {
      DictContNotificationStatusDataSvc.getAll()
        .then(response => {
          commit(DNOTIFICATIONSTATUS_LIST, response.data);
          resolve(response);
        })
      })
    },
  [DNOTIFICATIONSTATUS_CREATE]: ({ dispatch }, contstatus ) => {
    return new Promise((resolve) => {
      DictContNotificationStatusDataSvc.create(contstatus)
        .then(response => {
          dispatch(DNOTIFICATIONSTATUS_LIST);
          resolve(response);
        })
      })
    },
  [DNOTIFICATIONSTATUS_DELETE]: ({ dispatch }, contstatus ) => {
    return new Promise((resolve) => {
      DictContNotificationStatusDataSvc.delete(contstatus)
        .then(response => {
          dispatch(DNOTIFICATIONSTATUS_LIST);
          resolve(response);
        })
    })
  },
  [DNOTIFICATIONSTATUS_UPDATE]: ({ dispatch }, contstatus ) => {
    return new Promise((resolve) => {
      DictContNotificationStatusDataSvc.update(contstatus.id,contstatus)
        .then(response => {
          dispatch(DNOTIFICATIONSTATUS_LIST);
          resolve(response);
        })
    })
  },
};

const mutations = {
  [DNOTIFICATIONSTATUS_LIST]: (state, resp) => {
    state.dnotifstatus = resp;
  },
};

export default {
  state,
  getters,
  actions,
  mutations
};
