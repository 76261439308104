import {http} from "../../api/http-api";

class RepEstimatesDataSvc {
    getAll() {
        return http.get("/repestimate?expand=currency,container,container.type,container.size,createuser,container.shipowner,create_user")
    }
    getOne(id) {
        return http.get(`/repestimate/${id}?expand=currency,container,container.type,container.size,createuser,container.shipowner`)
    }
    create(data) {
        return http.post(`/repestimate`, data)
    }
    update(id, data) {
        return http.patch(`/repestimate/${id}`, data)
    }
    delete(id) {
        return http.delete(`/repestimate/${id}`)
    }
    search(data) {
        return http.get(`/repestimate/search?${data}`)
    }
}

export default new RepEstimatesDataSvc();