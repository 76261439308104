import {LOCATION_CREATE, LOCATION_DELETE, LOCATION_LIST, LOCATION_UPDATE} from "../actions/location";
import LocationsDataSvc from '../../services/LocationDataSvc'

const state = {
  locations: []
};

const getters = {
  getLocations: state => state.locations,
};

const actions = {
  [LOCATION_LIST]: ({ commit }) => {
    return new Promise((resolve) => {
      LocationsDataSvc.getAll()
        .then(response => {
          commit(LOCATION_LIST, response.data);
          resolve(response);
        })
      })
    },
  [LOCATION_CREATE]: ({ dispatch }, locations ) => {
    return new Promise((resolve) => {
      LocationsDataSvc.create(locations)
        .then(response => {
          dispatch(LOCATION_LIST);
          resolve(response);
        })
      })
    },
  [LOCATION_DELETE]: ({ dispatch }, locations ) => {
    return new Promise((resolve) => {
      LocationsDataSvc.delete(locations)
        .then(response => {
          dispatch(LOCATION_LIST);
          resolve(response);
        })
    })
  },
  [LOCATION_UPDATE]: ({ dispatch }, locations ) => {
    return new Promise((resolve) => {
      LocationsDataSvc.update(locations.id,locations)
        .then(response => {
          dispatch(LOCATION_LIST);
          resolve(response);
        })
    })
  },
};

const mutations = {
  [LOCATION_LIST]: (state, resp) => {
    state.locations = resp;
  },
};

export default {
  state,
  getters,
  actions,
  mutations
};
