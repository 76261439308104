import {http} from "../../api/http-api";

class TrainComponentsDataSvc {
    getAll() {
        return http.get("/traincomponents")
    }
    create(data) {
        return http.post(`/traincomponents`, data)
    }
    update(id, data) {
        return http.patch(`/traincomponents/${id}`, data)
    }
    delete(id) {
        return http.delete(`/traincomponents/${id}`)
    }
}

export default new TrainComponentsDataSvc();