import {http} from "../../api/http-api";

class DictContStatusPtiDataSvc {
    getAll() {
        return http.get("/dictcontstatuspti")
    }
    create(data) {
        return http.post(`/dictcontstatuspti`, data)
    }
    update(id, data) {
        return http.patch(`/dictcontstatuspti/${id}`, data)
    }
    delete(id) {
        return http.delete(`/dictcontstatuspti/${id}`)
    }
}

export default new DictContStatusPtiDataSvc();