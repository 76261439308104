import {DBLOCKREASON_CREATE, DBLOCKREASON_DELETE, DBLOCKREASON_LIST, DBLOCKREASON_UPDATE} from "../../actions/dict/dictcontblockreason";
import DictContBlockReasonDataSvc from '../../../services/dict/DictContBlockReasonDataSvc'

const state = {
  dblockreasons: []
};

const getters = {
  getDContBlockReason: state => state.dblockreasons,
};

const actions = {
  [DBLOCKREASON_LIST]: ({ commit }) => {
    return new Promise((resolve) => {
      DictContBlockReasonDataSvc.getAll()
        .then(response => {
          commit(DBLOCKREASON_LIST, response.data);
          resolve(response);
        })
      })
    },
  [DBLOCKREASON_CREATE]: ({ dispatch }, contsize ) => {
    return new Promise((resolve) => {
      DictContBlockReasonDataSvc.create(contsize)
        .then(response => {
          dispatch(DBLOCKREASON_LIST);
          resolve(response);
        })
      })
    },
  [DBLOCKREASON_DELETE]: ({ dispatch }, contsize ) => {
    return new Promise((resolve) => {
      DictContBlockReasonDataSvc.delete(contsize)
        .then(response => {
          dispatch(DBLOCKREASON_LIST);
          resolve(response);
        })
    })
  },
  [DBLOCKREASON_UPDATE]: ({ dispatch }, contsize ) => {
    return new Promise((resolve) => {
      DictContBlockReasonDataSvc.update(contsize.id,contsize)
        .then(response => {
          dispatch(DBLOCKREASON_LIST);
          resolve(response);
        })
    })
  },
};

const mutations = {
  [DBLOCKREASON_LIST]: (state, resp) => {
    state.dblockreasons = resp;
  },
};

export default {
  state,
  getters,
  actions,
  mutations
};
