import {DSTATUSCLEAN_CREATE, DSTATUSCLEAN_DELETE, DSTATUSCLEAN_LIST, DSTATUSCLEAN_UPDATE} from "../../actions/dict/dictcontstatusclean";
import DictContStatusCleanDataSvc from '../../../services/dict/DictContStatusCleanDataSvc'

const state = {
  dstatusclean: []
};

const getters = {
  getDContStatusClean: state => state.dstatusclean,
};

const actions = {
  [DSTATUSCLEAN_LIST]: ({ commit }) => {
    return new Promise((resolve) => {
      DictContStatusCleanDataSvc.getAll()
        .then(response => {
          commit(DSTATUSCLEAN_LIST, response.data);
          resolve(response);
        })
      })
    },
  [DSTATUSCLEAN_CREATE]: ({ dispatch }, contstatusclean ) => {
    return new Promise((resolve) => {
      DictContStatusCleanDataSvc.create(contstatusclean)
        .then(response => {
          dispatch(DSTATUSCLEAN_LIST);
          resolve(response);
        })
      })
    },
  [DSTATUSCLEAN_DELETE]: ({ dispatch }, contstatusclean ) => {
    return new Promise((resolve) => {
      DictContStatusCleanDataSvc.delete(contstatusclean)
        .then(response => {
          dispatch(DSTATUSCLEAN_LIST);
          resolve(response);
        })
    })
  },
  [DSTATUSCLEAN_UPDATE]: ({ dispatch }, contstatusclean ) => {
    return new Promise((resolve) => {
      DictContStatusCleanDataSvc.update(contstatusclean.id,contstatusclean)
        .then(response => {
          dispatch(DSTATUSCLEAN_LIST);
          resolve(response);
        })
    })
  },
};

const mutations = {
  [DSTATUSCLEAN_LIST]: (state, resp) => {
    state.dstatusclean = resp;
  },
};

export default {
  state,
  getters,
  actions,
  mutations
};
