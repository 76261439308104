import {PRODUCTGROUP_CREATE, PRODUCTGROUP_DELETE, PRODUCTGROUP_LIST, PRODUCTGROUP_UPDATE} from "../../actions/invoice/invproductgroups";
import InvProductGroupsDataSvc from '../../../services/invoice/InvProductGroupsDataSvc'

const state = {
  productgroups: []
};

const getters = {
  getProductGroups: state => state.productgroups,
};

const actions = {
  [PRODUCTGROUP_LIST]: ({ commit }) => {
    return new Promise((resolve) => {
      InvProductGroupsDataSvc.getAll()
        .then(response => {
          commit(PRODUCTGROUP_LIST, response.data);
          resolve(response);
        })
      })
    },
  [PRODUCTGROUP_CREATE]: ({ dispatch }, productgroup ) => {
    return new Promise((resolve) => {
      InvProductGroupsDataSvc.create(productgroup)
        .then(response => {
          dispatch(PRODUCTGROUP_LIST);
          resolve(response);
        })
      })
    },
  [PRODUCTGROUP_DELETE]: ({ dispatch }, productgroup ) => {
    return new Promise((resolve) => {
      InvProductGroupsDataSvc.delete(productgroup)
        .then(response => {
          dispatch(PRODUCTGROUP_LIST);
          resolve(response);
        })
    })
  },
  [PRODUCTGROUP_UPDATE]: ({ dispatch }, productgroup ) => {
    return new Promise((resolve) => {
      InvProductGroupsDataSvc.update(productgroup.id,productgroup)
        .then(response => {
          dispatch(PRODUCTGROUP_LIST);
          resolve(response);
        })
    })
  },
};

const mutations = {
  [PRODUCTGROUP_LIST]: (state, resp) => {
    state.productgroups = resp;
  },
};

export default {
  state,
  getters,
  actions,
  mutations
};
