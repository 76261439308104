import {RATESTYPE_CREATE, RATESTYPE_DELETE, RATESTYPE_LIST, RATESTYPE_UPDATE} from "../../actions/rates/ratesstoragetype";
import RatesStorageTypeDataSvc from '../../../services/rates/RatesStorageTypeDataSvc'

const state = {
  ratestype: []
};

const getters = {
  getRatesStorageType: state => state.ratestype,
};

const actions = {
  [RATESTYPE_LIST]: ({ commit }) => {
    return new Promise((resolve) => {
      RatesStorageTypeDataSvc.getAll()
        .then(response => {
          commit(RATESTYPE_LIST, response.data);
          resolve(response);
        })
      })
    },
  [RATESTYPE_CREATE]: ({ dispatch }, rates )=> {
    return new Promise((resolve) => {
      RatesStorageTypeDataSvc.create(rates)
        .then(response => {
          dispatch(RATESTYPE_LIST);
          resolve(response);
        })
      })
    },
  [RATESTYPE_DELETE]: ({ dispatch }, rates )=> {
    return new Promise((resolve) => {
      RatesStorageTypeDataSvc.delete(rates)
        .then(response => {
          dispatch(RATESTYPE_LIST);
          resolve(response);
        })
    })
  },
  [RATESTYPE_UPDATE]: ({ dispatch }, rates )=> {
    return new Promise((resolve) => {
      RatesStorageTypeDataSvc.update(rates.id,rates)
        .then(response => {
          dispatch(RATESTYPE_LIST);
          resolve(response);
        })
    })
  },
};

const mutations = {
  [RATESTYPE_LIST]: (state, resp) => {
    state.ratestype = resp;
  },
};

export default {
  state,
  getters,
  actions,
  mutations
};
