import {RATESNUMERICCHOICEPOSITIONS_CREATE, RATESNUMERICCHOICEPOSITIONS_DELETE, RATESNUMERICCHOICEPOSITIONS_LIST, RATESNUMERICCHOICEPOSITIONS_UPDATE} from "../../actions/rates/ratesnumericchoicepositions";
import RatesNumericChoicePositionsDataSvc from '../../../services/rates/RatesNumericChoicePositionsDataSvc'

const state = {
  ratesnumericchoicepositions: []
};

const getters = {
  getRatesNumericChoicePositions: state => state.ratesnumericchoicepositions,
};

const actions = {
  [RATESNUMERICCHOICEPOSITIONS_LIST]: ({ commit }) => {
    return new Promise((resolve) => {
      RatesNumericChoicePositionsDataSvc.getAll()
        .then(response => {
          commit(RATESNUMERICCHOICEPOSITIONS_LIST, response.data);
          resolve(response);
        })
      })
    },
  [RATESNUMERICCHOICEPOSITIONS_CREATE]: ({ dispatch }, rates )=> {
    return new Promise((resolve) => {
      RatesNumericChoicePositionsDataSvc.create(rates)
        .then(response => {
          dispatch(RATESNUMERICCHOICEPOSITIONS_LIST);
          resolve(response);
        })
      })
    },
  [RATESNUMERICCHOICEPOSITIONS_DELETE]: ({ dispatch }, rates )=> {
    return new Promise((resolve) => {
      RatesNumericChoicePositionsDataSvc.delete(rates)
        .then(response => {
          dispatch(RATESNUMERICCHOICEPOSITIONS_LIST);
          resolve(response);
        })
    })
  },
  [RATESNUMERICCHOICEPOSITIONS_UPDATE]: ({ dispatch }, rates )=> {
    return new Promise((resolve) => {
      RatesNumericChoicePositionsDataSvc.update(rates.id,rates)
        .then(response => {
          dispatch(RATESNUMERICCHOICEPOSITIONS_LIST);
          resolve(response);
        })
    })
  },
};

const mutations = {
  [RATESNUMERICCHOICEPOSITIONS_LIST]: (state, resp) => {
    state.ratesnumericchoicepositions = resp;
  },
};

export default {
  state,
  getters,
  actions,
  mutations
};
