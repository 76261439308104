import {http} from "../../api/http-api";

class ContInterchangeDataSvc {
    getAll() {
        return http.get("/continterchange")
    }
    create(data) {
        return http.post(`/continterchange`, data)
    }
    update(id, data) {
        return http.patch(`/continterchange/${id}`, data)
    }
    delete(id) {
        return http.delete(`/continterchange/${id}`)
    }
}

export default new ContInterchangeDataSvc();