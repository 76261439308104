import {CONTNOTIFICATION_CREATE, CONTNOTIFICATION_DELETE, CONTNOTIFICATION_LIST, CONTNOTIFICATION_UPDATE} from "../../actions/cont/contnotification";
import ContServiceCleanDataSvc from '../../../services/cont/ContNotificationDataSvc'

const state = {
  contnotification: []
};

const getters = {
  getContnotification: state => state.contnotification,
};

const actions = {
  [CONTNOTIFICATION_LIST]: ({ commit }) => {
    return new Promise((resolve) => {
      ContServiceCleanDataSvc.getAll()
        .then(response => {
          commit(CONTNOTIFICATION_LIST, response.data);
          resolve(response);
        })
      })
    },
  [CONTNOTIFICATION_CREATE]: ({ dispatch }, contnotification ) => {
    return new Promise((resolve) => {
      ContServiceCleanDataSvc.create(contnotification)
        .then(response => {
          dispatch(CONTNOTIFICATION_LIST);
          resolve(response);
        })
      })
    },
  [CONTNOTIFICATION_DELETE]: ({ dispatch }, contnotification ) => {
    return new Promise((resolve) => {
      ContServiceCleanDataSvc.delete(contnotification)
        .then(response => {
          dispatch(CONTNOTIFICATION_LIST);
          resolve(response);
        })
    })
  },
  [CONTNOTIFICATION_UPDATE]: ({ dispatch }, contnotification ) => {
    return new Promise((resolve) => {
      ContServiceCleanDataSvc.update(contnotification.id,contnotification)
        .then(response => {
          dispatch(CONTNOTIFICATION_LIST);
          resolve(response);
        })
    })
  },
};

const mutations = {
  [CONTNOTIFICATION_LIST]: (state, resp) => {
    state.contnotification = resp;
  },
};

export default {
  state,
  getters,
  actions,
  mutations
};
