import {INVOICENO_CREATE, INVOICENO_DELETE, INVOICENO_LIST, INVOICENO_UPDATE} from "../../actions/invoice/invoiceno";
import InvoiceNoDataSvc from '../../../services/invoice/InvoiceNoDataSvc'

const state = {
  invoicenos: []
};

const getters = {
  getInvoiceNo: state => state.invoicenos,
};

const actions = {
  [INVOICENO_LIST]: ({ commit }) => {
    return new Promise((resolve) => {
      InvoiceNoDataSvc.getAll()
        .then(response => {
          commit(INVOICENO_LIST, response.data);
          resolve(response);
        })
      })
    },
  [INVOICENO_CREATE]: ({ dispatch }, invoice ) => {
    return new Promise((resolve) => {
      InvoiceNoDataSvc.create(invoice)
        .then(response => {
          dispatch(INVOICENO_LIST);
          resolve(response);
        })
      })
    },
  [INVOICENO_DELETE]: ({ dispatch }, invoice ) => {
    return new Promise((resolve) => {
      InvoiceNoDataSvc.delete(invoice)
        .then(response => {
          dispatch(INVOICENO_LIST);
          resolve(response);
        })
    })
  },
  [INVOICENO_UPDATE]: ({ dispatch }, invoice ) => {
    return new Promise((resolve) => {
      InvoiceNoDataSvc.update(invoice.id,invoice)
        .then(response => {
          dispatch(INVOICENO_LIST);
          resolve(response);
        })
    })
  },
};

const mutations = {
  [INVOICENO_LIST]: (state, resp) => {
    state.invoicenos = resp;
  },
};

export default {
  state,
  getters,
  actions,
  mutations
};
