import {TARIFFLOCATIONS_CREATE, TARIFFLOCATIONS_DELETE, TARIFFLOCATIONS_LIST, TARIFFLOCATIONS_UPDATE} from "../../actions/repair/reptarifflocations";
import RepTariffDataSvc from '../../../services/repair/RepTariffLocationsDataSvc'

const state = {
  tarifflocations: []
};

const getters = {
  getTariffLocations: state => state.tarifflocations,
};

const actions = {
  [TARIFFLOCATIONS_LIST]: ({ commit }) => {
    return new Promise((resolve) => {
      RepTariffDataSvc.getAll()
        .then(response => {
          commit(TARIFFLOCATIONS_LIST, response.data);
          resolve(response);
        })
      })
    },
  [TARIFFLOCATIONS_CREATE]: ({ dispatch }, tarifflocations ) => {
    return new Promise((resolve) => {
      RepTariffDataSvc.create(tarifflocations)
        .then(response => {
          dispatch(TARIFFLOCATIONS_LIST);
          resolve(response);
        })
      })
    },
  [TARIFFLOCATIONS_DELETE]: ({ dispatch }, tarifflocations ) => {
    return new Promise((resolve) => {
      RepTariffDataSvc.delete(tarifflocations)
        .then(response => {
          dispatch(TARIFFLOCATIONS_LIST);
          resolve(response);
        })
    })
  },
  [TARIFFLOCATIONS_UPDATE]: ({ dispatch }, tarifflocations ) => {
    return new Promise((resolve) => {
      RepTariffDataSvc.update(tarifflocations.id,tarifflocations)
        .then(response => {
          dispatch(TARIFFLOCATIONS_LIST);
          resolve(response);
        })
    })
  },
};

const mutations = {
  [TARIFFLOCATIONS_LIST]: (state, resp) => {
    state.tarifflocations = resp;
  },
};

export default {
  state,
  getters,
  actions,
  mutations
};
