import {COUNTRY_CREATE, COUNTRY_DELETE, COUNTRY_LIST, COUNTRY_UPDATE} from "../actions/country";
import CountriesDataSvc from '../../services/CountriesDataSvc'

const state = {
  country: []
};

const getters = {
  getCountry: state => state.country,
};

const actions = {
  [COUNTRY_LIST]: ({ commit }) => {
    return new Promise((resolve) => {
      CountriesDataSvc.getAll()
        .then(response => {
          commit(COUNTRY_LIST, response.data);
          resolve(response);
        })
      })
    },
  [COUNTRY_CREATE]: ({ dispatch }, country ) => {
    return new Promise((resolve) => {
      CountriesDataSvc.create(country)
        .then(response => {
          dispatch(COUNTRY_LIST);
          resolve(response);
        })
      })
    },
  [COUNTRY_DELETE]: ({ dispatch }, country ) => {
    return new Promise((resolve) => {
      CountriesDataSvc.delete(country)
        .then(response => {
          dispatch(COUNTRY_LIST);
          resolve(response);
        })
    })
  },
  [COUNTRY_UPDATE]: ({ dispatch }, country ) => {
    return new Promise((resolve) => {
      CountriesDataSvc.update(country.id,country)
        .then(response => {
          dispatch(COUNTRY_LIST);
          resolve(response);
        })
    })
  },
};

const mutations = {
  [COUNTRY_LIST]: (state, resp) => {
    state.country = resp;
  },
};

export default {
  state,
  getters,
  actions,
  mutations
};
