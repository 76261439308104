import {INTERCHANGE_CREATE, INTERCHANGE_DELETE, INTERCHANGE_LIST, INTERCHANGE_UPDATE} from "../../actions/cont/continterchange";
import ContInterchangeDataSvc from '../../../services/cont/ContInterchangeDataSvc'

const state = {
  interdamage: []
};

const getters = {
  getInterdamage: state => state.interdamage,
};

const actions = {
  [INTERCHANGE_LIST]: ({ commit }) => {
    return new Promise((resolve) => {
      ContInterchangeDataSvc.getAll()
        .then(response => {
          commit(INTERCHANGE_LIST, response.data);
          resolve(response);
        })
      })
    },
  [INTERCHANGE_CREATE]: ({ dispatch }, interdamage ) => {
    return new Promise((resolve) => {
      ContInterchangeDataSvc.create(interdamage)
        .then(response => {
          dispatch(INTERCHANGE_LIST);
          resolve(response);
        })
      })
    },
  [INTERCHANGE_DELETE]: ({ dispatch }, interdamage ) => {
    return new Promise((resolve) => {
      ContInterchangeDataSvc.delete(interdamage)
        .then(response => {
          dispatch(INTERCHANGE_LIST);
          resolve(response);
        })
    })
  },
  [INTERCHANGE_UPDATE]: ({ dispatch }, interdamage ) => {
    return new Promise((resolve) => {
      ContInterchangeDataSvc.update(interdamage.id,interdamage)
        .then(response => {
          dispatch(INTERCHANGE_LIST);
          resolve(response);
        })
    })
  },
};

const mutations = {
  [INTERCHANGE_LIST]: (state, resp) => {
    state.interdamage = resp;
  },
};

export default {
  state,
  getters,
  actions,
  mutations
};
