import {DSTATUSNEUTRAL_CREATE, DSTATUSNEUTRAL_DELETE, DSTATUSNEUTRAL_LIST, DSTATUSNEUTRAL_UPDATE} from "../../actions/dict/dictcontstatusneutral";
import DictContStatusNeutralDataSvc from '../../../services/dict/DictContStatusNeutralDataSvc'

const state = {
  dstatusneutral: []
};

const getters = {
  getDContStatusNeutral: state => state.dstatusneutral,
};

const actions = {
  [DSTATUSNEUTRAL_LIST]: ({ commit }) => {
    return new Promise((resolve) => {
      DictContStatusNeutralDataSvc.getAll()
        .then(response => {
          commit(DSTATUSNEUTRAL_LIST, response.data);
          resolve(response);
        })
      })
    },
  [DSTATUSNEUTRAL_CREATE]: ({ dispatch }, contstatusneutral ) => {
    return new Promise((resolve) => {
      DictContStatusNeutralDataSvc.create(contstatusneutral)
        .then(response => {
          dispatch(DSTATUSNEUTRAL_LIST);
          resolve(response);
        })
      })
    },
  [DSTATUSNEUTRAL_DELETE]: ({ dispatch }, contstatusneutral ) => {
    return new Promise((resolve) => {
      DictContStatusNeutralDataSvc.delete(contstatusneutral)
        .then(response => {
          dispatch(DSTATUSNEUTRAL_LIST);
          resolve(response);
        })
    })
  },
  [DSTATUSNEUTRAL_UPDATE]: ({ dispatch }, contstatusneutral ) => {
    return new Promise((resolve) => {
      DictContStatusNeutralDataSvc.update(contstatusneutral.id,contstatusneutral)
        .then(response => {
          dispatch(DSTATUSNEUTRAL_LIST);
          resolve(response);
        })
    })
  },
};

const mutations = {
  [DSTATUSNEUTRAL_LIST]: (state, resp) => {
    state.dstatusneutral = resp;
  },
};

export default {
  state,
  getters,
  actions,
  mutations
};
