import {PAYMENT_CREATE, PAYMENT_DELETE, PAYMENT_LIST, PAYMENT_UPDATE} from "../actions/invoice/payment";
import PaymentDataSvc from '../../services/invoice/PaymentDataSvc'

const state = {
  payment: []
};

const getters = {
  getPayment: state => state.payment,
};

const actions = {
  [PAYMENT_LIST]: ({ commit }) => {
    return new Promise((resolve) => {
      PaymentDataSvc.getAll()
        .then(response => {
          commit(PAYMENT_LIST, response.data);
          resolve(response);
        })
      })
    },
  [PAYMENT_CREATE]: ({ dispatch }, payment ) => {
    return new Promise((resolve) => {
      PaymentDataSvc.create(payment)
        .then(response => {
          dispatch(PAYMENT_LIST);
          resolve(response);
        })
      })
    },
  [PAYMENT_DELETE]: ({ dispatch }, payment ) => {
    return new Promise((resolve) => {
      PaymentDataSvc.delete(payment)
        .then(response => {
          dispatch(PAYMENT_LIST);
          resolve(response);
        })
    })
  },
  [PAYMENT_UPDATE]: ({ dispatch }, payment ) => {
    return new Promise((resolve) => {
      PaymentDataSvc.update(payment.id,payment)
        .then(response => {
          dispatch(PAYMENT_LIST);
          resolve(response);
        })
    })
  },
};

const mutations = {
  [PAYMENT_LIST]: (state, resp) => {
    state.payment = resp;
  },
};

export default {
  state,
  getters,
  actions,
  mutations
};
