import {PLACE_CREATE, PLACE_DELETE, PLACE_LIST, PLACE_UPDATE} from "../actions/places";
import PlacesDataSvc from '../../services/PlacesDataSvc'

const state = {
  places: []
};

const getters = {
  getPlaces: state => state.places,
};

const actions = {
  [PLACE_LIST]: ({ commit }) => {
    return new Promise((resolve) => {
      PlacesDataSvc.getAll()
        .then(response => {
          commit(PLACE_LIST, response.data);
          resolve(response);
        })
      })
    },
  [PLACE_CREATE]: ({ dispatch }, places ) => {
    return new Promise((resolve) => {
      PlacesDataSvc.create(places)
        .then(response => {
          dispatch(PLACE_LIST);
          resolve(response);
        })
      })
    },
  [PLACE_DELETE]: ({ dispatch }, places ) => {
    return new Promise((resolve) => {
      PlacesDataSvc.delete(places)
        .then(response => {
          dispatch(PLACE_LIST);
          resolve(response);
        })
    })
  },
  [PLACE_UPDATE]: ({ dispatch }, places ) => {
    return new Promise((resolve) => {
      PlacesDataSvc.update(places.id,places)
        .then(response => {
          dispatch(PLACE_LIST);
          resolve(response);
        })
    })
  },
};

const mutations = {
  [PLACE_LIST]: (state, resp) => {
    state.places = resp;
  },
};

export default {
  state,
  getters,
  actions,
  mutations
};
