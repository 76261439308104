import {TARIFFITEMS_CREATE, TARIFFITEMS_DELETE, TARIFFITEMS_LIST, TARIFFITEMS_UPDATE} from "../../actions/tariff/tariffitems";
import TariffItemsDataSvc from '../../../services/tariff/TariffItemsDataSvc'

const state = {
  tariffitems: []
};

const getters = {
  getTariffItems: state => state.tariffitems,
};

const actions = {
  [TARIFFITEMS_LIST]: ({ commit }) => {
    return new Promise((resolve) => {
      TariffItemsDataSvc.getAll()
        .then(response => {
          commit(TARIFFITEMS_LIST, response.data);
          resolve(response);
        })
      })
    },
  [TARIFFITEMS_CREATE]: ({ dispatch }, rates )=> {
    return new Promise((resolve) => {
      TariffItemsDataSvc.create(rates)
        .then(response => {
          dispatch(TARIFFITEMS_LIST);
          resolve(response);
        })
      })
    },
  [TARIFFITEMS_DELETE]: ({ dispatch }, rates )=> {
    return new Promise((resolve) => {
      TariffItemsDataSvc.delete(rates)
        .then(response => {
          dispatch(TARIFFITEMS_LIST);
          resolve(response);
        })
    })
  },
  [TARIFFITEMS_UPDATE]: ({ dispatch }, rates )=> {
    return new Promise((resolve) => {
      TariffItemsDataSvc.update(rates.id,rates)
        .then(response => {
          dispatch(TARIFFITEMS_LIST);
          resolve(response);
        })
    })
  },
};

const mutations = {
  [TARIFFITEMS_LIST]: (state, resp) => {
    state.tariffitems = resp;
  },
};

export default {
  state,
  getters,
  actions,
  mutations
};
