import {TRAINTARIFF_CREATE, TRAINTARIFF_DELETE, TRAINTARIFF_LIST, TRAINTARIFF_UPDATE} from "../../actions/rail/traintariff";
import TrainTariffDataSvc from '../../../services/rail/TrainTariffDataSvc'

const state = {
  traintariff: []
};

const getters = {
  getTrainTariff: state => state.traintariff,
};

const actions = {
  [TRAINTARIFF_LIST]: ({ commit }) => {
    return new Promise((resolve) => {
      TrainTariffDataSvc.getAll()
        .then(response => {
          commit(TRAINTARIFF_LIST, response.data);
          resolve(response);
        })
      })
    },
  [TRAINTARIFF_CREATE]: ({ dispatch }, traintariff )=> {
    return new Promise((resolve) => {
      TrainTariffDataSvc.create(traintariff)
        .then(response => {
          dispatch(TRAINTARIFF_LIST);
          resolve(response);
        })
      })
    },
  [TRAINTARIFF_DELETE]: ({ dispatch }, traintariff )=> {
    return new Promise((resolve) => {
      TrainTariffDataSvc.delete(traintariff)
        .then(response => {
          dispatch(TRAINTARIFF_LIST);
          resolve(response);
        })
    })
  },
  [TRAINTARIFF_UPDATE]: ({ dispatch }, traintariff )=> {
    return new Promise((resolve) => {
      TrainTariffDataSvc.update(traintariff.id,traintariff)
        .then(response => {
          dispatch(TRAINTARIFF_LIST);
          resolve(response);
        })
    })
  },
};

const mutations = {
  [TRAINTARIFF_LIST]: (state, resp) => {
    state.traintariff = resp;
  },
};

export default {
  state,
  getters,
  actions,
  mutations
};
