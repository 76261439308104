import {http} from "../../api/http-api";

class DictContServicePayerDataSvc {
    getAll() {
        return http.get("/dictcontservicepayer")
    }
    create(data) {
        return http.post(`/dictcontservicepayer`, data)
    }
    update(id, data) {
        return http.patch(`/dictcontservicepayer/${id}`, data)
    }
    delete(id) {
        return http.delete(`/dictcontservicepayer/${id}`)
    }
}

export default new DictContServicePayerDataSvc();