import {DSTATUSTYPE_CREATE, DSTATUSTYPE_DELETE, DSTATUSTYPE_LIST, DSTATUSTYPE_UPDATE} from "../../actions/dict/dictcontstatustype";
import DictContStatusTypeDataSvc from '../../../services/dict/DictContStatusTypeDataSvc'

const state = {
  dstatustype: []
};

const getters = {
  getDContStatusType: state => state.dstatustype,
};

const actions = {
  [DSTATUSTYPE_LIST]: ({ commit }) => {
    return new Promise((resolve) => {
      DictContStatusTypeDataSvc.getAll()
        .then(response => {
          commit(DSTATUSTYPE_LIST, response.data);
          resolve(response);
        })
      })
    },
  [DSTATUSTYPE_CREATE]: ({ dispatch }, contstatustype ) => {
    return new Promise((resolve) => {
      DictContStatusTypeDataSvc.create(contstatustype)
        .then(response => {
          dispatch(DSTATUSTYPE_LIST);
          resolve(response);
        })
      })
    },
  [DSTATUSTYPE_DELETE]: ({ dispatch }, contstatustype ) => {
    return new Promise((resolve) => {
      DictContStatusTypeDataSvc.delete(contstatustype)
        .then(response => {
          dispatch(DSTATUSTYPE_LIST);
          resolve(response);
        })
    })
  },
  [DSTATUSTYPE_UPDATE]: ({ dispatch }, contstatustype ) => {
    return new Promise((resolve) => {
      DictContStatusTypeDataSvc.update(contstatustype.id,contstatustype)
        .then(response => {
          dispatch(DSTATUSTYPE_LIST);
          resolve(response);
        })
    })
  },
};

const mutations = {
  [DSTATUSTYPE_LIST]: (state, resp) => {
    state.dstatustype = resp;
  },
};

export default {
  state,
  getters,
  actions,
  mutations
};
