import {VATRATE_CREATE, VATRATE_DELETE, VATRATE_LIST, VATRATE_UPDATE} from "../../actions/invoice/invvatrates";
import InvVatRatesDataSvc from '../../../services/invoice/InvVatRatesDataSvc'

const state = {
  vatrates: []
};

const getters = {
  getVatRates: state => state.vatrates,
};

const actions = {
  [VATRATE_LIST]: ({ commit }) => {
    return new Promise((resolve) => {
      InvVatRatesDataSvc.getAll()
        .then(response => {
          commit(VATRATE_LIST, response.data);
          resolve(response);
        })
      })
    },
  [VATRATE_CREATE]: ({ dispatch }, vatrate ) => {
    return new Promise((resolve) => {
      InvVatRatesDataSvc.create(vatrate)
        .then(response => {
          dispatch(VATRATE_LIST);
          resolve(response);
        })
      })
    },
  [VATRATE_DELETE]: ({ dispatch }, vatrate ) => {
    return new Promise((resolve) => {
      InvVatRatesDataSvc.delete(vatrate)
        .then(response => {
          dispatch(VATRATE_LIST);
          resolve(response);
        })
    })
  },
  [VATRATE_UPDATE]: ({ dispatch }, vatrate ) => {
    return new Promise((resolve) => {
      InvVatRatesDataSvc.update(vatrate.id,vatrate)
        .then(response => {
          dispatch(VATRATE_LIST);
          resolve(response);
        })
    })
  },
};

const mutations = {
  [VATRATE_LIST]: (state, resp) => {
    state.vatrates = resp;
  },
};

export default {
  state,
  getters,
  actions,
  mutations
};
