import {STATUS_CREATE, STATUS_DELETE, STATUS_LIST, STATUS_UPDATE, STATUSEXP_LIST} from "../../actions/cont/contstatus";
import ContStatusDataSvc from '../../../services/cont/ContStatusDataSvc'

const state = {
  contstatus: [],
  contstatusexp: []
};

const getters = {
  getContstatus: state => state.contstatus,
  getContstatusExp: state => state.contstatusexp,
};

const actions = {
  [STATUS_LIST]: ({ commit }) => {
    return new Promise((resolve) => {
      ContStatusDataSvc.getAll()
        .then(response => {
          commit(STATUS_LIST, response.data);
          resolve(response);
        })
      })
    },
    [STATUSEXP_LIST]: ({ commit }) => {
      return new Promise((resolve) => {
        ContStatusDataSvc.getAllexp()
          .then(response => {
            commit(STATUSEXP_LIST, response.data);
            resolve(response);
          })
        })
      },
    [STATUS_CREATE]: ({ dispatch }, contstatus ) => {
    return new Promise((resolve) => {
      ContStatusDataSvc.create(contstatus)
        .then(response => {
          dispatch(STATUS_LIST);
          resolve(response);
        })
      })
    },
  [STATUS_DELETE]: ({ dispatch }, contstatus ) => {
    return new Promise((resolve) => {
      ContStatusDataSvc.delete(contstatus)
        .then(response => {
          dispatch(STATUS_LIST);
          resolve(response);
        })
    })
  },
  [STATUS_UPDATE]: ({ dispatch }, contstatus ) => {
    return new Promise((resolve) => {
      ContStatusDataSvc.update(contstatus.id,contstatus)
        .then(response => {
          dispatch(STATUS_LIST);
          resolve(response);
        })
    })
  },
};

const mutations = {
  [STATUS_LIST]: (state, resp) => {
    state.contstatus = resp;
  },
  [STATUSEXP_LIST]: (state, resp) => {
    state.contstatusexp = resp;
  },
};

export default {
  state,
  getters,
  actions,
  mutations
};
