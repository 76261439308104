import {TARIFF_CREATE, TARIFF_DELETE, TARIFF_LIST, TARIFF_UPDATE} from "../../actions/tariff/tariff";
import TariffDataSvc from '../../../services/tariff/TariffDataSvc'

const state = {
  tariff: []
};

const getters = {
  getTariff: state => state.tariff,
};

const actions = {
  [TARIFF_LIST]: ({ commit }) => {
    return new Promise((resolve) => {
      TariffDataSvc.getAll()
        .then(response => {
          commit(TARIFF_LIST, response.data);
          resolve(response);
        })
      })
    },
  [TARIFF_CREATE]: ({ dispatch }, rates )=> {
    return new Promise((resolve) => {
      TariffDataSvc.create(rates)
        .then(response => {
          dispatch(TARIFF_LIST);
          resolve(response);
        })
      })
    },
  [TARIFF_DELETE]: ({ dispatch }, rates )=> {
    return new Promise((resolve) => {
      TariffDataSvc.delete(rates)
        .then(response => {
          dispatch(TARIFF_LIST);
          resolve(response);
        })
    })
  },
  [TARIFF_UPDATE]: ({ dispatch }, rates )=> {
    return new Promise((resolve) => {
      TariffDataSvc.update(rates.id,rates)
        .then(response => {
          dispatch(TARIFF_LIST);
          resolve(response);
        })
    })
  },
};

const mutations = {
  [TARIFF_LIST]: (state, resp) => {
    state.tariff = resp;
  },
};

export default {
  state,
  getters,
  actions,
  mutations
};
