<template>
  <div class="v-application--wrap">
    <layout-drawer />
    <layout-toolbar />
    <layout-content />
    <layout-footer />
  </div>
</template>

<script>
import LayoutDrawer from './LayoutDrawer.vue';
import LayoutToolbar from './LayoutToolbar.vue';
import LayoutContent from './LayoutContent.vue';
import LayoutFooter from './LayoutFooter.vue';

export default {
  name: 'Layout',
  components: {
    LayoutDrawer,
    LayoutToolbar,
    LayoutContent,
    LayoutFooter,
  },
  data: () => ({}),
};
</script>
