import {RATESWASHPOSITIONS_CREATE, RATESWASHPOSITIONS_DELETE, RATESWASHPOSITIONS_LIST, RATESWASHPOSITIONS_UPDATE} from "../../actions/rates/rateswashpositions";
import RatesWashPositionsDataSvc from '../../../services/rates/RatesWashPositionsDataSvc'

const state = {
  rateswashpositions: []
};

const getters = {
  getRatesWashPositions: state => state.rateswashpositions,
};

const actions = {
  [RATESWASHPOSITIONS_LIST]: ({ commit }) => {
    return new Promise((resolve) => {
      RatesWashPositionsDataSvc.getAll()
        .then(response => {
          commit(RATESWASHPOSITIONS_LIST, response.data);
          resolve(response);
        })
      })
    },
  [RATESWASHPOSITIONS_CREATE]: ({ dispatch }, rates )=> {
    return new Promise((resolve) => {
      RatesWashPositionsDataSvc.create(rates)
        .then(response => {
          dispatch(RATESWASHPOSITIONS_LIST);
          resolve(response);
        })
      })
    },
  [RATESWASHPOSITIONS_DELETE]: ({ dispatch }, rates )=> {
    return new Promise((resolve) => {
      RatesWashPositionsDataSvc.delete(rates)
        .then(response => {
          dispatch(RATESWASHPOSITIONS_LIST);
          resolve(response);
        })
    })
  },
  [RATESWASHPOSITIONS_UPDATE]: ({ dispatch }, rates )=> {
    return new Promise((resolve) => {
      RatesWashPositionsDataSvc.update(rates.id,rates)
        .then(response => {
          dispatch(RATESWASHPOSITIONS_LIST);
          resolve(response);
        })
    })
  },
};

const mutations = {
  [RATESWASHPOSITIONS_LIST]: (state, resp) => {
    state.rateswashpositions = resp;
  },
};

export default {
  state,
  getters,
  actions,
  mutations
};
