import {TRAINCOMPONENTS_CREATE, TRAINCOMPONENTS_DELETE, TRAINCOMPONENTS_LIST, TRAINCOMPONENTS_UPDATE} from "../../actions/rail/traincomponents";
import TrainComponentsDataSvc from '../../../services/rail/TrainComponentsDataSvc'

const state = {
  traincomponents: []
};

const getters = {
  getTrainComponents: state => state.traincomponents,
};

const actions = {
  [TRAINCOMPONENTS_LIST]: ({ commit }) => {
    return new Promise((resolve) => {
      TrainComponentsDataSvc.getAll()
        .then(response => {
          commit(TRAINCOMPONENTS_LIST, response.data);
          resolve(response);
        })
      })
    },
  [TRAINCOMPONENTS_CREATE]: ({ dispatch }, traincomponents )=> {
    return new Promise((resolve) => {
      TrainComponentsDataSvc.create(traincomponents)
        .then(response => {
          dispatch(TRAINCOMPONENTS_LIST);
          resolve(response);
        })
      })
    },
  [TRAINCOMPONENTS_DELETE]: ({ dispatch }, traincomponents )=> {
    return new Promise((resolve) => {
      TrainComponentsDataSvc.delete(traincomponents)
        .then(response => {
          dispatch(TRAINCOMPONENTS_LIST);
          resolve(response);
        })
    })
  },
  [TRAINCOMPONENTS_UPDATE]: ({ dispatch }, traincomponents )=> {
    return new Promise((resolve) => {
      TrainComponentsDataSvc.update(traincomponents.id,traincomponents)
        .then(response => {
          dispatch(TRAINCOMPONENTS_LIST);
          resolve(response);
        })
    })
  },
};

const mutations = {
  [TRAINCOMPONENTS_LIST]: (state, resp) => {
    state.traincomponents = resp;
  },
};

export default {
  state,
  getters,
  actions,
  mutations
};
