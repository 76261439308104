import {DTRANSPORT_CREATE, DTRANSPORT_DELETE, DTRANSPORT_LIST, DTRANSPORT_UPDATE} from "../../actions/dict/dictconttransport";
import DictContTransportDataSvc from '../../../services/dict/DictContTransportDataSvc'

const state = {
  transport: []
};

const getters = {
  getTransports: state => state.transport,
};

const actions = {
  [DTRANSPORT_LIST]: ({ commit }) => {
    return new Promise((resolve) => {
      DictContTransportDataSvc.getAll()
        .then(response => {
          commit(DTRANSPORT_LIST, response.data);
          resolve(response);
        })
      })
    },
  [DTRANSPORT_CREATE]: ({ dispatch }, conttransport ) => {
    return new Promise((resolve) => {
      DictContTransportDataSvc.create(conttransport)
        .then(response => {
          dispatch(DTRANSPORT_LIST);
          resolve(response);
        })
      })
    },
  [DTRANSPORT_DELETE]: ({ dispatch }, conttransport ) => {
    return new Promise((resolve) => {
      DictContTransportDataSvc.delete(conttransport)
        .then(response => {
          dispatch(DTRANSPORT_LIST);
          resolve(response);
        })
    })
  },
  [DTRANSPORT_UPDATE]: ({ dispatch }, conttransport ) => {
    return new Promise((resolve) => {
      DictContTransportDataSvc.update(conttransport.id,conttransport)
        .then(response => {
          dispatch(DTRANSPORT_LIST);
          resolve(response);
        })
    })
  },
};

const mutations = {
  [DTRANSPORT_LIST]: (state, resp) => {
    state.transport = resp;
  },
};

export default {
  state,
  getters,
  actions,
  mutations
};
