import {http} from "../../api/http-api";

class ContStatusCurrentDataSvc {
    getAllexp() {
        return http.get("/contstatuscurrent")
    }
    getAll() {
        return http.get("/contstatuscurrent")
    }
    create(data) {
        return http.post(`/contstatuscurrent`, data)
    }
    update(id, data) {
        return http.patch(`/contstatuscurrent/${id}`, data)
    }
    delete(id) {
        return http.delete(`/contstatuscurrent/${id}`)
    }
}

export default new ContStatusCurrentDataSvc();