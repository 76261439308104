import {TRAINSERVICES_CREATE, TRAINSERVICES_DELETE, TRAINSERVICES_LIST, TRAINSERVICES_UPDATE} from "../../actions/rail/trainservices";
import TrainServicesDataSvc from '../../../services/rail/TrainServicesDataSvc'

const state = {
  trainservices: []
};

const getters = {
  getTrainServices: state => state.trainservices,
};

const actions = {
  [TRAINSERVICES_LIST]: ({ commit }) => {
    return new Promise((resolve) => {
      TrainServicesDataSvc.getAll()
        .then(response => {
          commit(TRAINSERVICES_LIST, response.data);
          resolve(response);
        })
      })
    },
  [TRAINSERVICES_CREATE]: ({ dispatch }, trainservices )=> {
    return new Promise((resolve) => {
      TrainServicesDataSvc.create(trainservices)
        .then(response => {
          dispatch(TRAINSERVICES_LIST);
          resolve(response);
        })
      })
    },
  [TRAINSERVICES_DELETE]: ({ dispatch }, trainservices )=> {
    return new Promise((resolve) => {
      TrainServicesDataSvc.delete(trainservices)
        .then(response => {
          dispatch(TRAINSERVICES_LIST);
          resolve(response);
        })
    })
  },
  [TRAINSERVICES_UPDATE]: ({ dispatch }, trainservices )=> {
    return new Promise((resolve) => {
      TrainServicesDataSvc.update(trainservices.id,trainservices)
        .then(response => {
          dispatch(TRAINSERVICES_LIST);
          resolve(response);
        })
    })
  },
};

const mutations = {
  [TRAINSERVICES_LIST]: (state, resp) => {
    state.trainservices = resp;
  },
};

export default {
  state,
  getters,
  actions,
  mutations
};
