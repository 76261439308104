import {TARIFFCONTSERVICES_CREATE, TARIFFCONTSERVICES_DELETE, TARIFFCONTSERVICES_LIST, TARIFFCONTSERVICES_UPDATE} from "../../actions/tariff/tariffcontservices";
import TariffContServices from '../../../services/tariff/TariffContServicesDataSvc'

const state = {
  tariffcontservices: []
};

const getters = {
  getTariffContServices: state => state.tariffcontservices,
  getTariffContServicesAdmin: state => state.tariffcontservices.filter((service) => service.gatein === 1)
};

const actions = {
  [TARIFFCONTSERVICES_LIST]: ({ commit }) => {
    return new Promise((resolve) => {
      TariffContServices.getAll()
        .then(response => {
          commit(TARIFFCONTSERVICES_LIST, response.data);
          resolve(response);
        })
      })
    },
  [TARIFFCONTSERVICES_CREATE]: ({ dispatch }, rates )=> {
    return new Promise((resolve) => {
      TariffContServices.create(rates)
        .then(response => {
          dispatch(TARIFFCONTSERVICES_LIST);
          resolve(response);
        })
      })
    },
  [TARIFFCONTSERVICES_DELETE]: ({ dispatch }, rates )=> {
    return new Promise((resolve) => {
      TariffContServices.delete(rates)
        .then(response => {
          dispatch(TARIFFCONTSERVICES_LIST);
          resolve(response);
        })
    })
  },
  [TARIFFCONTSERVICES_UPDATE]: ({ dispatch }, rates )=> {
    return new Promise((resolve) => {
      TariffContServices.update(rates.id,rates)
        .then(response => {
          dispatch(TARIFFCONTSERVICES_LIST);
          resolve(response);
        })
    })
  },
};

const mutations = {
  [TARIFFCONTSERVICES_LIST]: (state, resp) => {
    state.tariffcontservices = resp;
  },
};

export default {
  state,
  getters,
  actions,
  mutations
};
