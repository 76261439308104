import {DCOLORS_CREATE, DCOLORS_DELETE, DCOLORS_LIST, DCOLORS_UPDATE} from "../../actions/dict/dictcontcolors";
import DictContColorsDataSvc from '../../../services/dict/DictContColorsDataSvc'

const state = {
  dcolor: []
};

const getters = {
  getDColors: state => state.dcolor,
};

const actions = {
  [DCOLORS_LIST]: ({ commit }) => {
    return new Promise((resolve) => {
      DictContColorsDataSvc.getAll()
        .then(response => {
          commit(DCOLORS_LIST, response.data);
          resolve(response);
        })
      })
    },
  [DCOLORS_CREATE]: ({ dispatch }, contcolor ) => {
    return new Promise((resolve) => {
      DictContColorsDataSvc.create(contcolor)
        .then(response => {
          dispatch(DCOLORS_LIST);
          resolve(response);
        })
      })
    },
  [DCOLORS_DELETE]: ({ dispatch }, contcolor ) => {
    return new Promise((resolve) => {
      DictContColorsDataSvc.delete(contcolor)
        .then(response => {
          dispatch(DCOLORS_LIST);
          resolve(response);
        })
    })
  },
  [DCOLORS_UPDATE]: ({ dispatch }, contcolor ) => {
    return new Promise((resolve) => {
      DictContColorsDataSvc.update(contcolor.id,contcolor)
        .then(response => {
          dispatch(DCOLORS_LIST);
          resolve(response);
        })
    })
  },
};

const mutations = {
  [DCOLORS_LIST]: (state, resp) => {
    state.dcolor = resp;
  },
};

export default {
  state,
  getters,
  actions,
  mutations
};
