import {http} from "../../api/http-api";

class UsersDataSvc {
    getAll() {
        return http.get("/users?expand=role")
    }
    login(data) {
        return http.post("/users/login", data)
    }
    create(data) {
        return http.post(`/users`, data)
    }
    update(id, data) {
        return http.patch(`/users/${id}`, data)
    }
    delete(id) {
        return http.delete(`/users/${id}`)
    }
}

export default new UsersDataSvc();