import {http} from "../api/http-api";

class PlacesDataSvc {
    getAll() {
        return http.get("/places")
    }
    create(data) {
        return http.post(`/places`, data)
    }
    update(id, data) {
        return http.patch(`/places/${id}`, data)
    }
    delete(id) {
        return http.delete(`/places/${id}`)
    }
}

export default new PlacesDataSvc();