import { INVGTU_LIST } from "../../actions/invoice/invgtu";
import InvGtuDataSvc from '../../../services/invoice/InvGtuDataSvc'

const state = {
  invgtu: []
};

const getters = {
  getGTU: state => state.invgtu,
};

const actions = {
  [INVGTU_LIST]: ({ commit }) => {
    return new Promise((resolve) => {
      InvGtuDataSvc.getAll()
        .then(response => {
          commit(INVGTU_LIST, response.data);
          resolve(response);
        })
      })
    },
};

const mutations = {
  [INVGTU_LIST]: (state, resp) => {
    state.invgtu = resp;
  },
};

export default {
  state,
  getters,
  actions,
  mutations
};
