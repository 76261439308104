import {http} from "../../api/http-api";

class RepEstimatesTypeDataSvc {
    getAll() {
        return http.get("/repestimatetype")
    }
    create(data) {
        return http.post(`/repestimatetype`, data)
    }
    update(id, data) {
        return http.patch(`/repestimatetype/${id}`, data)
    }
    delete(id) {
        return http.delete(`/repestimatetype/${id}`)
    }
}

export default new RepEstimatesTypeDataSvc();