import {SERVICEPERSON_CREATE, SERVICEPERSON_DELETE, SERVICEPERSON_LIST, SERVICEPERSON_UPDATE} from "../../actions/dict/dictcontserviceperson";
import DictContServicePersonDataSvc from '../../../services/dict/DictContServicePersonDataSvc'

const state = {
  person: []
};

const getters = {
  getServicePerson: state => state.person,
};

const actions = {
  [SERVICEPERSON_LIST]: ({ commit }) => {
    return new Promise((resolve) => {
      DictContServicePersonDataSvc.getAll()
        .then(response => {
          commit(SERVICEPERSON_LIST, response.data);
          resolve(response);
        })
      })
    },
  [SERVICEPERSON_CREATE]: ({ dispatch }, serviceperson ) => {
    return new Promise((resolve) => {
      DictContServicePersonDataSvc.create(serviceperson)
        .then(response => {
          dispatch(SERVICEPERSON_LIST);
          resolve(response);
        })
      })
    },
  [SERVICEPERSON_DELETE]: ({ dispatch }, serviceperson ) => {
    return new Promise((resolve) => {
      DictContServicePersonDataSvc.delete(serviceperson)
        .then(response => {
          dispatch(SERVICEPERSON_LIST);
          resolve(response);
        })
    })
  },
  [SERVICEPERSON_UPDATE]: ({ dispatch }, serviceperson ) => {
    return new Promise((resolve) => {
      DictContServicePersonDataSvc.update(serviceperson.id,serviceperson)
        .then(response => {
          dispatch(SERVICEPERSON_LIST);
          resolve(response);
        })
    })
  },
};

const mutations = {
  [SERVICEPERSON_LIST]: (state, resp) => {
    state.person = resp;
  },
};

export default {
  state,
  getters,
  actions,
  mutations
};
