import {RATESSTORAGE_CREATE, RATESSTORAGE_DELETE, RATESSTORAGE_LIST, RATESSTORAGE_UPDATE} from "../../actions/rates/ratesstorage";
import RatesStorageDataSvc from '../../../services/rates/RatesStorageDataSvc'

const state = {
  ratesstorage: []
};

const getters = {
  getRatesStorage: state => state.ratesstorage,
};

const actions = {
  [RATESSTORAGE_LIST]: ({ commit }) => {
    return new Promise((resolve) => {
      RatesStorageDataSvc.getAll()
        .then(response => {
          commit(RATESSTORAGE_LIST, response.data);
          resolve(response);
        })
      })
    },
  [RATESSTORAGE_CREATE]: ({ dispatch }, rates )=> {
    return new Promise((resolve) => {
      RatesStorageDataSvc.create(rates)
        .then(response => {
          dispatch(RATESSTORAGE_LIST);
          resolve(response);
        })
      })
    },
  [RATESSTORAGE_DELETE]: ({ dispatch }, rates )=> {
    return new Promise((resolve) => {
      RatesStorageDataSvc.delete(rates)
        .then(response => {
          dispatch(RATESSTORAGE_LIST);
          resolve(response);
        })
    })
  },
  [RATESSTORAGE_UPDATE]: ({ dispatch }, rates )=> {
    return new Promise((resolve) => {
      RatesStorageDataSvc.update(rates.id,rates)
        .then(response => {
          dispatch(RATESSTORAGE_LIST);
          resolve(response);
        })
    })
  },
};

const mutations = {
  [RATESSTORAGE_LIST]: (state, resp) => {
    state.ratesstorage = resp;
  },
};

export default {
  state,
  getters,
  actions,
  mutations
};
