import {http} from "../../api/http-api";

class ContWestimDataSvc {
    getAll() {
        return http.get("/contwestim")
    }
    create(data) {
        return http.post(`/contwestim`, data)
    }
    update(id, data) {
        return http.patch(`/contwestim/${id}`, data)
    }
    delete(id) {
        return http.delete(`/contwestim/${id}`)
    }
    search(data) {
        return http.get(`/contwestim/search?${data}`)
    }
}

export default new ContWestimDataSvc();