import {http} from "../../api/http-api";
import {httpgetfile} from "../../api/http-api"

class ReportsDataSvc {
    getAll() {
        return http.get("/reports")
    }
    create(data) {
        return http.post(`/reports`, data)
    }
    update(id, data) {
        return http.patch(`/reports/${id}`, data)
    }
    delete(id) {
        return http.delete(`/reports/${id}`)
    }
    genreport(data) {
        return http.post(`/reports/genreport`, data)
    }
    getreport(data) {
        return httpgetfile.get(`/reports/${data.dirname}/${data.filename}`, {responseType: 'blob'})
    }
}

export default new ReportsDataSvc();