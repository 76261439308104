import {http} from "../../api/http-api";

class DictContServicesDataSvc {
    getAll() {
        return http.get("/dictcontservices")
    }
    create(data) {
        return http.post(`/dictcontservices`, data)
    }
    update(id, data) {
        return http.patch(`/dictcontservices/${id}`, data)
    }
    delete(id) {
        return http.delete(`/dictcontservices/${id}`)
    }
}

export default new DictContServicesDataSvc();