import {http} from "../../api/http-api";

class RatesWashDataSvc {
    getAll() {
        return http.get("/rateswash")
    }
    create(data) {
        return http.post(`/rateswash`, data)
    }
    update(id, data) {
        return http.patch(`/rateswash/${id}`, data)
    }
    delete(id) {
        return http.delete(`/rateswash/${id}`)
    }
}

export default new RatesWashDataSvc();