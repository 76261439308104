import {ASSIGNMENT_CREATE, ASSIGNMENT_DELETE, ASSIGNMENT_LIST, ASSIGNMENT_UPDATE} from "../../actions/cont/contassignment";
import ContAssignmentDataSvc from '../../../services/cont/ContAssignmentDataSvc'

const state = {
  contassignment: []
};

const getters = {
  getContassignment: state => state.contassignment,
};

const actions = {
  [ASSIGNMENT_LIST]: ({ commit }) => {
    return new Promise((resolve) => {
      ContAssignmentDataSvc.getAll()
        .then(response => {
          commit(ASSIGNMENT_LIST, response.data);
          resolve(response);
        })
      })
    },
  [ASSIGNMENT_CREATE]: ({ dispatch }, contassignment ) => {
    return new Promise((resolve) => {
      ContAssignmentDataSvc.create(contassignment)
        .then(response => {
          dispatch(ASSIGNMENT_LIST);
          resolve(response);
        })
      })
    },
  [ASSIGNMENT_DELETE]: ({ dispatch }, contassignment ) => {
    return new Promise((resolve) => {
      ContAssignmentDataSvc.delete(contassignment)
        .then(response => {
          dispatch(ASSIGNMENT_LIST);
          resolve(response);
        })
    })
  },
  [ASSIGNMENT_UPDATE]: ({ dispatch }, contassignment ) => {
    return new Promise((resolve) => {
      ContAssignmentDataSvc.update(contassignment.id,contassignment)
        .then(response => {
          dispatch(ASSIGNMENT_LIST);
          resolve(response);
        })
    })
  },
};

const mutations = {
  [ASSIGNMENT_LIST]: (state, resp) => {
    state.contassignment = resp;
  },
};

export default {
  state,
  getters,
  actions,
  mutations
};
