import {http} from "../../api/http-api";

class InvCorrPositionsDataSvc {
    getAll() {
        return http.get("/invcorrectionsposition?expand=vat,unit,gtu")
    }
    create(data) {
        return http.post(`/invcorrectionsposition`, data)
    }
    update(id, data) {
        return http.patch(`/invcorrectionsposition/${id}`, data)
    }
    delete(id) {
        return http.delete(`/invcorrectionsposition/${id}`)
    }
}

export default new InvCorrPositionsDataSvc();