import {http} from "../../api/http-api";

class TariffServicesGroupDataSvc {
    getAll() {
        return http.get("/tariffservicesgroup")
    }
    create(data) {
        return http.post(`/tariffservicesgroup`, data)
    }
    update(id, data) {
        return http.patch(`/tariffservicesgroup/${id}`, data)
    }
    delete(id) {
        return http.delete(`/tariffservicesgroup/${id}`)
    }
}

export default new TariffServicesGroupDataSvc();