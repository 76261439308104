import {OTHERSERVICES_CREATE, OTHERSERVICES_DELETE, OTHERSERVICES_LIST, OTHERSERVICES_UPDATE} from "../../actions/tariff/otherservices";
import OtherServicesDataSvc from '../../../services/tariff/TariffOtherServicesDataSvc'

const state = {
  otherservices: []
};

const getters = {
  getOtherServices: state => state.otherservices,
  getOtherServicesAdmin: state => state.otherservices.filter((service) => service.gatein === 1)
};

const actions = {
  [OTHERSERVICES_LIST]: ({ commit }) => {
    return new Promise((resolve) => {
      OtherServicesDataSvc.getAll()
        .then(response => {
          commit(OTHERSERVICES_LIST, response.data);
          resolve(response);
        })
      })
    },
  [OTHERSERVICES_CREATE]: ({ dispatch }, rates )=> {
    return new Promise((resolve) => {
      OtherServicesDataSvc.create(rates)
        .then(response => {
          dispatch(OTHERSERVICES_LIST);
          resolve(response);
        })
      })
    },
  [OTHERSERVICES_DELETE]: ({ dispatch }, rates )=> {
    return new Promise((resolve) => {
      OtherServicesDataSvc.delete(rates)
        .then(response => {
          dispatch(OTHERSERVICES_LIST);
          resolve(response);
        })
    })
  },
  [OTHERSERVICES_UPDATE]: ({ dispatch }, rates )=> {
    return new Promise((resolve) => {
      OtherServicesDataSvc.update(rates.id,rates)
        .then(response => {
          dispatch(OTHERSERVICES_LIST);
          resolve(response);
        })
    })
  },
};

const mutations = {
  [OTHERSERVICES_LIST]: (state, resp) => {
    state.otherservices = resp;
  },
};

export default {
  state,
  getters,
  actions,
  mutations
};
