import {DSTATUSPTI_CREATE, DSTATUSPTI_DELETE, DSTATUSPTI_LIST, DSTATUSPTI_UPDATE} from "../../actions/dict/dictcontstatuspti";
import DictContStatusPtiDataSvc from '../../../services/dict/DictContStatusPtiDataSvc'

const state = {
  dstatuspti: []
};

const getters = {
  getDContStatusPti: state => state.dstatuspti,
};

const actions = {
  [DSTATUSPTI_LIST]: ({ commit }) => {
    return new Promise((resolve) => {
      DictContStatusPtiDataSvc.getAll()
        .then(response => {
          commit(DSTATUSPTI_LIST, response.data);
          resolve(response);
        })
      })
    },
  [DSTATUSPTI_CREATE]: ({ dispatch }, contstatuspti ) => {
    return new Promise((resolve) => {
      DictContStatusPtiDataSvc.create(contstatuspti)
        .then(response => {
          dispatch(DSTATUSPTI_LIST);
          resolve(response);
        })
      })
    },
  [DSTATUSPTI_DELETE]: ({ dispatch }, contstatuspti ) => {
    return new Promise((resolve) => {
      DictContStatusPtiDataSvc.delete(contstatuspti)
        .then(response => {
          dispatch(DSTATUSPTI_LIST);
          resolve(response);
        })
    })
  },
  [DSTATUSPTI_UPDATE]: ({ dispatch }, contstatuspti ) => {
    return new Promise((resolve) => {
      DictContStatusPtiDataSvc.update(contstatuspti.id,contstatuspti)
        .then(response => {
          dispatch(DSTATUSPTI_LIST);
          resolve(response);
        })
    })
  },
};

const mutations = {
  [DSTATUSPTI_LIST]: (state, resp) => {
    state.dstatuspti = resp;
  },
};

export default {
  state,
  getters,
  actions,
  mutations
};
