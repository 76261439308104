import {RATESWASH_CREATE, RATESWASH_DELETE, RATESWASH_LIST, RATESWASH_UPDATE} from "../../actions/rates/rateswash";
import RatesWashDataSvc from '../../../services/rates/RatesWashDataSvc'

const state = {
  rateswash: []
};

const getters = {
  getRatesWash: state => state.rateswash,
};

const actions = {
  [RATESWASH_LIST]: ({ commit }) => {
    return new Promise((resolve) => {
      RatesWashDataSvc.getAll()
        .then(response => {
          commit(RATESWASH_LIST, response.data);
          resolve(response);
        })
      })
    },
  [RATESWASH_CREATE]: ({ dispatch }, rates )=> {
    return new Promise((resolve) => {
      RatesWashDataSvc.create(rates)
        .then(response => {
          dispatch(RATESWASH_LIST);
          resolve(response);
        })
      })
    },
  [RATESWASH_DELETE]: ({ dispatch }, rates )=> {
    return new Promise((resolve) => {
      RatesWashDataSvc.delete(rates)
        .then(response => {
          dispatch(RATESWASH_LIST);
          resolve(response);
        })
    })
  },
  [RATESWASH_UPDATE]: ({ dispatch }, rates )=> {
    return new Promise((resolve) => {
      RatesWashDataSvc.update(rates.id,rates)
        .then(response => {
          dispatch(RATESWASH_LIST);
          resolve(response);
        })
    })
  },
};

const mutations = {
  [RATESWASH_LIST]: (state, resp) => {
    state.rateswash = resp;
  },
};

export default {
  state,
  getters,
  actions,
  mutations
};
