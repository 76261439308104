import {DSTATUSUNIT_CREATE, DSTATUSUNIT_DELETE, DSTATUSUNIT_LIST, DSTATUSUNIT_UPDATE} from "../../actions/dict/dictcontstatusunit";
import DictContStatusUnitDataSvc from '../../../services/dict/DictContStatusUnitDataSvc'

const state = {
  dstatusunit: []
};

const getters = {
  getDContStatusUnit: state => state.dstatusunit,
};

const actions = {
  [DSTATUSUNIT_LIST]: ({ commit }) => {
    return new Promise((resolve) => {
      DictContStatusUnitDataSvc.getAll()
        .then(response => {
          commit(DSTATUSUNIT_LIST, response.data);
          resolve(response);
        })
      })
    },
  [DSTATUSUNIT_CREATE]: ({ dispatch }, contstatusunit ) => {
    return new Promise((resolve) => {
      DictContStatusUnitDataSvc.create(contstatusunit)
        .then(response => {
          dispatch(DSTATUSUNIT_LIST);
          resolve(response);
        })
      })
    },
  [DSTATUSUNIT_DELETE]: ({ dispatch }, contstatusunit ) => {
    return new Promise((resolve) => {
      DictContStatusUnitDataSvc.delete(contstatusunit)
        .then(response => {
          dispatch(DSTATUSUNIT_LIST);
          resolve(response);
        })
    })
  },
  [DSTATUSUNIT_UPDATE]: ({ dispatch }, contstatusunit ) => {
    return new Promise((resolve) => {
      DictContStatusUnitDataSvc.update(contstatusunit.id,contstatusunit)
        .then(response => {
          dispatch(DSTATUSUNIT_LIST);
          resolve(response);
        })
    })
  },
};

const mutations = {
  [DSTATUSUNIT_LIST]: (state, resp) => {
    state.dstatusunit = resp;
  },
};

export default {
  state,
  getters,
  actions,
  mutations
};
