import { USER_REQUEST, USER_ERROR, USER_SUCCESS } from "../../actions/user/user";
import { AUTH_LOGOUT } from "../../actions/auth";
import UsersDataSvc from '../../../services/user/UserDataSvc'

const state = { status: "" };

const actions = {
    [USER_REQUEST]: ({ commit, dispatch }, user) => {
      commit(USER_REQUEST);
      UsersDataSvc.login(user)
        .then(resp => {
          commit(USER_SUCCESS, resp);
        })
        .catch(() => {
          commit(USER_ERROR);
          // if resp is unauthorized, logout, to
          dispatch(AUTH_LOGOUT);
        });
    }
  };
  
  const mutations = {
    [USER_REQUEST]: state => {
      state.status = "X loading";
    },
    [USER_SUCCESS]: state => {
      state.status = "X success";
    },
    [USER_ERROR]: state => {
      state.status = "error";
    },
    [AUTH_LOGOUT]: state => {
      state.profile = {};
    }
  };
  
  export default {
    state,
    actions,
    mutations
  };
  