import {REPESTIMATES_CREATE, REPESTIMATES_DELETE, REPESTIMATES_LIST, REPESTIMATES_UPDATE, REPESTIMATES_GETONE, REPESTIMATES_SEARCH, REPESTIMATES_LISTBYCONT} from "../../actions/repair/repestimates";
import RepEstimatesDataSvc from '../../../services/repair/RepEstimatesDataSvc'

const state = {
  estimates: [],
  estimatesbycont: [],
};

const getters = {
  getEstimates: state => state.estimates,
  getEstimatesByCont: state => state.estimatesbycont,
};

const actions = {
  [REPESTIMATES_LIST]: ({ commit }) => {
    return new Promise((resolve) => {
      RepEstimatesDataSvc.getAll()
        .then(response => {
          commit(REPESTIMATES_LIST, response.data);
          resolve(response);
        })
      })
    },
    [REPESTIMATES_GETONE]: ({ dispatch }, estimate ) => {
      return new Promise((resolve) => {
        RepEstimatesDataSvc.getOne(estimate)
          .then(response => {
            dispatch(REPESTIMATES_LIST);
            resolve(response);
          })
      })
    },
    [REPESTIMATES_SEARCH]: ( {commit}, estimate ) => {
      return new Promise((resolve) => {
        RepEstimatesDataSvc.search(estimate)
          .then(response => {
            commit(REPESTIMATES_LISTBYCONT, response.data);
            resolve(response);
          })
      })
    },
    [REPESTIMATES_CREATE]: ({ dispatch }, estimate ) => {
    return new Promise((resolve) => {
      RepEstimatesDataSvc.create(estimate)
        .then(response => {
          dispatch(REPESTIMATES_LIST);
          resolve(response);
        })
      })
    },
  [REPESTIMATES_DELETE]: ({ dispatch }, estimate ) => {
    return new Promise((resolve) => {
      RepEstimatesDataSvc.delete(estimate)
        .then(response => {
          dispatch(REPESTIMATES_LIST);
          resolve(response);
        })
    })
  },
  [REPESTIMATES_UPDATE]: ({ dispatch }, estimate ) => {
    return new Promise((resolve) => {
      RepEstimatesDataSvc.update(estimate.id,estimate)
        .then(response => {
          dispatch(REPESTIMATES_LIST);
          resolve(response);
        })
    })
  },
};

const mutations = {
  [REPESTIMATES_LIST]: (state, resp) => {
    state.estimates = resp;
  },
  [REPESTIMATES_LISTBYCONT]: (state, resp) => {
    state.estimatesbycont = resp;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
