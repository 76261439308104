import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify';
import router from './router/index';
import store from "./store";
import i18n from './i18n';
import VueExcelXlsx from "vue-excel-xlsx";
Vue.config.productionTip = false
Vue.use(VueExcelXlsx);
new Vue({
  vuetify,
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
