import {CURRENCY_CREATE, CURRENCY_DELETE, CURRENCY_LIST, CURRENCY_UPDATE} from "../actions/currency";
import CurrencyDataSvc from '../../services/CurrencyDataSvc'

const state = {
  currencies: []
};

const getters = {
  getCurrencies: state => state.currencies,
};

const actions = {
  [CURRENCY_LIST]: ({ commit }) => {
    return new Promise((resolve) => {
      CurrencyDataSvc.getAll()
        .then(response => {
          commit(CURRENCY_LIST, response.data);
          resolve(response);
        })
      })
    },
  [CURRENCY_CREATE]: ({ dispatch }, currency ) => {
    return new Promise((resolve) => {
      CurrencyDataSvc.create(currency)
        .then(response => {
          dispatch(CURRENCY_LIST);
          resolve(response);
        })
      })
    },
  [CURRENCY_DELETE]: ({ dispatch }, currency ) => {
    return new Promise((resolve) => {
      CurrencyDataSvc.delete(currency)
        .then(response => {
          dispatch(CURRENCY_LIST);
          resolve(response);
        })
    })
  },
  [CURRENCY_UPDATE]: ({ dispatch }, currency ) => {
    return new Promise((resolve) => {
      CurrencyDataSvc.update(currency.id,currency)
        .then(response => {
          dispatch(CURRENCY_LIST);
          resolve(response);
        })
    })
  },
};

const mutations = {
  [CURRENCY_LIST]: (state, resp) => {
    state.currencies = resp;
  },
};

export default {
  state,
  getters,
  actions,
  mutations
};
