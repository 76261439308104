import {http} from "../../api/http-api";

class RatesWrongPutDataSvc {
    getAll() {
        return http.get("/rateswrongput")
    }
    create(data) {
        return http.post(`/rateswrongput`, data)
    }
    update(id, data) {
        return http.patch(`/rateswrongput/${id}`, data)
    }
    delete(id) {
        return http.delete(`/rateswrongput/${id}`)
    }
}

export default new RatesWrongPutDataSvc();