import {RATESHANDLING_CREATE, RATESHANDLING_DELETE, RATESHANDLING_LIST, RATESHANDLING_UPDATE} from "../../actions/rates/rateshandling";
import RatesHandlingDataSvc from '../../../services/rates/RatesHandlingDataSvc'

const state = {
  rateshandling: []
};

const getters = {
  getRatesHandling: state => state.rateshandling,
};

const actions = {
  [RATESHANDLING_LIST]: ({ commit }) => {
    return new Promise((resolve) => {
      RatesHandlingDataSvc.getAll()
        .then(response => {
          commit(RATESHANDLING_LIST, response.data);
          resolve(response);
        })
      })
    },
  [RATESHANDLING_CREATE]: ({ dispatch }, rates ) => {
    return new Promise((resolve) => {
      RatesHandlingDataSvc.create(rates)
        .then(response => {
          dispatch(RATESHANDLING_LIST);
          resolve(response);
        })
      })
    },
  [RATESHANDLING_DELETE]: ({ dispatch }, rates ) => {
    return new Promise((resolve) => {
      RatesHandlingDataSvc.delete(rates)
        .then(response => {
          dispatch(RATESHANDLING_LIST);
          resolve(response);
        })
    })
  },
  [RATESHANDLING_UPDATE]: ({ dispatch }, rates ) => {
    return new Promise((resolve) => {
      RatesHandlingDataSvc.update(rates.id,rates)
        .then(response => {
          dispatch(RATESHANDLING_LIST);
          resolve(response);
        })
    })
  },
};

const mutations = {
  [RATESHANDLING_LIST]: (state, resp) => {
    state.rateshandling = resp;
  },
};

export default {
  state,
  getters,
  actions,
  mutations
};
