import {INVCORRREASON_CREATE, INVCORRREASON_DELETE, INVCORRREASON_LIST, INVCORRREASON_UPDATE} from "../../actions/invoice/invcorrreason";
import InvCorrReasonDataSvc from '../../../services/invoice/InvCorrReasonDataSvc'

const state = {
  invcorrreasons: []
};

const getters = {
  getInvCorrReason: state => state.invcorrreasons,
};

const actions = {
  [INVCORRREASON_LIST]: ({ commit }) => {
    return new Promise((resolve) => {
      InvCorrReasonDataSvc.getAll()
        .then(response => {
          commit(INVCORRREASON_LIST, response.data);
          resolve(response);
        })
      })
    },
  [INVCORRREASON_CREATE]: ({ dispatch }, invcorrreason ) => {
    return new Promise((resolve) => {
      InvCorrReasonDataSvc.create(invcorrreason)
        .then(response => {
          dispatch(INVCORRREASON_LIST);
          resolve(response);
        })
      })
    },
  [INVCORRREASON_DELETE]: ({ dispatch }, invcorrreason ) => {
    return new Promise((resolve) => {
      InvCorrReasonDataSvc.delete(invcorrreason)
        .then(response => {
          dispatch(INVCORRREASON_LIST);
          resolve(response);
        })
    })
  },
  [INVCORRREASON_UPDATE]: ({ dispatch }, invcorrreason ) => {
    return new Promise((resolve) => {
      InvCorrReasonDataSvc.update(invcorrreason.id,invcorrreason)
        .then(response => {
          dispatch(INVCORRREASON_LIST);
          resolve(response);
        })
    })
  },
};

const mutations = {
  [INVCORRREASON_LIST]: (state, resp) => {
    state.invcorrreasons = resp;
  },
};

export default {
  state,
  getters,
  actions,
  mutations
};
