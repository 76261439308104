import {http} from "../../api/http-api";

class DictContSizeDataSvc {
    getAll() {
        return http.get("/dictcontsize")
    }
    create(data) {
        return http.post(`/dictcontsize`, data)
    }
    update(id, data) {
        return http.patch(`/dictcontsize/${id}`, data)
    }
    delete(id) {
        return http.delete(`/dictcontsize/${id}`)
    }
}

export default new DictContSizeDataSvc();