import {REPCODESTYPE_CREATE, REPCODESTYPE_DELETE, REPCODESTYPE_LIST, REPCODESTYPE_UPDATE} from "../../actions/repair/repcodestype";
import RepCodesTypeDataSvc from '../../../services/repair/RepCodesTypeDataSvc'

const state = {
  codestype: []
};

const getters = {
  getCodesType: state => state.codestype,
};

const actions = {
  [REPCODESTYPE_LIST]: ({ commit }) => {
    return new Promise((resolve) => {
      RepCodesTypeDataSvc.getAll()
        .then(response => {
          commit(REPCODESTYPE_LIST, response.data);
          resolve(response);
        })
      })
    },
  [REPCODESTYPE_CREATE]: ({ dispatch }, codestype ) => {
    return new Promise((resolve) => {
      RepCodesTypeDataSvc.create(codestype)
        .then(response => {
          dispatch(REPCODESTYPE_LIST);
          resolve(response);
        })
      })
    },
  [REPCODESTYPE_DELETE]: ({ dispatch }, codestype ) => {
    return new Promise((resolve) => {
      RepCodesTypeDataSvc.delete(codestype)
        .then(response => {
          dispatch(REPCODESTYPE_LIST);
          resolve(response);
        })
    })
  },
  [REPCODESTYPE_UPDATE]: ({ dispatch }, codestype ) => {
    return new Promise((resolve) => {
      RepCodesTypeDataSvc.update(codestype.id,codestype)
        .then(response => {
          dispatch(REPCODESTYPE_LIST);
          resolve(response);
        })
    })
  },
};

const mutations = {
  [REPCODESTYPE_LIST]: (state, resp) => {
    state.codestype = resp;
  },
};

export default {
  state,
  getters,
  actions,
  mutations
};
