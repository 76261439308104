import {http} from "../../api/http-api";

class DictContStatusTypeDataSvc {
    getAll() {
        return http.get("/dictcontstatustype")
    }
    create(data) {
        return http.post(`/dictcontstatustype`, data)
    }
    update(id, data) {
        return http.patch(`/dictcontstatustype/${id}`, data)
    }
    delete(id) {
        return http.delete(`/dictcontstatustype/${id}`)
    }
}

export default new DictContStatusTypeDataSvc();