import {http} from "../../api/http-api";

class DictContStatusDmgDataSvc {
    getAll() {
        return http.get("/dictcontstatusdmg")
    }
    create(data) {
        return http.post(`/dictcontstatusdmg`, data)
    }
    update(id, data) {
        return http.patch(`/dictcontstatusdmg/${id}`, data)
    }
    delete(id) {
        return http.delete(`/dictcontstatusdmg/${id}`)
    }
}

export default new DictContStatusDmgDataSvc();