import {CONTRACTORS_CREATE, CONTRACTORS_DELETE, CONTRACTORS_LIST, CONTRACTORS_UPDATE} from "../../actions/cont/contractors";
import ContractorsDataSvc from '../../../services/cont/ContractorsDataSvc'

const state = {
  contractors: []
};

const getters = {
  getContractors: state => state.contractors,
  getForwarders: state => state.contractors.filter((contractor) => contractor.forwarder === 1),
  getShipowners: state => state.contractors.filter((contractor) => contractor.shipowner === 1),
};

const actions = {
  [CONTRACTORS_LIST]: ({ commit }) => {
    return new Promise((resolve) => {
      ContractorsDataSvc.getAll()
        .then(response => {
          commit(CONTRACTORS_LIST, response.data);
          resolve(response);
        })
      })
    },
  [CONTRACTORS_CREATE]: ({ dispatch }, contractors ) => {
    return new Promise((resolve) => {
      ContractorsDataSvc.create(contractors)
        .then(response => {
          dispatch(CONTRACTORS_LIST);
          resolve(response);
        })
      })
    },
  [CONTRACTORS_DELETE]: ({ dispatch }, contractors ) => {
    return new Promise((resolve) => {
      ContractorsDataSvc.delete(contractors)
        .then(response => {
          dispatch(CONTRACTORS_LIST);
          resolve(response);
        })
    })
  },
  [CONTRACTORS_UPDATE]: ({ dispatch }, contractors ) => {
    return new Promise((resolve) => {
      ContractorsDataSvc.update(contractors.id,contractors)
        .then(response => {
          dispatch(CONTRACTORS_LIST);
          resolve(response);
        })
    })
  },
};

const mutations = {
  [CONTRACTORS_LIST]: (state, resp) => {
    state.contractors = resp;
  },
};

export default {
  state,
  getters,
  actions,
  mutations
};
