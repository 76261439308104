import {http} from "../../api/http-api";

class RepCatalogDataSvc {
    getAll() {
        return http.get("/repcatalog?expand=currency")
    }
    getFull() {
        return http.get("/repcatalog?expand=currency,tariff")
    }
    getOne(id) {
        return http.get(`/repcatalog/${id}`)
    }
    create(data) {
        return http.post(`/repcatalog`, data)
    }
    update(id, data) {
        return http.patch(`/repcatalog/${id}`, data)
    }
    delete(id) {
        return http.delete(`/repcatalog/${id}`)
    }
}

export default new RepCatalogDataSvc();