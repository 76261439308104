import {http} from "../../api/http-api";

class ContractorsDataSvc {
    getAll() {
        return http.get("/contractors?expand=corr_country,inv_country,country,inv_account,inv_currency,inv_payment_method,inv_language")
    }
    create(data) {
        return http.post(`/contractors`, data)
    }
    update(id, data) {
        return http.patch(`/contractors/${id}`, data)
    }
    delete(id) {
        return http.delete(`/contractors/${id}`)
    }
    search(data) {
        return http.get(`/contractors/search?${data}`)
    }
}

export default new ContractorsDataSvc();