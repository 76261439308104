import {USERS_CREATE, USERS_DELETE, USERS_LIST, USERS_UPDATE} from "../../actions/user/users";
import UsersDataSvc from '../../../services/user/UserDataSvc'

const state = {
  users: []
};

const getters = {
  getUsers: state => state.users,
};

const actions = {
  [USERS_LIST]: ({ commit }) => {
    return new Promise((resolve) => {
      UsersDataSvc.getAll()
        .then(response => {
          commit(USERS_LIST, response.data);
          resolve(response);
        })
      })
    },
  [USERS_CREATE]: ({ dispatch }, users ) => {
    return new Promise((resolve) => {
      UsersDataSvc.create(users)
        .then(response => {
          dispatch(USERS_LIST);
          resolve(response);
        })
      })
    },
  [USERS_DELETE]: ({ dispatch }, users ) => {
    return new Promise((resolve) => {
      UsersDataSvc.delete(users)
        .then(response => {
          dispatch(USERS_LIST);
          resolve(response);
        })
    })
  },
  [USERS_UPDATE]: ({ dispatch }, users ) => {
    return new Promise((resolve) => {
      UsersDataSvc.update(users.id,users)
        .then(response => {
          dispatch(USERS_LIST);
          resolve(response);
        })
    })
  },
};

const mutations = {
  [USERS_LIST]: (state, resp) => {
    state.users = resp;
  },
};

export default {
  state,
  getters,
  actions,
  mutations
};
