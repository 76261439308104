import {REPORTS_CREATE, REPORTS_DELETE, REPORTS_GEN, REPORTS_GET, REPORTS_LIST, REPORTS_UPDATE} from "../actions/reports";
import ReportsDataSvc from '../../services/repair/ReportsDataSvc'

const state = {
  reports: []
};

const getters = {
  getReports: state => state.reports,
};

const actions = {
  [REPORTS_LIST]: ({ commit }) => {
    return new Promise((resolve) => {
      ReportsDataSvc.getAll()
        .then(response => {
          commit(REPORTS_LIST, response.data);
          resolve(response);
        })
      })
    },
  [REPORTS_CREATE]: ({ dispatch }, reports ) => {
    return new Promise((resolve) => {
      ReportsDataSvc.create(reports)
        .then(response => {
          dispatch(REPORTS_LIST);
          resolve(response);
        })
      })
    },
  [REPORTS_DELETE]: ({ dispatch }, reports ) => {
    return new Promise((resolve) => {
      ReportsDataSvc.delete(reports)
        .then(response => {
          dispatch(REPORTS_LIST);
          resolve(response);
        })
    })
  },
  [REPORTS_UPDATE]: ({ dispatch }, reports ) => {
    return new Promise((resolve) => {
      ReportsDataSvc.update(reports.id,reports)
        .then(response => {
          dispatch(REPORTS_LIST);
          resolve(response);
        })
    })
  },
  [REPORTS_GEN]: ({ dispatch }, reports ) => {
    return new Promise((resolve) => {
      ReportsDataSvc.genreport(reports)
        .then(response => {
          dispatch(REPORTS_LIST);
          resolve(response);
        })
    })
  },
  [REPORTS_GET]: ({ dispatch }, reports ) => {
    return new Promise((resolve) => {
      ReportsDataSvc.getreport(reports)
        .then(response => {
          dispatch(REPORTS_LIST);
          resolve(response);
        })
    })
  },
};

const mutations = {
  [REPORTS_LIST]: (state, resp) => {
    state.reports = resp;
  },
};

export default {
  state,
  getters,
  actions,
  mutations
};
