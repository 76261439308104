<template>
    <div class="v-application--wrap">
      <v-main>
        <transition
          name="fade-transform"
          mode="out-in"
        >
        <keep-alive>
          <router-view />
        </keep-alive>
        </transition>
      </v-main>
  </div>
</template>

<script>
export default {
    name: 'Start',
    data: () => ({}),
}
</script>
