import {TARIFFPOSITIONS_CREATE, TARIFFPOSITIONS_DELETE, TARIFFPOSITIONS_LIST, TARIFFPOSITIONS_UPDATE} from "../../actions/tariff/tariffpositions";
import TariffPositionsDataSvc from '../../../services/tariff/TariffPositionsDataSvc'

const state = {
  tariffpositions: []
};

const getters = {
  getTariffPositions: state => state.tariffpositions,
};

const actions = {
  [TARIFFPOSITIONS_LIST]: ({ commit }) => {
    return new Promise((resolve) => {
      TariffPositionsDataSvc.getAll()
        .then(response => {
          commit(TARIFFPOSITIONS_LIST, response.data);
          resolve(response);
        })
      })
    },
  [TARIFFPOSITIONS_CREATE]: ({ dispatch }, rates )=> {
    return new Promise((resolve) => {
      TariffPositionsDataSvc.create(rates)
        .then(response => {
          dispatch(TARIFFPOSITIONS_LIST);
          resolve(response);
        })
      })
    },
  [TARIFFPOSITIONS_DELETE]: ({ dispatch }, rates )=> {
    return new Promise((resolve) => {
      TariffPositionsDataSvc.delete(rates)
        .then(response => {
          dispatch(TARIFFPOSITIONS_LIST);
          resolve(response);
        })
    })
  },
  [TARIFFPOSITIONS_UPDATE]: ({ dispatch }, rates )=> {
    return new Promise((resolve) => {
      TariffPositionsDataSvc.update(rates.id,rates)
        .then(response => {
          dispatch(TARIFFPOSITIONS_LIST);
          resolve(response);
        })
    })
  },
};

const mutations = {
  [TARIFFPOSITIONS_LIST]: (state, resp) => {
    state.tariffpositions = resp;
  },
};

export default {
  state,
  getters,
  actions,
  mutations
};
