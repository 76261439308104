import {REPESTIMATESTYPE_CREATE, REPESTIMATESTYPE_DELETE, REPESTIMATESTYPE_LIST, REPESTIMATESTYPE_UPDATE} from "../../actions/repair/repestimatestype";
import RepEstimatesTypeDataSvc from '../../../services/repair/RepEstimatesTypeDataSvc'

const state = {
  estimatestype: []
};

const getters = {
  getEstimatesType: state => state.estimatestype,
};

const actions = {
  [REPESTIMATESTYPE_LIST]: ({ commit }) => {
    return new Promise((resolve) => {
      RepEstimatesTypeDataSvc.getAll()
        .then(response => {
          commit(REPESTIMATESTYPE_LIST, response.data);
          resolve(response);
        })
      })
    },
  [REPESTIMATESTYPE_CREATE]: ({ dispatch }, estimatetype ) => {
    return new Promise((resolve) => {
      RepEstimatesTypeDataSvc.create(estimatetype)
        .then(response => {
          dispatch(REPESTIMATESTYPE_LIST);
          resolve(response);
        })
      })
    },
  [REPESTIMATESTYPE_DELETE]: ({ dispatch }, estimatetype ) => {
    return new Promise((resolve) => {
      RepEstimatesTypeDataSvc.delete(estimatetype)
        .then(response => {
          dispatch(REPESTIMATESTYPE_LIST);
          resolve(response);
        })
    })
  },
  [REPESTIMATESTYPE_UPDATE]: ({ dispatch }, estimatetype ) => {
    return new Promise((resolve) => {
      RepEstimatesTypeDataSvc.update(estimatetype.id,estimatetype)
        .then(response => {
          dispatch(REPESTIMATESTYPE_LIST);
          resolve(response);
        })
    })
  },
};

const mutations = {
  [REPESTIMATESTYPE_LIST]: (state, resp) => {
    state.estimatestype = resp;
  },
};

export default {
  state,
  getters,
  actions,
  mutations
};
