import {http} from "../../api/http-api";

class RatesStorageTypeDataSvc {
    getAll() {
        return http.get("/ratesstoragetype")
    }
    create(data) {
        return http.post(`/ratesstoragetype`, data)
    }
    update(id, data) {
        return http.patch(`/ratesstoragetype/${id}`, data)
    }
    delete(id) {
        return http.delete(`/ratesstoragetype/${id}`)
    }
}

export default new RatesStorageTypeDataSvc();