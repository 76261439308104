import {http} from "../../api/http-api";

class ContBlockDataSvc {
    getAll() {
        return http.get("/contblock")
    }
    create(data) {
        return http.post(`/contblock`, data)
    }
    update(id, data) {
        return http.patch(`/contblock/${id}`, data)
    }
    delete(id) {
        return http.delete(`/contblock/${id}`)
    }
}

export default new ContBlockDataSvc();