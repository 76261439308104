import {INVCORRECTIONS_CREATE, INVCORRECTIONS_DELETE, INVCORRECTIONS_LIST, INVCORRECTIONS_UPDATE} from "../../actions/invoice/invcorrections";
import InvCorrectionsDataSvc from '../../../services/invoice/InvCorrectionsDataSvc'

const state = {
  corrections: []
};

const getters = {
  getCorrections: state => state.corrections,
};

const actions = {
  [INVCORRECTIONS_LIST]: ({ commit }) => {
    return new Promise((resolve) => {
      InvCorrectionsDataSvc.getAll()
        .then(response => {
          commit(INVCORRECTIONS_LIST, response.data);
          resolve(response);
        })
      })
    },
  [INVCORRECTIONS_CREATE]: ({ dispatch }, correction ) => {
    return new Promise((resolve) => {
      InvCorrectionsDataSvc.create(correction)
        .then(response => {
          dispatch(INVCORRECTIONS_LIST);
          resolve(response);
        })
      })
    },
  [INVCORRECTIONS_DELETE]: ({ dispatch }, correction ) => {
    return new Promise((resolve) => {
      InvCorrectionsDataSvc.delete(correction)
        .then(response => {
          dispatch(INVCORRECTIONS_LIST);
          resolve(response);
        })
    })
  },
  [INVCORRECTIONS_UPDATE]: ({ dispatch }, correction ) => {
    return new Promise((resolve) => {
      InvCorrectionsDataSvc.update(correction.id,correction)
        .then(response => {
          dispatch(INVCORRECTIONS_LIST);
          resolve(response);
        })
    })
  },
};

const mutations = {
  [INVCORRECTIONS_LIST]: (state, resp) => {
    state.corrections = resp;
  },
};

export default {
  state,
  getters,
  actions,
  mutations
};
