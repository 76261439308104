<template>
  <v-footer
    app
    padless
    inset
  >
    <v-card
      dense
      flat
      tile
      width="100%"
      class="secondary text-center"
    >
      <v-card-text class="white--text">
        {{ new Date().getFullYear() }} — <strong>Info-Art</strong>
      </v-card-text>
    </v-card>
  </v-footer>
</template>

<script>

export default {
  name: 'Footer',
  data: () => ({}),
};
</script>
