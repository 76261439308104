import {UNITS_CREATE, UNITS_DELETE, UNITS_LIST, UNITS_UPDATE} from "../actions/units";
import UnitsDataSvc from '../../services/UnitsDataSvc'

const state = {
  units: []
};

const getters = {
  getUnits: state => state.units,
};

const actions = {
  [UNITS_LIST]: ({ commit }) => {
    return new Promise((resolve) => {
      UnitsDataSvc.getAll()
        .then(response => {
          commit(UNITS_LIST, response.data);
          resolve(response);
        })
      })
    },
  [UNITS_CREATE]: ({ dispatch }, units ) => {
    return new Promise((resolve) => {
      UnitsDataSvc.create(units)
        .then(response => {
          dispatch(UNITS_LIST);
          resolve(response);
        })
      })
    },
  [UNITS_DELETE]: ({ dispatch }, units ) => {
    return new Promise((resolve) => {
      UnitsDataSvc.delete(units)
        .then(response => {
          dispatch(UNITS_LIST);
          resolve(response);
        })
    })
  },
  [UNITS_UPDATE]: ({ dispatch }, units ) => {
    return new Promise((resolve) => {
      UnitsDataSvc.update(units.id,units)
        .then(response => {
          dispatch(UNITS_LIST);
          resolve(response);
        })
    })
  },
};

const mutations = {
  [UNITS_LIST]: (state, resp) => {
    state.units = resp;
  },
};

export default {
  state,
  getters,
  actions,
  mutations
};
