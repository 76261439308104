import {TARIFFSERVICES_CREATE, TARIFFSERVICES_DELETE, TARIFFSERVICES_LIST, TARIFFSERVICES_UPDATE} from "../../actions/tariff/tariffservices";
import TariffServices from '../../../services/tariff/TariffServicesDataSvc'

const state = {
  tariffservices: []
};

const getters = {
  getTariffServices: state => state.tariffservices,
  getTariffServicesAdmin: state => state.tariffservices.filter((service) => service.gatein === 1)
};

const actions = {
  [TARIFFSERVICES_LIST]: ({ commit }) => {
    return new Promise((resolve) => {
      TariffServices.getAll()
        .then(response => {
          commit(TARIFFSERVICES_LIST, response.data);
          resolve(response);
        })
      })
    },
  [TARIFFSERVICES_CREATE]: ({ dispatch }, rates )=> {
    return new Promise((resolve) => {
      TariffServices.create(rates)
        .then(response => {
          dispatch(TARIFFSERVICES_LIST);
          resolve(response);
        })
      })
    },
  [TARIFFSERVICES_DELETE]: ({ dispatch }, rates )=> {
    return new Promise((resolve) => {
      TariffServices.delete(rates)
        .then(response => {
          dispatch(TARIFFSERVICES_LIST);
          resolve(response);
        })
    })
  },
  [TARIFFSERVICES_UPDATE]: ({ dispatch }, rates )=> {
    return new Promise((resolve) => {
      TariffServices.update(rates.id,rates)
        .then(response => {
          dispatch(TARIFFSERVICES_LIST);
          resolve(response);
        })
    })
  },
};

const mutations = {
  [TARIFFSERVICES_LIST]: (state, resp) => {
    state.tariffservices = resp;
  },
};

export default {
  state,
  getters,
  actions,
  mutations
};
