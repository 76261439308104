import {DSTATUSWASH_CREATE, DSTATUSWASH_DELETE, DSTATUSWASH_LIST, DSTATUSWASH_UPDATE} from "../../actions/dict/dictcontstatuswash";
import DictContStatusWashDataSvc from '../../../services/dict/DictContStatusWashDataSvc'

const state = {
  dstatuswash: []
};

const getters = {
  getDContStatusWash: state => state.dstatuswash,
};

const actions = {
  [DSTATUSWASH_LIST]: ({ commit }) => {
    return new Promise((resolve) => {
      DictContStatusWashDataSvc.getAll()
        .then(response => {
          commit(DSTATUSWASH_LIST, response.data);
          resolve(response);
        })
      })
    },
  [DSTATUSWASH_CREATE]: ({ dispatch }, contstatuswash ) => {
    return new Promise((resolve) => {
      DictContStatusWashDataSvc.create(contstatuswash)
        .then(response => {
          dispatch(DSTATUSWASH_LIST);
          resolve(response);
        })
      })
    },
  [DSTATUSWASH_DELETE]: ({ dispatch }, contstatuswash ) => {
    return new Promise((resolve) => {
      DictContStatusWashDataSvc.delete(contstatuswash)
        .then(response => {
          dispatch(DSTATUSWASH_LIST);
          resolve(response);
        })
    })
  },
  [DSTATUSWASH_UPDATE]: ({ dispatch }, contstatuswash ) => {
    return new Promise((resolve) => {
      DictContStatusWashDataSvc.update(contstatuswash.id,contstatuswash)
        .then(response => {
          dispatch(DSTATUSWASH_LIST);
          resolve(response);
        })
    })
  },
};

const mutations = {
  [DSTATUSWASH_LIST]: (state, resp) => {
    state.dstatuswash = resp;
  },
};

export default {
  state,
  getters,
  actions,
  mutations
};
