<template>
    <v-app-bar
        app
        dense
        tile
    >
        <v-app-bar-nav-icon @click.stop="toggleNavbar">
            <v-icon>mdi-format-indent-decrease</v-icon>
        </v-app-bar-nav-icon>
        <v-spacer></v-spacer>
        <div class="text-center blue--text caption">{{ currentUser }}</div>
        <v-tooltip bottom>
            <template v-slot:activator="{on}">
                <v-app-bar-nav-icon @click="logout">
                    <v-icon v-on="on">mdi-logout</v-icon>
                </v-app-bar-nav-icon>
            </template>
            <span>Wyloguj</span>
        </v-tooltip>
 
  </v-app-bar>
</template>

<script>
import { AUTH_LOGOUT } from "../store/actions/auth";
import { mapGetters } from 'vuex'
export default {
    computed: {
        ...mapGetters({
             login: 'userLogin' 
        })
    },


    data: () => {
        return {
            currentUser: '',
        };
    },
    methods: {
        toggleNavbar() {
            this.$store.dispatch('NavbarToggle');
        },
        logout() {
            this.$store.dispatch(AUTH_LOGOUT)
                .then( this.$router.push('/login') )
        } 
    },
    mounted() {
        this.currentUser = localStorage.getItem("userName");
    },
}
</script> 