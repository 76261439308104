import {http} from "../../api/http-api";

class RatesStorageDataSvc {
    getAll() {
        return http.get("/ratesstorage?expand=type")
    }
    create(data) {
        return http.post(`/ratesstorage`, data)
    }
    update(id, data) {
        return http.patch(`/ratesstorage/${id}`, data)
    }
    delete(id) {
        return http.delete(`/ratesstorage/${id}`)
    }
}

export default new RatesStorageDataSvc();