import {SERVICE_CREATE, SERVICE_DELETE, SERVICE_LIST, SERVICE_UPDATE} from "../../actions/invoice/invservices";
import InvServicesDataSvc from '../../../services/invoice/InvServicesDataSvc'

const state = {
  services: []
};

const getters = {
  getInvServices: state => state.services,
};

const actions = {
  [SERVICE_LIST]: ({ commit }) => {
    return new Promise((resolve) => {
      InvServicesDataSvc.getAll()
        .then(response => {
          commit(SERVICE_LIST, response.data);
          resolve(response);
        })
      })
    },
  [SERVICE_CREATE]: ({ dispatch }, service ) => {
    return new Promise((resolve) => {
      InvServicesDataSvc.create(service)
        .then(response => {
          dispatch(SERVICE_LIST);
          resolve(response);
        })
      })
    },
  [SERVICE_DELETE]: ({ dispatch }, service ) => {
    return new Promise((resolve) => {
      InvServicesDataSvc.delete(service)
        .then(response => {
          dispatch(SERVICE_LIST);
          resolve(response);
        })
    })
  },
  [SERVICE_UPDATE]: ({ dispatch }, service ) => {
    return new Promise((resolve) => {
      InvServicesDataSvc.update(service.id,service)
        .then(response => {
          dispatch(SERVICE_LIST);
          resolve(response);
        })
    })
  },
};

const mutations = {
  [SERVICE_LIST]: (state, resp) => {
    state.services = resp;
  },
};

export default {
  state,
  getters,
  actions,
  mutations
};
