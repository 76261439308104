import {http} from "../../api/http-api";

class RatesStoragePositionsDataSvc {
    getAll() {
        return http.get("/ratesstoragepositions")
    }
    create(data) {
        return http.post(`/ratesstoragepositions`, data)
    }
    update(id, data) {
        return http.patch(`/ratesstoragepositions/${id}`, data)
    }
    delete(id) {
        return http.delete(`/ratesstoragepositions/${id}`)
    }
}

export default new RatesStoragePositionsDataSvc();