import {DASSIGNMENT_CREATE, DASSIGNMENT_DELETE, DASSIGNMENT_LIST, DASSIGNMENT_UPDATE} from "../../actions/dict/dictcontassignment";
import DictContAssignmentDataSvc from '../../../services/dict/DictContAssignmentDataSvc'

const state = {
  dassignment: []
};

const getters = {
  getDAssignments: state => state.dassignment,
};

const actions = {
  [DASSIGNMENT_LIST]: ({ commit }) => {
    return new Promise((resolve) => {
      DictContAssignmentDataSvc.getAll()
        .then(response => {
          commit(DASSIGNMENT_LIST, response.data);
          resolve(response);
        })
      })
    },
  [DASSIGNMENT_CREATE]: ({ dispatch }, contassignment ) => {
    return new Promise((resolve) => {
      DictContAssignmentDataSvc.create(contassignment)
        .then(response => {
          dispatch(DASSIGNMENT_LIST);
          resolve(response);
        })
      })
    },
  [DASSIGNMENT_DELETE]: ({ dispatch }, contassignment ) => {
    return new Promise((resolve) => {
      DictContAssignmentDataSvc.delete(contassignment)
        .then(response => {
          dispatch(DASSIGNMENT_LIST);
          resolve(response);
        })
    })
  },
  [DASSIGNMENT_UPDATE]: ({ dispatch }, contassignment ) => {
    return new Promise((resolve) => {
      DictContAssignmentDataSvc.update(contassignment.id,contassignment)
        .then(response => {
          dispatch(DASSIGNMENT_LIST);
          resolve(response);
        })
    })
  },
};

const mutations = {
  [DASSIGNMENT_LIST]: (state, resp) => {
    state.dassignment = resp;
  },
};

export default {
  state,
  getters,
  actions,
  mutations
};
