import {http} from "../../api/http-api";

class TrainTariffDataSvc {
    getAll() {
        return http.get("/traintariff")
    }
    create(data) {
        return http.post(`/traintariff`, data)
    }
    update(id, data) {
        return http.patch(`/traintariff/${id}`, data)
    }
    delete(id) {
        return http.delete(`/traintariff/${id}`)
    }
}

export default new TrainTariffDataSvc();