import {RATESNUMERICCHOICE_CREATE, RATESNUMERICCHOICE_DELETE, RATESNUMERICCHOICE_LIST, RATESNUMERICCHOICE_UPDATE} from "../../actions/rates/ratesnumericchoice";
import RatesNumericChoiceDataSvc from '../../../services/rates/RatesNumericChoiceDataSvc'

const state = {
  ratesnumericchoice: []
};

const getters = {
  getRatesNumericChoice: state => state.ratesnumericchoice,
};

const actions = {
  [RATESNUMERICCHOICE_LIST]: ({ commit }) => {
    return new Promise((resolve) => {
      RatesNumericChoiceDataSvc.getAll()
        .then(response => {
          commit(RATESNUMERICCHOICE_LIST, response.data);
          resolve(response);
        })
      })
    },
  [RATESNUMERICCHOICE_CREATE]: ({ dispatch }, rates )=> {
    return new Promise((resolve) => {
      RatesNumericChoiceDataSvc.create(rates)
        .then(response => {
          dispatch(RATESNUMERICCHOICE_LIST);
          resolve(response);
        })
      })
    },
  [RATESNUMERICCHOICE_DELETE]: ({ dispatch }, rates )=> {
    return new Promise((resolve) => {
      RatesNumericChoiceDataSvc.delete(rates)
        .then(response => {
          dispatch(RATESNUMERICCHOICE_LIST);
          resolve(response);
        })
    })
  },
  [RATESNUMERICCHOICE_UPDATE]: ({ dispatch }, rates )=> {
    return new Promise((resolve) => {
      RatesNumericChoiceDataSvc.update(rates.id,rates)
        .then(response => {
          dispatch(RATESNUMERICCHOICE_LIST);
          resolve(response);
        })
    })
  },
};

const mutations = {
  [RATESNUMERICCHOICE_LIST]: (state, resp) => {
    state.ratesnumericchoice = resp;
  },
};

export default {
  state,
  getters,
  actions,
  mutations
};
