import {CATALOG_CREATE, CATALOG_DELETE, CATALOG_LIST, CATALOG_UPDATE, CATALOG_ONE} from "../../actions/repair/repcatalog";
import RepCatalogDataSvc from '../../../services/repair/RepCatalogDataSvc'

const state = {
  catalog: [],
  catalogone: []
};

const getters = {
  getCatalog: state => state.catalog,
  getCatalogOne: state => state.catalogone,
};

const actions = {
  [CATALOG_LIST]: ({ commit }) => {
    return new Promise((resolve) => {
      RepCatalogDataSvc.getAll()
        .then(response => {
          commit(CATALOG_LIST, response.data);
          resolve(response);
        })
      })
    },
  [CATALOG_CREATE]: ({ dispatch }, catalog ) => {
    return new Promise((resolve) => {
      RepCatalogDataSvc.create(catalog)
        .then(response => {
          dispatch(CATALOG_LIST);
          resolve(response);
        })
      })
    },
  [CATALOG_DELETE]: ({ dispatch }, catalog ) => {
    return new Promise((resolve) => {
      RepCatalogDataSvc.delete(catalog)
        .then(response => {
          dispatch(CATALOG_LIST);
          resolve(response);
        })
    })
  },
  [CATALOG_ONE]: ( { commit }, id ) => {
    return new Promise((resolve) => {
      RepCatalogDataSvc.getOne(id)
        .then(response => {
          commit(CATALOG_ONE, response.data);
          resolve(response);
        })
    })
  },
  [CATALOG_UPDATE]: ({ dispatch }, catalog ) => {
    return new Promise((resolve) => {
      RepCatalogDataSvc.update(catalog.id,catalog)
        .then(response => {
          dispatch(CATALOG_LIST);
          resolve(response);
        })
    })
  },
};

const mutations = {
  [CATALOG_LIST]: (state, resp) => {
    state.catalog = resp;
  },
  [CATALOG_ONE]: (state, resp) => {
    state.catalogone = resp;
  },
};

export default {
  state,
  getters,
  actions,
  mutations
};
