import {http} from "../../api/http-api";

class RepTariffDataSvc {
    getAll() {
        return http.get("/reptariff?expand=element_code,repair_code,locations")
    }
    create(data) {
        return http.post(`/reptariff`, data)
    }
    update(id, data) {
        return http.patch(`/reptariff/${id}`, data)
    }
    delete(id) {
        return http.delete(`/reptariff/${id}`)
    }
}

export default new RepTariffDataSvc();