import {INVOICE_CREATE, INVOICE_DELETE, INVOICE_LIST, INVOICE_UPDATE} from "../../actions/invoice/invoice";
import InvoiceDataSvc from '../../../services/invoice/InvoiceDataSvc'

const state = {
  invoices: []
};

const getters = {
  getInvoice: state => state.invoices,
};

const actions = {
  [INVOICE_LIST]: ({ commit }) => {
    return new Promise((resolve) => {
      InvoiceDataSvc.getAll()
        .then(response => {
          commit(INVOICE_LIST, response.data);
          resolve(response);
        })
      })
    },
  [INVOICE_CREATE]: ({ dispatch }, invoice ) => {
    return new Promise((resolve) => {
      InvoiceDataSvc.create(invoice)
        .then(response => {
          dispatch(INVOICE_LIST);
          resolve(response);
        })
      })
    },
  [INVOICE_DELETE]: ({ dispatch }, invoice ) => {
    return new Promise((resolve) => {
      InvoiceDataSvc.delete(invoice)
        .then(response => {
          dispatch(INVOICE_LIST);
          resolve(response);
        })
    })
  },
  [INVOICE_UPDATE]: ({ dispatch }, invoice ) => {
    return new Promise((resolve) => {
      InvoiceDataSvc.update(invoice.id,invoice)
        .then(response => {
          dispatch(INVOICE_LIST);
          resolve(response);
        })
    })
  },
};

const mutations = {
  [INVOICE_LIST]: (state, resp) => {
    state.invoices = resp;
  },
};

export default {
  state,
  getters,
  actions,
  mutations
};
