import {INVCORRPOSITIONS_CREATE, INVCORRPOSITIONS_DELETE, INVCORRPOSITIONS_LIST, INVCORRPOSITIONS_UPDATE} from "../../actions/invoice/invcorrpositions";
import InvCorrPositionsDataSvc from '../../../services/invoice/InvCorrPositionsDataSvc'

const state = {
  corrpositions: []
};

const getters = {
  getCorrPositions: state => state.corrpositions,
};

const actions = {
  [INVCORRPOSITIONS_LIST]: ({ commit }) => {
    return new Promise((resolve) => {
      InvCorrPositionsDataSvc.getAll()
        .then(response => {
          commit(INVCORRPOSITIONS_LIST, response.data);
          resolve(response);
        })
      })
    },
  [INVCORRPOSITIONS_CREATE]: ({ dispatch }, corrposition ) => {
    return new Promise((resolve) => {
      InvCorrPositionsDataSvc.create(corrposition)
        .then(response => {
          dispatch(INVCORRPOSITIONS_LIST);
          resolve(response);
        })
      })
    },
  [INVCORRPOSITIONS_DELETE]: ({ dispatch }, corrposition ) => {
    return new Promise((resolve) => {
      InvCorrPositionsDataSvc.delete(corrposition)
        .then(response => {
          dispatch(INVCORRPOSITIONS_LIST);
          resolve(response);
        })
    })
  },
  [INVCORRPOSITIONS_UPDATE]: ({ dispatch }, corrposition ) => {
    return new Promise((resolve) => {
      InvCorrPositionsDataSvc.update(corrposition.id,corrposition)
        .then(response => {
          dispatch(INVCORRPOSITIONS_LIST);
          resolve(response);
        })
    })
  },
};

const mutations = {
  [INVCORRPOSITIONS_LIST]: (state, resp) => {
    state.corrpositions = resp;
  },
};

export default {
  state,
  getters,
  actions,
  mutations
};
