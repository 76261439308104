import {DSERVICEPAYER_CREATE, DSERVICEPAYER_DELETE, DSERVICEPAYER_LIST, DSERVICEPAYER_UPDATE} from "../../actions/dict/dictcontservicepayer";
import DictContServicePayerDataSvc from '../../../services/dict/DictContServicePayerDataSvc'

const state = {
  payer: []
};

const getters = {
  getDServicePayer: state => state.payer,
};

const actions = {
  [DSERVICEPAYER_LIST]: ({ commit }) => {
    return new Promise((resolve) => {
      DictContServicePayerDataSvc.getAll()
        .then(response => {
          commit(DSERVICEPAYER_LIST, response.data);
          resolve(response);
        })
      })
    },
  [DSERVICEPAYER_CREATE]: ({ dispatch }, servicepayer ) => {
    return new Promise((resolve) => {
      DictContServicePayerDataSvc.create(servicepayer)
        .then(response => {
          dispatch(DSERVICEPAYER_LIST);
          resolve(response);
        })
      })
    },
  [DSERVICEPAYER_DELETE]: ({ dispatch }, servicepayer ) => {
    return new Promise((resolve) => {
      DictContServicePayerDataSvc.delete(servicepayer)
        .then(response => {
          dispatch(DSERVICEPAYER_LIST);
          resolve(response);
        })
    })
  },
  [DSERVICEPAYER_UPDATE]: ({ dispatch }, servicepayer ) => {
    return new Promise((resolve) => {
      DictContServicePayerDataSvc.update(servicepayer.id,servicepayer)
        .then(response => {
          dispatch(DSERVICEPAYER_LIST);
          resolve(response);
        })
    })
  },
};

const mutations = {
  [DSERVICEPAYER_LIST]: (state, resp) => {
    state.payer = resp;
  },
};

export default {
  state,
  getters,
  actions,
  mutations
};
