import {USERSROLE_CREATE, USERSROLE_DELETE, USERSROLE_LIST, USERSROLE_UPDATE} from "../../actions/user/usersrole";
import UsersRoleDataSvc from '../../../services/user/UsersRoleDataSvc'

const state = {
  usersrole: []
};

const getters = {
  getUsersRole: state => state.usersrole,
};

const actions = {
  [USERSROLE_LIST]: ({ commit }) => {
    return new Promise((resolve) => {
      UsersRoleDataSvc.getAll()
        .then(response => {
          commit(USERSROLE_LIST, response.data);
          resolve(response);
        })
      })
    },
  [USERSROLE_CREATE]: ({ dispatch }, usersrole ) => {
    return new Promise((resolve) => {
      UsersRoleDataSvc.create(usersrole)
        .then(response => {
          dispatch(USERSROLE_LIST);
          resolve(response);
        })
      })
    },
  [USERSROLE_DELETE]: ({ dispatch }, usersrole ) => {
    return new Promise((resolve) => {
      UsersRoleDataSvc.delete(usersrole)
        .then(response => {
          dispatch(USERSROLE_LIST);
          resolve(response);
        })
    })
  },
  [USERSROLE_UPDATE]: ({ dispatch }, usersrole ) => {
    return new Promise((resolve) => {
      UsersRoleDataSvc.update(usersrole.id,usersrole)
        .then(response => {
          dispatch(USERSROLE_LIST);
          resolve(response);
        })
    })
  },
};

const mutations = {
  [USERSROLE_LIST]: (state, resp) => {
    state.usersrole = resp;
  },
};

export default {
  state,
  getters,
  actions,
  mutations
};
