import {http} from "../../api/http-api";

class UsersRoleDataSvc {
    getAll() {
        return http.get(`/usersrole`)
    }
    delete(id) {
        return http.delete(`/usersrole/${id}`)
    }
    create(data) {
        return http.post(`/usersrole`, data)
    }
    update(id, data) {
        return http.patch(`/usersrole/${id}`, data)
    }
}

export default new UsersRoleDataSvc();