import {INVCORRNO_CREATE, INVCORRNO_DELETE, INVCORRNO_LIST, INVCORRNO_UPDATE} from "../../actions/invoice/invcorrno";
import InvCorrNoDataSvc from '../../../services/invoice/InvCorrNoDataSvc'

const state = {
  invcorrnos: []
};

const getters = {
  getInvCorrNo: state => state.invcorrnos,
};

const actions = {
  [INVCORRNO_LIST]: ({ commit }) => {
    return new Promise((resolve) => {
      InvCorrNoDataSvc.getAll()
        .then(response => {
          commit(INVCORRNO_LIST, response.data);
          resolve(response);
        })
      })
    },
  [INVCORRNO_CREATE]: ({ dispatch }, invcorrno ) => {
    return new Promise((resolve) => {
      InvCorrNoDataSvc.create(invcorrno)
        .then(response => {
          dispatch(INVCORRNO_LIST);
          resolve(response);
        })
      })
    },
  [INVCORRNO_DELETE]: ({ dispatch }, invcorrno ) => {
    return new Promise((resolve) => {
      InvCorrNoDataSvc.delete(invcorrno)
        .then(response => {
          dispatch(INVCORRNO_LIST);
          resolve(response);
        })
    })
  },
  [INVCORRNO_UPDATE]: ({ dispatch }, invcorrno ) => {
    return new Promise((resolve) => {
      InvCorrNoDataSvc.update(invcorrno.id,invcorrno)
        .then(response => {
          dispatch(INVCORRNO_LIST);
          resolve(response);
        })
    })
  },
};

const mutations = {
  [INVCORRNO_LIST]: (state, resp) => {
    state.invcorrnos = resp;
  },
};

export default {
  state,
  getters,
  actions,
  mutations
};
