import {RATESSEALREMOVE_CREATE, RATESSEALREMOVE_DELETE, RATESSEALREMOVE_LIST, RATESSEALREMOVE_UPDATE} from "../../actions/rates/ratessealremove";
import RatesSealRemoveDataSvc from '../../../services/rates/RatesSealRemoveDataSvc'

const state = {
  ratessealremove: []
};

const getters = {
  getRatesSealRemove: state => state.ratessealremove,
};

const actions = {
  [RATESSEALREMOVE_LIST]: ({ commit }) => {
    return new Promise((resolve) => {
      RatesSealRemoveDataSvc.getAll()
        .then(response => {
          commit(RATESSEALREMOVE_LIST, response.data);
          resolve(response);
        })
      })
    },
  [RATESSEALREMOVE_CREATE]: ({ dispatch }, rates )=> {
    return new Promise((resolve) => {
      RatesSealRemoveDataSvc.create(rates)
        .then(response => {
          dispatch(RATESSEALREMOVE_LIST);
          resolve(response);
        })
      })
    },
  [RATESSEALREMOVE_DELETE]: ({ dispatch }, rates )=> {
    return new Promise((resolve) => {
      RatesSealRemoveDataSvc.delete(rates)
        .then(response => {
          dispatch(RATESSEALREMOVE_LIST);
          resolve(response);
        })
    })
  },
  [RATESSEALREMOVE_UPDATE]: ({ dispatch }, rates )=> {
    return new Promise((resolve) => {
      RatesSealRemoveDataSvc.update(rates.id,rates)
        .then(response => {
          dispatch(RATESSEALREMOVE_LIST);
          resolve(response);
        })
    })
  },
};

const mutations = {
  [RATESSEALREMOVE_LIST]: (state, resp) => {
    state.ratessealremove = resp;
  },
};

export default {
  state,
  getters,
  actions,
  mutations
};
