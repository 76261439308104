import {http} from "../../api/http-api";

class RatesCleanPositionsDataSvc {
    getAll() {
        return http.get("/ratescleanpositions")
    }
    create(data) {
        return http.post(`/ratescleanpositions`, data)
    }
    update(id, data) {
        return http.patch(`/ratescleanpositions/${id}`, data)
    }
    delete(id) {
        return http.delete(`/ratescleanpositions/${id}`)
    }
}

export default new RatesCleanPositionsDataSvc();