import {RATESHANDLINGPOSITIONS_CREATE, RATESHANDLINGPOSITIONS_DELETE, RATESHANDLINGPOSITIONS_LIST, RATESHANDLINGPOSITIONS_UPDATE} from "../../actions/rates/rateshandlingpositions";
import RatesHandlingPositionsDataSvc from '../../../services/rates/RatesHandlingPositionsDataSvc'

const state = {
  rateshandlingpositions: []
};

const getters = {
  getRatesHandlingPositions: state => state.rateshandlingpositions,
};

const actions = {
  [RATESHANDLINGPOSITIONS_LIST]: ({ commit }) => {
    return new Promise((resolve) => {
      RatesHandlingPositionsDataSvc.getAll()
        .then(response => {
          commit(RATESHANDLINGPOSITIONS_LIST, response.data);
          resolve(response);
        })
      })
    },
  [RATESHANDLINGPOSITIONS_CREATE]: ({ dispatch }, rates ) => {
    return new Promise((resolve) => {
      RatesHandlingPositionsDataSvc.create(rates)
        .then(response => {
          dispatch(RATESHANDLINGPOSITIONS_LIST);
          resolve(response);
        })
      })
    },
  [RATESHANDLINGPOSITIONS_DELETE]: ({ dispatch }, rates ) => {
    return new Promise((resolve) => {
      RatesHandlingPositionsDataSvc.delete(rates)
        .then(response => {
          dispatch(RATESHANDLINGPOSITIONS_LIST);
          resolve(response);
        })
    })
  },
  [RATESHANDLINGPOSITIONS_UPDATE]: ({ dispatch }, rates ) => {
    return new Promise((resolve) => {
      RatesHandlingPositionsDataSvc.update(rates.id,rates)
        .then(response => {
          dispatch(RATESHANDLINGPOSITIONS_LIST);
          resolve(response);
        })
    })
  },
};

const mutations = {
  [RATESHANDLINGPOSITIONS_LIST]: (state, resp) => {
    state.rateshandlingpositions = resp;
  },
};

export default {
  state,
  getters,
  actions,
  mutations
};
