import {DPTI_CREATE, DPTI_DELETE, DPTI_LIST, DPTI_UPDATE} from "../../actions/dict/dictcontpti";
import DictContPtiDataSvc from '../../../services/dict/DictContPtiDataSvc'

const state = {
  pti: []
};

const getters = {
  getDContPti: state => state.pti,
};

const actions = {
  [DPTI_LIST]: ({ commit }) => {
    return new Promise((resolve) => {
      DictContPtiDataSvc.getAll()
        .then(response => {
          commit(DPTI_LIST, response.data);
          resolve(response);
        })
      })
    },
  [DPTI_CREATE]: ({ dispatch }, contpti ) => {
    return new Promise((resolve) => {
      DictContPtiDataSvc.create(contpti)
        .then(response => {
          dispatch(DPTI_LIST);
          resolve(response);
        })
      })
    },
  [DPTI_DELETE]: ({ dispatch }, contpti ) => {
    return new Promise((resolve) => {
      DictContPtiDataSvc.delete(contpti)
        .then(response => {
          dispatch(DPTI_LIST);
          resolve(response);
        })
    })
  },
  [DPTI_UPDATE]: ({ dispatch }, contpti ) => {
    return new Promise((resolve) => {
      DictContPtiDataSvc.update(contpti.id,contpti)
        .then(response => {
          dispatch(DPTI_LIST);
          resolve(response);
        })
    })
  },
};

const mutations = {
  [DPTI_LIST]: (state, resp) => {
    state.pti = resp;
  },
};

export default {
  state,
  getters,
  actions,
  mutations
};
