import {RATESCLEAN_CREATE, RATESCLEAN_DELETE, RATESCLEAN_LIST, RATESCLEAN_UPDATE} from "../../actions/rates/ratesclean";
import RatesCleanDataSvc from '../../../services/rates/RatesCleanDataSvc'

const state = {
  ratesclean: []
};

const getters = {
  getRatesClean: state => state.ratesclean,
};

const actions = {
  [RATESCLEAN_LIST]: ({ commit }) => {
    return new Promise((resolve) => {
      RatesCleanDataSvc.getAll()
        .then(response => {
          commit(RATESCLEAN_LIST, response.data);
          resolve(response);
        })
      })
    },
  [RATESCLEAN_CREATE]: ({ dispatch }, rates )=> {
    return new Promise((resolve) => {
      RatesCleanDataSvc.create(rates)
        .then(response => {
          dispatch(RATESCLEAN_LIST);
          resolve(response);
        })
      })
    },
  [RATESCLEAN_DELETE]: ({ dispatch }, rates )=> {
    return new Promise((resolve) => {
      RatesCleanDataSvc.delete(rates)
        .then(response => {
          dispatch(RATESCLEAN_LIST);
          resolve(response);
        })
    })
  },
  [RATESCLEAN_UPDATE]: ({ dispatch }, rates )=> {
    return new Promise((resolve) => {
      RatesCleanDataSvc.update(rates.id,rates)
        .then(response => {
          dispatch(RATESCLEAN_LIST);
          resolve(response);
        })
    })
  },
};

const mutations = {
  [RATESCLEAN_LIST]: (state, resp) => {
    state.ratesclean = resp;
  },
};

export default {
  state,
  getters,
  actions,
  mutations
};
