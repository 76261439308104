import {REPESTNO_CREATE, REPESTNO_DELETE, REPESTNO_LIST, REPESTNO_UPDATE, REPESTNO_SEARCH, REPESTNO_GETNO, REPESTNO_GETONE} from "../../actions/repair/repestno";
import RepEstNoDataSvc from '../../../services/repair/RepEstNoDataSvc'

const state = {
  estno: [],
  estnoone: []
};

const getters = {
  getEstNo: state => state.estno,
  getEstNoOne: state => state.estnoone,
};

const actions = {
  [REPESTNO_LIST]: ({ commit }) => {
    return new Promise((resolve) => {
      RepEstNoDataSvc.getAll()
        .then(response => {
          commit(REPESTNO_LIST, response.data);
          resolve(response);
        })
      })
    },
    [REPESTNO_SEARCH]: ( {commit}, estimate ) => {
      return new Promise((resolve) => {
        RepEstNoDataSvc.search(estimate)
          .then(response => {
            commit(REPESTNO_GETNO, response.data);
            resolve(response);
          })
      })
    },
    [REPESTNO_GETONE]: ( {commit}, estimate ) => {
      return new Promise((resolve) => {
        RepEstNoDataSvc.getOne(estimate)
          .then(response => {
            commit(REPESTNO_GETNO, response.data);
            resolve(response);
          })
      })
    },
  [REPESTNO_CREATE]: ({ dispatch }, estno ) => {
    return new Promise((resolve) => {
      RepEstNoDataSvc.create(estno)
        .then(response => {
          dispatch(REPESTNO_LIST);
          resolve(response);
        })
      })
    },
  [REPESTNO_DELETE]: ({ dispatch }, estno ) => {
    return new Promise((resolve) => {
      RepEstNoDataSvc.delete(estno)
        .then(response => {
          dispatch(REPESTNO_LIST);
          resolve(response);
        })
    })
  },
  [REPESTNO_UPDATE]: ({ dispatch }, estno ) => {
    return new Promise((resolve) => {
      RepEstNoDataSvc.update(estno.id,estno)
        .then(response => {
          dispatch(REPESTNO_LIST);
          resolve(response);
        })
    })
  },
};

const mutations = {
  [REPESTNO_LIST]: (state, resp) => {
    state.estno = resp;
  },
  [REPESTNO_GETNO]: (state, resp) => {
    state.estnoone = resp;
  },
};

export default {
  state,
  getters,
  actions,
  mutations
};
