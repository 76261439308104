import {http} from "../../api/http-api";

class InvVatRatesDataSvc {
    getAll() {
        return http.get("/invvatrates")
    }
    create(data) {
        return http.post(`/invvatrates`, data)
    }
    update(id, data) {
        return http.patch(`/invvatrates/${id}`, data)
    }
    delete(id) {
        return http.delete(`/invvatrates/${id}`)
    }
}

export default new InvVatRatesDataSvc();