import {DNOTIFICATIONTYPE_CREATE, DNOTIFICATIONTYPE_DELETE, DNOTIFICATIONTYPE_LIST, DNOTIFICATIONTYPE_UPDATE} from "../../actions/dict/dictcontnotificationtype";
import DictContNotificationTypeDataSvc from '../../../services/dict/DictContNotificationTypeDataSvc'

const state = {
  dnotiftype: []
};

const getters = {
  getDContNotifType: state => state.dnotiftype,
};

const actions = {
  [DNOTIFICATIONTYPE_LIST]: ({ commit }) => {
    return new Promise((resolve) => {
      DictContNotificationTypeDataSvc.getAll()
        .then(response => {
          commit(DNOTIFICATIONTYPE_LIST, response.data);
          resolve(response);
        })
      })
    },
  [DNOTIFICATIONTYPE_CREATE]: ({ dispatch }, contstatus ) => {
    return new Promise((resolve) => {
      DictContNotificationTypeDataSvc.create(contstatus)
        .then(response => {
          dispatch(DNOTIFICATIONTYPE_LIST);
          resolve(response);
        })
      })
    },
  [DNOTIFICATIONTYPE_DELETE]: ({ dispatch }, contstatus ) => {
    return new Promise((resolve) => {
      DictContNotificationTypeDataSvc.delete(contstatus)
        .then(response => {
          dispatch(DNOTIFICATIONTYPE_LIST);
          resolve(response);
        })
    })
  },
  [DNOTIFICATIONTYPE_UPDATE]: ({ dispatch }, contstatus ) => {
    return new Promise((resolve) => {
      DictContNotificationTypeDataSvc.update(contstatus.id,contstatus)
        .then(response => {
          dispatch(DNOTIFICATIONTYPE_LIST);
          resolve(response);
        })
    })
  },
};

const mutations = {
  [DNOTIFICATIONTYPE_LIST]: (state, resp) => {
    state.dnotiftype = resp;
  },
};

export default {
  state,
  getters,
  actions,
  mutations
};
