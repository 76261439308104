import {LANGUAGE_CREATE, LANGUAGE_DELETE, LANGUAGE_LIST, LANGUAGE_UPDATE} from "../actions/language";
import LanguageDataSvc from '../../services/LanguageDataSvc'

const state = {
  language: []
};

const getters = {
  getLanguage: state => state.language,
};

const actions = {
  [LANGUAGE_LIST]: ({ commit }) => {
    return new Promise((resolve) => {
      LanguageDataSvc.getAll()
        .then(response => {
          commit(LANGUAGE_LIST, response.data);
          resolve(response);
        })
      })
    },
  [LANGUAGE_CREATE]: ({ dispatch }, language ) => {
    return new Promise((resolve) => {
      LanguageDataSvc.create(language)
        .then(response => {
          dispatch(LANGUAGE_LIST);
          resolve(response);
        })
      })
    },
  [LANGUAGE_DELETE]: ({ dispatch }, language ) => {
    return new Promise((resolve) => {
      LanguageDataSvc.delete(language)
        .then(response => {
          dispatch(LANGUAGE_LIST);
          resolve(response);
        })
    })
  },
  [LANGUAGE_UPDATE]: ({ dispatch }, language ) => {
    return new Promise((resolve) => {
      LanguageDataSvc.update(language.id,language)
        .then(response => {
          dispatch(LANGUAGE_LIST);
          resolve(response);
        })
    })
  },
};

const mutations = {
  [LANGUAGE_LIST]: (state, resp) => {
    state.language = resp;
  },
};

export default {
  state,
  getters,
  actions,
  mutations
};
