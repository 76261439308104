import {
  AUTH_REQUEST,
  AUTH_ERROR,
  AUTH_SUCCESS,
  AUTH_LOGOUT
} from "../actions/auth";
//import { USER_REQUEST } from "../actions/user";
import UsersDataSvc from '../../services/user/UserDataSvc'

const state = {
  token: localStorage.getItem("token") || "",
  status: "",
  role: "",
  user: "",
};

const getters = {
  isAuthenticated: state => !!state.token,
  authStatus: state => state.status,
  isAdmin: state => { return (state.role === 1 ? true : false) },
  userLogin: state => { return state.user }
};

const actions = {
  [AUTH_REQUEST]: ({ commit }, user) => {
    return new Promise((resolve, reject) => {
      commit(AUTH_REQUEST);
      UsersDataSvc.login(user)
        .then(resp => {
          localStorage.setItem('token', resp.data.token);
          localStorage.setItem('userName', resp.data.user.fname+' '+resp.data.user.sname);
          localStorage.setItem('userID', resp.data.user.id);
          localStorage.setItem('userRole', resp.data.role);
          // Here set the header of your ajax library to the token value.
          // example with axios
          // axios.defaults.headers.common['Authorization'] = resp.token
          commit(AUTH_SUCCESS, resp);
//          dispatch(USER_REQUEST, user);
          resolve(resp);
        })
        .catch(err => {
          commit(AUTH_ERROR, err);
          localStorage.removeItem("token");
          reject(err);
        });
    });
  },
  [AUTH_LOGOUT]: ({ commit }) => {
    return new Promise(resolve => {
      commit(AUTH_LOGOUT);
      localStorage.removeItem('token');
      resolve();
    });
  }
};

const mutations = {
  [AUTH_REQUEST]: state => {
    state.status = "Y loading";
  },
  [AUTH_SUCCESS]: (state, resp) => {
    state.status = "success";
    state.token = resp.data.token;
    state.role = resp.data.user.role;
    state.user = resp.data.user.login;
  },
  [AUTH_ERROR]: state => {
    state.status = "error";
  },
  [AUTH_LOGOUT]: state => {
    state.token = "";
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
