<template>
  <v-navigation-drawer
    dark
    app
    width="230"
    class="primary"
    color="blue darken-1"
>
    <v-app-bar
      dense
      dark
    >
      <v-toolbar-title class="text-center">
        <v-avatar
          tile
          left
          height="34"
          width="45"
        >
          <img
            src="../assets/rclogo.png"
          >
        </v-avatar>
        <span> IA-Depot 2</span>
      </v-toolbar-title>
    </v-app-bar>
    <layout-drawer-list
      dense
    />
  </v-navigation-drawer>
</template>

<script>
import LayoutDrawerList from './LayoutDrawerList.vue';

export default {
  name: 'LayoutDrawer',
  components: {
    LayoutDrawerList,
  },
  data: () => ({}),
};
</script>
