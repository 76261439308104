import {RATESSEALREMOVEPOSITIONS_CREATE, RATESSEALREMOVEPOSITIONS_DELETE, RATESSEALREMOVEPOSITIONS_LIST, RATESSEALREMOVEPOSITIONS_UPDATE} from "../../actions/rates/ratessealremovepositions";
import RatesSealRemovePositionsDataSvc from '../../../services/rates/RatesSealRemovePositionsDataSvc'

const state = {
  ratessealremovepositions: []
};

const getters = {
  getRatesSealRemovePositions: state => state.ratessealremovepositions,
};

const actions = {
  [RATESSEALREMOVEPOSITIONS_LIST]: ({ commit }) => {
    return new Promise((resolve) => {
      RatesSealRemovePositionsDataSvc.getAll()
        .then(response => {
          commit(RATESSEALREMOVEPOSITIONS_LIST, response.data);
          resolve(response);
        })
      })
    },
  [RATESSEALREMOVEPOSITIONS_CREATE]: ({ dispatch }, rates )=> {
    return new Promise((resolve) => {
      RatesSealRemovePositionsDataSvc.create(rates)
        .then(response => {
          dispatch(RATESSEALREMOVEPOSITIONS_LIST);
          resolve(response);
        })
      })
    },
  [RATESSEALREMOVEPOSITIONS_DELETE]: ({ dispatch }, rates )=> {
    return new Promise((resolve) => {
      RatesSealRemovePositionsDataSvc.delete(rates)
        .then(response => {
          dispatch(RATESSEALREMOVEPOSITIONS_LIST);
          resolve(response);
        })
    })
  },
  [RATESSEALREMOVEPOSITIONS_UPDATE]: ({ dispatch }, rates )=> {
    return new Promise((resolve) => {
      RatesSealRemovePositionsDataSvc.update(rates.id,rates)
        .then(response => {
          dispatch(RATESSEALREMOVEPOSITIONS_LIST);
          resolve(response);
        })
    })
  },
};

const mutations = {
  [RATESSEALREMOVEPOSITIONS_LIST]: (state, resp) => {
    state.ratessealremovepositions = resp;
  },
};

export default {
  state,
  getters,
  actions,
  mutations
};
