import {RATESCLEANPOSITIONS_CREATE, RATESCLEANPOSITIONS_DELETE, RATESCLEANPOSITIONS_LIST, RATESCLEANPOSITIONS_UPDATE} from "../../actions/rates/ratescleanpositions";
import RatesCleanPositionsDataSvc from '../../../services/rates/RatesCleanPositionsDataSvc'

const state = {
  ratescleanpositions: []
};

const getters = {
  getRatesCleanPositions: state => state.ratescleanpositions,
};

const actions = {
  [RATESCLEANPOSITIONS_LIST]: ({ commit }) => {
    return new Promise((resolve) => {
      RatesCleanPositionsDataSvc.getAll()
        .then(response => {
          commit(RATESCLEANPOSITIONS_LIST, response.data);
          resolve(response);
        })
      })
    },
  [RATESCLEANPOSITIONS_CREATE]: ({ dispatch }, rates )=> {
    return new Promise((resolve) => {
      RatesCleanPositionsDataSvc.create(rates)
        .then(response => {
          dispatch(RATESCLEANPOSITIONS_LIST);
          resolve(response);
        })
      })
    },
  [RATESCLEANPOSITIONS_DELETE]: ({ dispatch }, rates )=> {
    return new Promise((resolve) => {
      RatesCleanPositionsDataSvc.delete(rates)
        .then(response => {
          dispatch(RATESCLEANPOSITIONS_LIST);
          resolve(response);
        })
    })
  },
  [RATESCLEANPOSITIONS_UPDATE]: ({ dispatch }, rates )=> {
    return new Promise((resolve) => {
      RatesCleanPositionsDataSvc.update(rates.id,rates)
        .then(response => {
          dispatch(RATESCLEANPOSITIONS_LIST);
          resolve(response);
        })
    })
  },
};

const mutations = {
  [RATESCLEANPOSITIONS_LIST]: (state, resp) => {
    state.ratescleanpositions = resp;
  },
};

export default {
  state,
  getters,
  actions,
  mutations
};
