import {http} from "../../api/http-api";

class RepEstNoDataSvc {
    getAll() {
        return http.get("/repestimatenumber")
    }
    getOne(id) {
        return http.get(`/repestimatenumber/${id}`)
    }
    create(data) {
        return http.post(`/repestimatenumber`, data)
    }
    update(id, data) {
        return http.patch(`/repestimatenumber/${id}`, data)
    }
    delete(id) {
        return http.delete(`/repestimatenumber/${id}`)
    }
    search(id) {
        return http.get(`/repestimatenumber/search?year=${id}`)
    }
}

export default new RepEstNoDataSvc();