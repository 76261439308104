import {http} from "../../api/http-api";

class TrainContTypeDataSvc {
    getAll() {
        return http.get("/trainconttype")
    }
    create(data) {
        return http.post(`/trainconttype`, data)
    }
    update(id, data) {
        return http.patch(`/trainconttype/${id}`, data)
    }
    delete(id) {
        return http.delete(`/trainconttype/${id}`)
    }
}

export default new TrainContTypeDataSvc();