import {REPCODES_CREATE, REPCODES_DELETE, REPCODES_LIST, REPCODES_UPDATE} from "../../actions/repair/repcodes";
import RepCodesDataSvc from '../../../services/repair/RepCodesDataSvc'

const state = {
  codes: []
};

const getters = {
  getCodes: state => state.codes,
  getResponsibleCodes: state => state.codes.filter((codes) => codes.type === 4),
  getDamageCodes: state => state.codes.filter((codes) => codes.type === 1),
  getRepairCodes: state => state.codes.filter((codes) => codes.type === 7),
  getMaterialCodes: state => state.codes.filter((codes) => codes.type === 2),
  getElementCodes: state => state.codes.filter((codes) => codes.type === 3),
  getLoc1Codes: state => state.codes.filter((codes) => codes.type === 5),
  getLoc2Codes: state => state.codes.filter((codes) => codes.type === 6),
};

const actions = {
  [REPCODES_LIST]: ({ commit }) => {
    return new Promise((resolve) => {
      RepCodesDataSvc.getAll()
        .then(response => {
          commit(REPCODES_LIST, response.data);
          resolve(response);
        })
      })
    },
  [REPCODES_CREATE]: ({ dispatch }, codes ) => {
    return new Promise((resolve) => {
      RepCodesDataSvc.create(codes)
        .then(response => {
          dispatch(REPCODES_LIST);
          resolve(response);
        })
      })
    },
  [REPCODES_DELETE]: ({ dispatch }, codes ) => {
    return new Promise((resolve) => {
      RepCodesDataSvc.delete(codes)
        .then(response => {
          dispatch(REPCODES_LIST);
          resolve(response);
        })
    })
  },
  [REPCODES_UPDATE]: ({ dispatch }, codes ) => {
    return new Promise((resolve) => {
      RepCodesDataSvc.update(codes.id,codes)
        .then(response => {
          dispatch(REPCODES_LIST);
          resolve(response);
        })
    })
  },
};

const mutations = {
  [REPCODES_LIST]: (state, resp) => {
    state.codes = resp;
  },
};

export default {
  state,
  getters,
  actions,
  mutations
};
