import {INVPLACE_CREATE, INVPLACE_DELETE, INVPLACE_LIST, INVPLACE_UPDATE} from "../../actions/invoice/invplaces";
import InvPlacesDataSvc from '../../../services/invoice/InvPlacesDataSvc'

const state = {
  places: []
};

const getters = {
  getInvPlaces: state => state.places,
};

const actions = {
  [INVPLACE_LIST]: ({ commit }) => {
    return new Promise((resolve) => {
      InvPlacesDataSvc.getAll()
        .then(response => {
          commit(INVPLACE_LIST, response.data);
          resolve(response);
        })
      })
    },
  [INVPLACE_CREATE]: ({ dispatch }, place ) => {
    return new Promise((resolve) => {
      InvPlacesDataSvc.create(place)
        .then(response => {
          dispatch(INVPLACE_LIST);
          resolve(response);
        })
      })
    },
  [INVPLACE_DELETE]: ({ dispatch }, place ) => {
    return new Promise((resolve) => {
      InvPlacesDataSvc.delete(place)
        .then(response => {
          dispatch(INVPLACE_LIST);
          resolve(response);
        })
    })
  },
  [INVPLACE_UPDATE]: ({ dispatch }, place ) => {
    return new Promise((resolve) => {
      InvPlacesDataSvc.update(place.id,place)
        .then(response => {
          dispatch(INVPLACE_LIST);
          resolve(response);
        })
    })
  },
};

const mutations = {
  [INVPLACE_LIST]: (state, resp) => {
    state.places = resp;
  },
};

export default {
  state,
  getters,
  actions,
  mutations
};
