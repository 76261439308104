import {BLOCK_CREATE, BLOCK_DELETE, BLOCK_LIST, BLOCK_UPDATE} from "../../actions/cont/contblock";
import ContBlockDataSvc from '../../../services/cont/ContBlockDataSvc'

const state = {
  contblock: []
};

const getters = {
  getContBlock: state => state.contblock,
};

const actions = {
  [BLOCK_LIST]: ({ commit }) => {
    return new Promise((resolve) => {
      ContBlockDataSvc.getAll()
        .then(response => {
          commit(BLOCK_LIST, response.data);
          resolve(response);
        })
      })
    },
  [BLOCK_CREATE]: ({ dispatch }, reason ) => {
    return new Promise((resolve) => {
      ContBlockDataSvc.create(reason)
        .then(response => {
          dispatch(BLOCK_LIST);
          resolve(response);
        })
      })
    },
  [BLOCK_DELETE]: ({ dispatch }, reason ) => {
    return new Promise((resolve) => {
      ContBlockDataSvc.delete(reason)
        .then(response => {
          dispatch(BLOCK_LIST);
          resolve(response);
        })
    })
  },
  [BLOCK_UPDATE]: ({ dispatch }, reason ) => {
    return new Promise((resolve) => {
      ContBlockDataSvc.update(reason.id,reason)
        .then(response => {
          dispatch(BLOCK_LIST);
          resolve(response);
        })
    })
  },
};

const mutations = {
  [BLOCK_LIST]: (state, resp) => {
    state.contblock = resp;
  },
};

export default {
  state,
  getters,
  actions,
  mutations
};
