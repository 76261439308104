import {TRAINCONTTYPE_CREATE, TRAINCONTTYPE_DELETE, TRAINCONTTYPE_LIST, TRAINCONTTYPE_UPDATE} from "../../actions/rail/trainconttype";
import TrainContTypeDataSvc from '../../../services/rail/TrainContTypeDataSvc'

const state = {
  trainconttype: []
};

const getters = {
  getTrainContType: state => state.trainconttype,
};

const actions = {
  [TRAINCONTTYPE_LIST]: ({ commit }) => {
    return new Promise((resolve) => {
      TrainContTypeDataSvc.getAll()
        .then(response => {
          commit(TRAINCONTTYPE_LIST, response.data);
          resolve(response);
        })
      })
    },
  [TRAINCONTTYPE_CREATE]: ({ dispatch }, trainconttype )=> {
    return new Promise((resolve) => {
      TrainContTypeDataSvc.create(trainconttype)
        .then(response => {
          dispatch(TRAINCONTTYPE_LIST);
          resolve(response);
        })
      })
    },
  [TRAINCONTTYPE_DELETE]: ({ dispatch }, trainconttype )=> {
    return new Promise((resolve) => {
      TrainContTypeDataSvc.delete(trainconttype)
        .then(response => {
          dispatch(TRAINCONTTYPE_LIST);
          resolve(response);
        })
    })
  },
  [TRAINCONTTYPE_UPDATE]: ({ dispatch }, trainconttype )=> {
    return new Promise((resolve) => {
      TrainContTypeDataSvc.update(trainconttype.id,trainconttype)
        .then(response => {
          dispatch(TRAINCONTTYPE_LIST);
          resolve(response);
        })
    })
  },
};

const mutations = {
  [TRAINCONTTYPE_LIST]: (state, resp) => {
    state.trainconttype = resp;
  },
};

export default {
  state,
  getters,
  actions,
  mutations
};
