import {http} from "../../api/http-api";

class DictContStatusWashDataSvc {
    getAll() {
        return http.get("/dictcontstatuswash")
    }
    create(data) {
        return http.post(`/dictcontstatuswash`, data)
    }
    update(id, data) {
        return http.patch(`/dictcontstatuswash/${id}`, data)
    }
    delete(id) {
        return http.delete(`/dictcontstatuswash/${id}`)
    }
}

export default new DictContStatusWashDataSvc();