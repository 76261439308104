import {INVCOMMENT_CREATE, INVCOMMENT_DELETE, INVCOMMENT_LIST, INVCOMMENT_UPDATE} from "../../actions/invoice/invcomments";
import InvCommentsDataSvc from '../../../services/invoice/InvCommentsDataSvc'

const state = {
  comments: []
};

const getters = {
  getComments: state => state.comments,
};

const actions = {
  [INVCOMMENT_LIST]: ({ commit }) => {
    return new Promise((resolve) => {
      InvCommentsDataSvc.getAll()
        .then(response => {
          commit(INVCOMMENT_LIST, response.data);
          resolve(response);
        })
      })
    },
  [INVCOMMENT_CREATE]: ({ dispatch }, comment ) => {
    return new Promise((resolve) => {
      InvCommentsDataSvc.create(comment)
        .then(response => {
          dispatch(INVCOMMENT_LIST);
          resolve(response);
        })
      })
    },
  [INVCOMMENT_DELETE]: ({ dispatch }, comment ) => {
    return new Promise((resolve) => {
      InvCommentsDataSvc.delete(comment)
        .then(response => {
          dispatch(INVCOMMENT_LIST);
          resolve(response);
        })
    })
  },
  [INVCOMMENT_UPDATE]: ({ dispatch }, comment ) => {
    return new Promise((resolve) => {
      InvCommentsDataSvc.update(comment.id,comment)
        .then(response => {
          dispatch(INVCOMMENT_LIST);
          resolve(response);
        })
    })
  },
};

const mutations = {
  [INVCOMMENT_LIST]: (state, resp) => {
    state.comments = resp;
  },
};

export default {
  state,
  getters,
  actions,
  mutations
};
