import {WESTIM_CREATE, WESTIM_DELETE, WESTIM_LIST, WESTIM_UPDATE} from "../../actions/cont/contwestim";
import ContWestimDataSvc from '../../../services/cont/ContWestimDataSvc'

const state = {
  type: []
};

const getters = {
  getWestim: state => state.westim,
};

const actions = {
  [WESTIM_LIST]: ({ commit }) => {
    return new Promise((resolve) => {
      ContWestimDataSvc.getAll()
        .then(response => {
          commit(WESTIM_LIST, response.data);
          resolve(response);
        })
      })
    },
  [WESTIM_CREATE]: ({ dispatch }, westim ) => {
    return new Promise((resolve) => {
      ContWestimDataSvc.create(westim)
        .then(response => {
          dispatch(WESTIM_LIST);
          resolve(response);
        })
      })
    },
  [WESTIM_DELETE]: ({ dispatch }, westim ) => {
    return new Promise((resolve) => {
      ContWestimDataSvc.delete(westim)
        .then(response => {
          dispatch(WESTIM_LIST);
          resolve(response);
        })
    })
  },
  [WESTIM_UPDATE]: ({ dispatch }, westim ) => {
    return new Promise((resolve) => {
      ContWestimDataSvc.update(westim.id,westim)
        .then(response => {
          dispatch(WESTIM_LIST);
          resolve(response);
        })
    })
  },
};

const mutations = {
  [WESTIM_LIST]: (state, resp) => {
    state.type = resp;
  },
};

export default {
  state,
  getters,
  actions,
  mutations
};
