import {TARIFFSERVICESGROUP_CREATE, TARIFFSERVICESGROUP_DELETE, TARIFFSERVICESGROUP_LIST, TARIFFSERVICESGROUP_UPDATE} from "../../actions/tariff/tariffservicesgroup";
import TariffServicesGroup from '../../../services/tariff/TariffServicesGroupDataSvc'

const state = {
  tariffservicesgroup: []
};

const getters = {
  getTariffServicesGroup: state => state.tariffservicesgroup,
  getTariffServicesGroupAdmin: state => state.tariffservicesgroup.filter((service) => service.gatein === 1)
};

const actions = {
  [TARIFFSERVICESGROUP_LIST]: ({ commit }) => {
    return new Promise((resolve) => {
      TariffServicesGroup.getAll()
        .then(response => {
          commit(TARIFFSERVICESGROUP_LIST, response.data);
          resolve(response);
        })
      })
    },
  [TARIFFSERVICESGROUP_CREATE]: ({ dispatch }, rates )=> {
    return new Promise((resolve) => {
      TariffServicesGroup.create(rates)
        .then(response => {
          dispatch(TARIFFSERVICESGROUP_LIST);
          resolve(response);
        })
      })
    },
  [TARIFFSERVICESGROUP_DELETE]: ({ dispatch }, rates )=> {
    return new Promise((resolve) => {
      TariffServicesGroup.delete(rates)
        .then(response => {
          dispatch(TARIFFSERVICESGROUP_LIST);
          resolve(response);
        })
    })
  },
  [TARIFFSERVICESGROUP_UPDATE]: ({ dispatch }, rates )=> {
    return new Promise((resolve) => {
      TariffServicesGroup.update(rates.id,rates)
        .then(response => {
          dispatch(TARIFFSERVICESGROUP_LIST);
          resolve(response);
        })
    })
  },
};

const mutations = {
  [TARIFFSERVICESGROUP_LIST]: (state, resp) => {
    state.tariffservicesgroup = resp;
  },
};

export default {
  state,
  getters,
  actions,
  mutations
};
