import {INVOICEPOSITION_CREATE, INVOICEPOSITION_DELETE, INVOICEPOSITION_LIST, INVOICEPOSITION_UPDATE} from "../../actions/invoice/invoiceposition";
import InvoicePositionDataSvc from '../../../services/invoice/InvoicePositionDataSvc'

const state = {
  invoicesposition: []
};

const getters = {
  getInvoicePosition: state => state.invoicesposition,
};

const actions = {
  [INVOICEPOSITION_LIST]: ({ commit }) => {
    return new Promise((resolve) => {
      InvoicePositionDataSvc.getAll()
        .then(response => {
          commit(INVOICEPOSITION_LIST, response.data);
          resolve(response);
        })
      })
    },
  [INVOICEPOSITION_CREATE]: ({ dispatch }, invoice ) => {
    return new Promise((resolve) => {
      InvoicePositionDataSvc.create(invoice)
        .then(response => {
          dispatch(INVOICEPOSITION_LIST);
          resolve(response);
        })
      })
    },
  [INVOICEPOSITION_DELETE]: ({ dispatch }, invoice ) => {
    return new Promise((resolve) => {
      InvoicePositionDataSvc.delete(invoice)
        .then(response => {
          dispatch(INVOICEPOSITION_LIST);
          resolve(response);
        })
    })
  },
  [INVOICEPOSITION_UPDATE]: ({ dispatch }, invoice ) => {
    return new Promise((resolve) => {
      InvoicePositionDataSvc.update(invoice.id,invoice)
        .then(response => {
          dispatch(INVOICEPOSITION_LIST);
          resolve(response);
        })
    })
  },
};

const mutations = {
  [INVOICEPOSITION_LIST]: (state, resp) => {
    state.invoicesposition = resp;
  },
};

export default {
  state,
  getters,
  actions,
  mutations
};
