import {http} from "../../api/http-api";

class RatesHandlingPositionsDataSvc {
    getAll() {
        return http.get("/rateshandlingpositions")
    }
    create(data) {
        return http.post(`/rateshandlingpositions`, data)
    }
    update(id, data) {
        return http.patch(`/rateshandlingpositions/${id}`, data)
    }
    delete(id) {
        return http.delete(`/rateshandlingpositions/${id}`)
    }
}

export default new RatesHandlingPositionsDataSvc();