import { NBPTABLES_LIST } from "../actions/invoice/nbptables";
import NbpTablesDataSvc from '../../services/invoice/NbpTablesDataSvc'

const state = {
  nbptable: []
};

const getters = {
  getNbpTables: state => state.nbptable,
};

const actions = {
  [NBPTABLES_LIST]: ({ commit }) => {
    return new Promise((resolve) => {
      NbpTablesDataSvc.getAll()
        .then(response => {
          commit(NBPTABLES_LIST, response.data);
          resolve(response);
        })
      })
    },
};

const mutations = {
  [NBPTABLES_LIST]: (state, resp) => {
    state.nbptable = resp;
  },
};

export default {
  state,
  getters,
  actions,
  mutations
};
