import {CLASS_CREATE, CLASS_DELETE, CLASS_LIST, CLASS_UPDATE} from "../../actions/cont/contclass";
import DictContClassDataSvc from '../../../services/dict/DictContClassDataSvc'

const state = {
  class: []
};

const getters = {
  getClasses: state => state.class,
};

const actions = {
  [CLASS_LIST]: ({ commit }) => {
    return new Promise((resolve) => {
      DictContClassDataSvc.getAll()
        .then(response => {
          commit(CLASS_LIST, response.data);
          resolve(response);
        })
      })
    },
  [CLASS_CREATE]: ({ dispatch }, contclass ) => {
    return new Promise((resolve) => {
      DictContClassDataSvc.create(contclass)
        .then(response => {
          dispatch(CLASS_LIST);
          resolve(response);
        })
      })
    },
  [CLASS_DELETE]: ({ dispatch }, contclass ) => {
    return new Promise((resolve) => {
      DictContClassDataSvc.delete(contclass)
        .then(response => {
          dispatch(CLASS_LIST);
          resolve(response);
        })
    })
  },
  [CLASS_UPDATE]: ({ dispatch }, contclass ) => {
    return new Promise((resolve) => {
      DictContClassDataSvc.update(contclass.id,contclass)
        .then(response => {
          dispatch(CLASS_LIST);
          resolve(response);
        })
    })
  },
};

const mutations = {
  [CLASS_LIST]: (state, resp) => {
    state.class = resp;
  },
};

export default {
  state,
  getters,
  actions,
  mutations
};
