import {http} from "../../api/http-api";

class DictContColorsDataSvc {
    getAll() {
        return http.get("/dictcontcolors")
    }
    create(data) {
        return http.post(`/dictcontcolors`, data)
    }
    update(id, data) {
        return http.patch(`/dictcontcolors/${id}`, data)
    }
    delete(id) {
        return http.delete(`/dictcontcolors/${id}`)
    }
}

export default new DictContColorsDataSvc();