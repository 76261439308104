import {RATESWRONGPUTPOSITIONS_CREATE, RATESWRONGPUTPOSITIONS_DELETE, RATESWRONGPUTPOSITIONS_LIST, RATESWRONGPUTPOSITIONS_UPDATE} from "../../actions/rates/rateswrongputpositions";
import RatesWrongPutPositionsDataSvc from '../../../services/rates/RatesWrongPutPositionsDataSvc'

const state = {
  rateswrongputpositions: []
};

const getters = {
  getRatesWrongPutPositions: state => state.rateswrongputpositions,
};

const actions = {
  [RATESWRONGPUTPOSITIONS_LIST]: ({ commit }) => {
    return new Promise((resolve) => {
      RatesWrongPutPositionsDataSvc.getAll()
        .then(response => {
          commit(RATESWRONGPUTPOSITIONS_LIST, response.data);
          resolve(response);
        })
      })
    },
  [RATESWRONGPUTPOSITIONS_CREATE]: ({ dispatch }, rates )=> {
    return new Promise((resolve) => {
      RatesWrongPutPositionsDataSvc.create(rates)
        .then(response => {
          dispatch(RATESWRONGPUTPOSITIONS_LIST);
          resolve(response);
        })
      })
    },
  [RATESWRONGPUTPOSITIONS_DELETE]: ({ dispatch }, rates )=> {
    return new Promise((resolve) => {
      RatesWrongPutPositionsDataSvc.delete(rates)
        .then(response => {
          dispatch(RATESWRONGPUTPOSITIONS_LIST);
          resolve(response);
        })
    })
  },
  [RATESWRONGPUTPOSITIONS_UPDATE]: ({ dispatch }, rates )=> {
    return new Promise((resolve) => {
      RatesWrongPutPositionsDataSvc.update(rates.id,rates)
        .then(response => {
          dispatch(RATESWRONGPUTPOSITIONS_LIST);
          resolve(response);
        })
    })
  },
};

const mutations = {
  [RATESWRONGPUTPOSITIONS_LIST]: (state, resp) => {
    state.rateswrongputpositions = resp;
  },
};

export default {
  state,
  getters,
  actions,
  mutations
};
